import { Injectable, Injector } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { LoggingService } from '../logging/logging.service';
import { MachineRootService } from './machine-root.service';
import { MachinesInactivityService } from './machines-inactivity.service';

@Injectable()
export class MachinesSupervisorService {
  protected log: LoggingService;
  private machineRootService: MachineRootService;
  private machineInactivityService: MachinesInactivityService;
  private router: Router;
  private url = '';
  
  constructor(
    private injector: Injector,
  ) {

  }

  init() {
    this.log = this.injector.get(LoggingService);
    this.machineRootService = this.injector.get(MachineRootService);
    this.machineInactivityService = this.injector.get(MachinesInactivityService);
    this.router = this.injector.get(Router);
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.log.info(`Navigate. ${this.url} -> ${event.url}`);
        this.url = event.url;
      }
    });
  }
}

