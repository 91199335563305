import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { Subscription } from 'rxjs';

@Injectable()
export class OpenFmcuGridWorkflowService extends BaseGridWorkflowService {

  subscription: Subscription;

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.FmcuAction;
  }

  processAction(displayItem: DisplayItem, context: any): boolean {
    const result = super.processAction(displayItem, context);
    if (!result || !displayItem) {
      return false;
    }

    this.dispatcherService.gateOpen(
      this.barcode,
      displayItem.apiUrl,
      displayItem.openGateType,
      displayItem.timeout
    );

    this.displayGridWorkflowService.displayGridInactivityService.disableInactivityTimer();

    this.subscription = this.dispatcherService.onGateOpenComplete(
      (message) => {

        this.displayGridWorkflowService.displayGridInactivityService.enableInactivityTimer();

        if (message.info) {
          this.displayGridWorkflowService.gridVisualItemsComponent.showAdditionDisplayItem(displayItem);
        }
      });

    return true;
  }

  get barcode(): string {
    if (!this.context) {
      return '';
    }

    return this.context.barcode || this.context.pincode || this.context.rfidCardCode;
  }

  hide(displayItem: DisplayItem, context: any) {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    if (displayItem) {
      this.dispatcherService.gateOpenStop(this.barcode, displayItem.apiUrl);
    }

    super.hide(displayItem, context);
  }
}

