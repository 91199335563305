<div class="payment-cash normal-text">
  <div class="block">
    <div class="payment-cash-info">{{ 'The Following cash is accepted' | translate }}.</div>
    <accepted-cash [text]="'Coins'" [acceptedCash]="acceptedCoins" [cashType]="cashTypeCoin"
     (onDenominationClick)="onDenominationClick()"></accepted-cash>
    <accepted-cash [text]="'Banknotes'" [acceptedCash]="acceptedBanknotes" [cashType]="cashTypeBanknote"
     (onDenominationClick)="onDenominationClick()"></accepted-cash>
  </div>
  <div class="block">
    <table>
      <tr>
        <td class="standart-mode-label-td">{{ 'Paid amount' | translate}}</td>
        <td class="standart-mode-value-td">{{ amountPaid }}</td>
      </tr>
      <tr>
        <td class="standart-mode-label-td">{{ 'New Card Balance' | translate}}</td>
        <td class="standart-mode-value-td">{{ newBalance }}</td>
      </tr>
    </table>
  </div>
  <receipt-switcher class="left-mode-receipt-switcher"></receipt-switcher>
</div>
<div class="row pay">
  <div class="col-lg-12">
    <div class="pull-right">
      <simple-button *ngIf="isConfirmButtonEnabled" [enabled]="true"
        [text]="'Confirm Payment'" [cssClass]="'btn btn-primary button-base back-button'" (onClick)="onConfirmClick()"></simple-button>
    </div>
  </div>
</div>
<div *ngIf="personalNumber" class="personal-number header1 centered-content">
  <div>PN: {{ personalNumber }}</div>
</div>
