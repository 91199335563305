import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Configuration } from 'src/app/lib/configuration';
import { OrderSaveResult } from 'src/app/lib/order/order-save-result';
import { RefundPaymentActionEnum } from 'src/app/lib/payment/refund-payment-action-enum';
import { ShopState } from 'src/app/lib/shop-state';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { VuCommunicationService } from 'src/app/services/vu/vu-communication.service';

@Component({
  selector: 'app-order-simulator',
  templateUrl: './order-simulator.component.html',
  styleUrls: ['./order-simulator.component.css']
})
export class OrderSimulatorComponent implements OnInit, OnDestroy {

  shopState = new ShopState();
  private subscriptionShopState: Subscription;

  constructor(
    private dispatcherService: DispatcherService,
    private vuCommunicationService: VuCommunicationService,
    private configurationService: ConfigurationService,
  ) { }

  ngOnInit(): void {
    this.subscriptionShopState = this.dispatcherService.onShopStateChangedSubscribe(x => this.onShopStateChanged(x));
  }

  ngOnDestroy(): void {
    if (this.subscriptionShopState) {
      this.subscriptionShopState.unsubscribe();
      this.subscriptionShopState = null;
    }
  }

  public get configuration(): Configuration {
    return this.configurationService.configuration;
  }

  get refundPaymentActions(): RefundPaymentActionEnum[] {
    return [
      RefundPaymentActionEnum.WithRefund,
      RefundPaymentActionEnum.WithoutRefund,
      RefundPaymentActionEnum.NoRefundOnReceiptPrint,
      RefundPaymentActionEnum.NoRefundOnTicketPrint,
      RefundPaymentActionEnum.NoRefundIfReceiptTaken,
      RefundPaymentActionEnum.NoRefundIfTicketTaken,
      RefundPaymentActionEnum.NoRefundIfTicketAndReceiptTaken,
    ];
  }

  private onShopStateChanged(shopState: ShopState): void {
    this.shopState = shopState;
  }

  onOrderSaveClick(isOk: boolean): void {
    this.dispatcherService.shopStateIsSavingOrder(false);
    const error = isOk ? '' : 'Error from the simulator';
    const result = new OrderSaveResult(isOk ? 1 : null, this.shopState.orderUid, isOk, error);
    this.vuCommunicationService.vuConnectionSimulator.orderSaveResultReceived(result);
  }

  onRefundPaymentActionChange(event: any): void {
    this._updateConfiguration();
  }

  _updateConfiguration(): void {
    this.vuCommunicationService.vuConnectionSimulator.configurationChanged(this.configurationService.configuration);
    this.vuCommunicationService.vuHttp.getVuConfiguration().then(
      result => {
        this.vuCommunicationService.vuConnectionSimulator.vuConfigurationChanged(result);
      }
    );
  }
}
