import { TouchTileType } from "./touch-tile-type";

export class TouchTileSwitch {
  constructor(
    public name: TouchTileType = TouchTileType.LogoLight,
    public enabled: Boolean = false,
  ) {

  }

  static fromOther(other: TouchTileSwitch): TouchTileSwitch {
    return Object.assign(new TouchTileSwitch(), other);
  }

  public toString(): string {
    return `name: '${this.name}' enabled:'${this.enabled}'`;
  }
}

