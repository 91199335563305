<div class="container entire-width">
  <div class="row row-buffer-bottom">
    <div class="col-lg-6" align="center">
      <a type="button" class="btn button-base button-strict-large btn-item" (click)="onBuyArticleButtonClick()">{{
        'Buy Card' | translate }}</a>
    </div>
    <div class="col-lg-6" align="center">
      <a type="button" class="btn button-base button-strict-large btn-item" (click)="onReleaseTicketButtonClick()">{{
        'Already purchased card, receive ticket for access' | translate }}</a>
    </div>
  </div>
  <div class="row  row-buffer-bottom">
    <!-- <div class="col-lg-4" align="center">
      <a type="button" class="btn button-base button-strict-large btn-item" (click)="onDAVCardRechargeButtonClick()">{{
        'DAV card recharge' | translate }}</a>
    </div> -->
    <div class="col-lg-6" align="center">
      <a type="button" class="btn button-base button-strict-large btn-item" (click)="onInfoButtonClick()">{{
        'Information' | translate }}</a>
    </div>
    <div class="col-lg-6" align="center">
      <a type="button" class="btn button-base button-strict-large btn-item" (click)="onTicketInformationButtonClick()">{{
        'Ticket information' | translate }}</a>
    </div>  </div>
</div>

