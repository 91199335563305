import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../components/components.module';
import { CardDispenserReadCardComponent } from './components/card-dispenser-read-card/card-dispenser-read-card.component';
import { CardDispenserReadCardModalComponent } from './components/card-dispenser-read-card-modal/card-dispenser-read-card-modal.component';
import { InsertCardImageComponent } from './components/insert-card-image/insert-card-image.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  declarations: [
    CardDispenserReadCardModalComponent,
    CardDispenserReadCardComponent,
    InsertCardImageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    TranslateModule,
    InlineSVGModule.forRoot({ baseUrl: '' }),
  ],
  exports: [
    CardDispenserReadCardModalComponent,
    CardDispenserReadCardComponent,
    InsertCardImageComponent,
  ],
  entryComponents: [
    CardDispenserReadCardModalComponent,
  ],
})
export class CardDispenserModule { }
