import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';
import { LanguageService } from '../../../services/language.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseScanCardModalComponent } from '../../modal/base-scan-card-modal/base-scan-card-modal.component';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../services/gui/modal/modal-service';

@Component({
  selector: 'app-kletterzentrum-root',
  templateUrl: './kletterzentrum-root.component.html',
  styleUrls: ['./kletterzentrum-root.component.css']
})
export class KletterzentrumRootComponent implements OnInit, OnDestroy {
  backButtonVisible: boolean;

  constructor(
    private dispatcherService: DispatcherService,
    private languageService: LanguageService,
    private modalService: ModalService,
  ) {
  }

  ngOnInit() {
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.pageHeaderSet('Main Menu');
    this.dispatcherService.isBackButtonVisible = false;
  }

  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
  }

  onDAVCardRechargeButtonClick() {
  }

  onReleaseTicketButtonClick() {
    this._showDialog('Release ticket', () => { this.dispatcherService.onButtonTicketActivationClick(); });
  }

  onTicketInformationButtonClick() {
    this._showDialog('Ticket information', () => { this.dispatcherService.onButtonTicketClick(); });
  }

  onInfoButtonClick() {
    this.dispatcherService.onButtonInfoClick();
  }

  onBuyArticleButtonClick() {
    this.dispatcherService.onButtonSaleShopClick();
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  private _showDialog(title: string, action: any) {
    this.modalService.show(
      BaseScanCardModalComponent,
      {
        title: title,
        message: 'Please scan your Member DAV card'
      },
      (data: any) => {
        if (data && data.isContinue && data.cards && data.cards.length == 1) {
          let barcode = data.cards[0];
          this.dispatcherService.barcode = barcode;
          if (action) {
            action();
          }
        }
      }
    );
  }
}
