import { Component, Injector } from '@angular/core';
import { VisualItemsComponent } from '../../visual-item/visual-items/visual-items.component';
import { VisualItem, Ticket } from '../../../lib/lib';
import { CategoryScanCardModalComponent } from '../modal/category-scan-card-modal/category-scan-card-modal.component';
import { DavCardType } from '../../../lib/kletterzentrum/dav-card-type';
import { DavCard } from '../../../lib/kletterzentrum/dav-card';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TicketService } from '../../../services/ticket/ticket.service';
import { DavCardService } from '../../../services/kletterzentrum/dav-card.service';
import { StoreTreeState } from '../../../lib/store-tree-state';
import { ModalService } from '../../../services/gui/modal/modal-service';
import { MessageType, Message } from '../../../services/message.service';

@Component({
  selector: 'app-klettrezentrum-visual-items',
  templateUrl: '../../visual-item/visual-items/visual-items.component.html',
  styleUrls: ['../../visual-item/visual-items/visual-items.component.css']
})
export class KletterzentrumVisualItemsComponent extends VisualItemsComponent {

  private _modalRef: BsModalRef;
  private machineTimeoutModalCancelSubscription: Subscription;
  private ticketService: TicketService;
  private davCardService: DavCardService;
  private modalService: ModalService;

  private _cachedMainTree: VisualItem[];

  ngOnInit(): void {
    super.ngOnInit();
    this.modalService = this.injector.get(ModalService);
    this.ticketService = this.injector.get(TicketService);
    this.davCardService = this.injector.get(DavCardService);

    this.machineTimeoutModalCancelSubscription = this.messageService.getSubscription()
      .pipe(filter((x: Message) => [MessageType.MachineTimeoutModalCancel].indexOf(x.messageType) > -1))
      .subscribe(message => {
          this.modalService.close(this._modalRef);
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if(this.machineTimeoutModalCancelSubscription)
      this.machineTimeoutModalCancelSubscription.unsubscribe();
  }

  protected initialize(visualItems: VisualItem[]) {
    this._cachedMainTree = visualItems;
    super.initialize(visualItems);
  }

  onClick(visualItem: VisualItem) {
    if (!visualItem) {
      return;
    }

    if (visualItem.data && visualItem.data.checkCardType && visualItem.data.checkCardType != DavCardType.None) {
      this._showDialog(visualItem);
      return;
    }

    super.onClick(visualItem);
  }

  _showSalePage(visualItem: VisualItem) {
    if (visualItem == null)
      return;

    let memberAge = null;
    if (this.davCardService.davCardInfo != null && this.davCardService.davCardInfo.memberAge != null)
      memberAge = this.davCardService.davCardInfo.memberAge;

    const filteredVisualItem = VisualItem.fromOther(visualItem);

    filteredVisualItem.children = StoreTreeState.filterVisualItemsByAge(filteredVisualItem.children, memberAge);

    super.onClick(filteredVisualItem);
  }

  onTicketScanned(ticket: Ticket, barcode: string) {
    let davCardInfo = new DavCard(null);
    davCardInfo.hasValidTicket = false;
    if (ticket != null) {
      davCardInfo.hasValidTicket = ticket.hasValidDavCard;
      davCardInfo.relatedTicketProductId = ticket.productId;
    }
    davCardInfo.cardNumber = barcode;

    this.davCardService.davCardInfo = davCardInfo;
  }

  private _showDialog(category: VisualItem) {
    let cardType: DavCardType;
    if (category && category.data && category.data.checkCardType)
      cardType = category.data.checkCardType;

    this.davCardService.davCardType = cardType || DavCardType.None;

    this._modalRef = this.modalService.show(
      CategoryScanCardModalComponent,
      {
        title: 'Scanning Card',
        message: (cardType && cardType == DavCardType.MemberDav) ?
          'Please scan your Member DAV card' : 'Please scan your Member DAV card Section Illertissen',
        isIllerissenMember: cardType && cardType == DavCardType.MemberDavSectionIllerissen
      },
      (data) => {
        this._modalRef = null;
        if (data && data.isContinue && data.cards && data.cards.length == 1) {
          let barcode = data.cards[0];

          this.ticketService.getTicketInfo(barcode).subscribe(
            (ticket: Ticket) => {
              this.onTicketScanned(ticket, barcode);
              this._showSalePage(category);
            },
          );
        }
      });
  }

  protected stepBack() {
    this.storeTreeState.backStep();
    if (this.storeTreeState.isStackEmpty && this._cachedMainTree) {
      this.initialize(this._cachedMainTree);
      return;
    }
    this.showCurrentStep();
  }
}
