export class CardDispenserStatus {
  isAvailable: boolean;
  stackEmpty: boolean;

  static createFromAny(value: any): CardDispenserStatus {
    if (!value) {
      return;
    }
    const cardDispenserStatus = new CardDispenserStatus();
    cardDispenserStatus.isAvailable = value.is_available;
    cardDispenserStatus.stackEmpty = value.stack_empty;
    return cardDispenserStatus;
  }
}
