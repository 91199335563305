import { Component, ViewEncapsulation } from '@angular/core';
import { UiService } from '../../../services/ui.service';
import { DispatcherService } from '../../../services/dispatcher.service';

@Component({
  selector: 'abort-button',
  templateUrl: './abort-button.component.html',
  styleUrls: ['./abort-button.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AbortButtonComponent {
  constructor(
    public uiService: UiService,
    private dispatcherService: DispatcherService,
  ) {
  }

  onClick(): void {
    this.dispatcherService.onAbortButtonClick();
  }
}
