import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CardDispenserActionResult } from 'src/app/lib/rfid-card/card-dispenser-action-result';
import { CardDispenserCompleteActionResult } from 'src/app/lib/rfid-card/card-dispenser-complete-action-result';
import { CardDispenserService } from 'src/app/services/card-dispenser/card-dispenser.service';

@Component({
  selector: 'app-card-dispenser-read-card',
  templateUrl: './card-dispenser-read-card.component.html',
  styleUrls: ['./card-dispenser-read-card.component.css']
})
export class CardDispenserReadCardComponent implements OnInit, OnDestroy {

  @Output()
  rfidCardRead = new EventEmitter<string>();

  private cardReadSubscription: Subscription;
  private completeSubscription: Subscription;

  constructor(
    private cardDispenserService: CardDispenserService,
  ) { }

  ngOnInit(): void {
    this.cardReadSubscription = this.cardDispenserService.cardRead.subscribe((result) => { this.onCardRead(result); });
    this.completeSubscription = this.cardDispenserService.complete.subscribe((result) => { this.onComplete(result); });
    this.cardDispenserService.takeCardFromCustomer(true);
  }

  ngOnDestroy(): void {
    this.unsubscribeCardReadSubscription();
    this.unsubscribeCompleteSubscription();
    if (!this.cardDispenserService.cardCode) {
      this.cardDispenserService.stopTakeCardFromCustomer();
    }
  }

  unsubscribeCardReadSubscription(): void {
    if (this.cardReadSubscription) {
      this.cardReadSubscription.unsubscribe();
      this.cardReadSubscription = null;
    }
  }

  unsubscribeCompleteSubscription(): void {
    if (this.completeSubscription) {
      this.completeSubscription.unsubscribe();
      this.completeSubscription = null;
    }
  }

  private onCardRead(result: CardDispenserActionResult): void {
    this.rfidCardRead.emit(result.barcode);
  }

  private onComplete(result: CardDispenserCompleteActionResult): void {
    this.cardDispenserService.takeCardFromCustomer(true);
  }
}
