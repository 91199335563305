import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageView } from 'src/app/lib/message-view';
import { BarcodeReaderService } from 'src/app/services/barcode/barcode-reader.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { Message, MessageService, MessageType } from 'src/app/services/message.service';
import { CovidCheckOperation } from '../../models/covid-check-operation';
import { CovidCheckResult } from '../../models/covid-check-result';
import { CovidService } from '../../services/covid.service';


@Component({
  selector: 'app-covid-check',
  templateUrl: './covid-check.component.html',
  styleUrls: ['./covid-check.component.css']
})
export class CovidCheckComponent implements OnInit, OnDestroy {

  private barcodeScannedSubscription: Subscription;
  private barcodeScanStartedSubscription: Subscription;
  private abortButtonSubscription: Subscription;
  private certificate: string;
  private testCertificate: string;

  private _backButtonVisible: boolean;
  private _abortButtonVisible: boolean;

  constructor(
    private covidService: CovidService,
    private dispatcherService: DispatcherService,
    private barcodeReaderService: BarcodeReaderService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this._backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this._abortButtonVisible = this.dispatcherService.isAbortButtonVisible;
    this.dispatcherService.isBackButtonVisible = false;
    this.reset();

    this.barcodeScannedSubscription = this.barcodeReaderService.barcodeScanned
      .subscribe(
        (barcode: string) => this.onBarcodeScanned(barcode)
      );

    this.barcodeScanStartedSubscription = this.barcodeReaderService.barcodeScanStarted
      .subscribe(
        () => this.onBarcodeScanStarted()
      );

    this.abortButtonSubscription = this.messageService.getSubscription()
      .pipe(
        filter((x: Message) => [MessageType.ButtonAbortClicked, MessageType.MachineHardReset].indexOf(x.messageType) > -1),
      )
      .subscribe((_) => {
        this.reset();
      });
  }

  ngOnDestroy(): void {
    this.dispatcherService.isBackButtonVisible = this._backButtonVisible;
    this.dispatcherService.isAbortButtonVisible = this._abortButtonVisible;

    if (this.barcodeScannedSubscription) {
      this.barcodeScannedSubscription.unsubscribe();
      this.barcodeScannedSubscription = null;
    }

    if (this.barcodeScanStartedSubscription) {
      this.barcodeScanStartedSubscription.unsubscribe();
      this.barcodeScanStartedSubscription = null;
    }

    if (this.abortButtonSubscription) {
      this.abortButtonSubscription.unsubscribe();
      this.abortButtonSubscription = null;
    }
  }

  reset(): void {
    this.certificate = '';
    this.testCertificate = '';

    this.dispatcherService.isAbortButtonVisible = false;
    this.currentCovidCheckOperation = CovidCheckOperation.WaitCertificateScan;
  }

  onBarcodeScanned(barcode: string): void {
    this.dispatcherService.onUserActivity();

    if (this.currentCovidCheckOperation === CovidCheckOperation.WaitCertificateScan ||
      this.currentCovidCheckOperation === CovidCheckOperation.WaitCertificateScanStarted) {
      this.certificate = barcode;
      this.currentCovidCheckOperation = CovidCheckOperation.ValidateCertificate;
      this.validate();
      return;
    }

    if (this.currentCovidCheckOperation === CovidCheckOperation.WaitTestCertificateScan ||
      this.currentCovidCheckOperation === CovidCheckOperation.WaitTestCertificateScanStarted) {
      this.testCertificate = barcode;
      this.currentCovidCheckOperation = CovidCheckOperation.ValidateTestCertificate;
      this.validate();
      return;
    }
  }

  onBarcodeScanStarted(): void {
    if (this.currentCovidCheckOperation === CovidCheckOperation.WaitCertificateScan) {
      this.currentCovidCheckOperation = CovidCheckOperation.WaitCertificateScanStarted;
      return;
    }
    if (this.currentCovidCheckOperation === CovidCheckOperation.WaitTestCertificateScan) {
      this.currentCovidCheckOperation = CovidCheckOperation.WaitTestCertificateScanStarted;
      return;
    }
  }

  validate(): void {
    this.covidService.checkCertificate(this.certificate, this.testCertificate).subscribe((result) => {
      this.validateResult(result);
    });
  }

  validateResult(result: CovidCheckResult): void {
    switch (this.currentCovidCheckOperation) {
      case CovidCheckOperation.ValidateCertificate:
        if (result?.valid) {
          this.setCertificateValidated(true);
          this.currentCovidCheckOperation = CovidCheckOperation.CertificateValid;
        } else {
          this.dispatcherService.isAbortButtonVisible = true;
          if (result?.testRequired) {
            this.currentCovidCheckOperation = CovidCheckOperation.WaitTestCertificateScan;
          } else {
            this.currentCovidCheckOperation = CovidCheckOperation.CertificateNotValid;
          }
        }
        break;
      case CovidCheckOperation.ValidateTestCertificate:
        if (result?.valid) {
          this.setCertificateValidated(true);
          this.currentCovidCheckOperation = CovidCheckOperation.CertificateValid;
        } else {
          this.dispatcherService.isAbortButtonVisible = true;
          this.currentCovidCheckOperation = CovidCheckOperation.CertificateNotValid;
        }
        break;
      default:
        break;
    }
  }

  setCertificateValidated(result: boolean): void {
    this.covidService.setCertificateValidated(result);
  }

  get currentCovidCheckOperation(): CovidCheckOperation {
    return this.covidService.currentCovidCheckOperation;
  }

  set currentCovidCheckOperation(value: CovidCheckOperation) {
    this.covidService.currentCovidCheckOperation = value;
    this.updateMessage();
    this.dispatcherService.reloadInactivityTracking();
  }

  updateMessage() {
    this.dispatcherService.messageViewUpdate(new MessageView(this.message));
  }

  get message(): string {
    switch (this.currentCovidCheckOperation) {
      case CovidCheckOperation.WaitCertificateScan:
        return 'Please scan your COVID certificate';
      case CovidCheckOperation.WaitTestCertificateScan:
        return 'Please scan your COVID Test certificate';
      case CovidCheckOperation.WaitCertificateScanStarted:
      case CovidCheckOperation.WaitTestCertificateScanStarted:
      case CovidCheckOperation.ValidateCertificate:
      case CovidCheckOperation.ValidateTestCertificate:
        return 'Please wait. Validation in progress';
      case CovidCheckOperation.CertificateNotValid:
        return 'COVID Certificate is not valid';
      default:
        return '';
    }
  }

  get iconCssClass(): string {
    switch (this.currentCovidCheckOperation) {
      case CovidCheckOperation.WaitCertificateScan:
      case CovidCheckOperation.WaitTestCertificateScan:
        return 'fa-qrcode fa-color-gray'
      case CovidCheckOperation.WaitCertificateScanStarted:
      case CovidCheckOperation.WaitTestCertificateScanStarted:
      case CovidCheckOperation.ValidateCertificate:
      case CovidCheckOperation.ValidateTestCertificate:
        return 'fa-clock-o orange';
      case CovidCheckOperation.CertificateNotValid:
        return 'fa-times red';
      default:
        return '';
    }
  }
}
