<a type="button"
  [attr.class]="cssClass"
  [class.disabled]="!enabled"
  [attr.disabled]="enabled ? null : 'disabled'"
  (click)="onButtonClick()">
  <span
    *ngIf="iconClass"
    class="icon-container">
      <i [attr.class]="iconClass"></i>
  </span>
  <span *ngIf="text">{{ text | translate}}</span>
  <span *ngIf="texts" class="multiline-container">
    <div *ngFor="let text of texts">{{ text | translate}}</div>
  </span>
</a>
