import { VisualItem } from './visual-item';

export class StoreTreeState {
  items: VisualItem[][];
  stack: VisualItem[][][] = [];
  rootStack: VisualItem[] = [];
  parentItem: VisualItem;
  selectedItem: VisualItem;
  age: number;

  private columnCount = 3;

  initialize(visualItems: VisualItem[], age: number = null): void {
    this.rootStack = [];
    this.parentItem = null;
    this.age = age;

    if (visualItems && visualItems.length === 1 && visualItems[0] && visualItems[0].children) {
      this.parentItem = visualItems[0];
      visualItems = this.parentItem.children;
    }

    if (this.age) {
      visualItems = StoreTreeState.filterVisualItemsByAge(visualItems, age);
    }

    this.items = this.buildShownVisualItems(visualItems);
  }

  static filterVisualItemsByAge(visualItems: VisualItem[], age: number): VisualItem[] {
    if (!visualItems || !age) {
      return visualItems;
    }

    const result = new Array<VisualItem>();

    visualItems.forEach(element => {
      if (!element || !element.isValidForAge(age)) {
        return;
      }
      if (element.children) {
        element.children = StoreTreeState.filterVisualItemsByAge(element.children, age)
      }
      if (element.isProduct || (element.children && element.children.length > 0)) {
        result.push(element);
      }
    });

    return result;
  }

  nextStep(visualItem: VisualItem): VisualItem[][] {
    this.stack.push(this.items);
    this.rootStack.push(this.parentItem);
    this.parentItem = visualItem;
    this.items = this.buildShownVisualItems(visualItem.children);

    return this.items;
  }

  backStep(): VisualItem[][] {
    this.items = this.stack.pop();
    this.parentItem = this.rootStack.pop();
    return this.items;
  }

  get isStackEmpty(): boolean {
    return this.stack.length === 0;
  }

  public buildShownVisualItems(visualItems: VisualItem[]) {
    let displayedVisualItems: VisualItem[][];
    displayedVisualItems = [];
    if (!visualItems) {
      return displayedVisualItems;
    }

    let columnCount = this.columnCount;

    if (visualItems.length === 2 || visualItems.length === 4) {
      columnCount = 2;
    }

    let l = 0;
    let j = 0;
    displayedVisualItems[l] = [];
    for (const visualItem of visualItems) {
      if (j >= columnCount) {
        l++; j = 0;
        displayedVisualItems[l] = [];
      }
      displayedVisualItems[l][j++] = visualItem;
    }
    this.beutifyDisplayedItems(displayedVisualItems);
    return displayedVisualItems;
  }

  private beutifyDisplayedItems(displayedVisualItems: VisualItem[][]) {
    if (displayedVisualItems == null) {
      return;
    }

    for (const rows of displayedVisualItems) {
      let cssClass = 'col-lg-12';

      switch (rows.length) {
        case 2:
          cssClass = 'col-lg-6';
          break;
        case 3:
          cssClass = 'col-lg-4';
          break;
        default:
          break;
      }

      for (const row of rows) {
        row.cssClass = cssClass;
      }
    }
  }
}
