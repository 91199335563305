import { Injectable } from '@angular/core';

import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../base/base-grid-workflow.service';
import { SaleService } from '../../../sale.service';

@Injectable()
export class PaymentBaseGridWorkflowService extends BaseGridWorkflowService {

  protected saleService: SaleService;

  protected isPaymentOperationInProgress: boolean;

  init(
  ) {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.dispatcherService.onDisplayModePaymentComplete((message) => {
      if (!this.displayItem) {
        return;
      }
      const info = message.info !== undefined ? message.info : false;
      this.userActivity();
      this.paymentComplete(info);
    });
  }

  reset(): void {
    super.reset();
    this.isPaymentOperationInProgress = false;
  }

  startPaymentOperation(abortPayment: boolean) {
    if (this.isPaymentOperationInProgress) {
      return;
    }

    this.isPaymentOperationInProgress = true;
    this.dispatcherService.toDisplayModePayment(abortPayment);
  }

  paymentComplete(info: any) {
    this.isPaymentOperationInProgress = false;

    if (this.displayItem) {
      const navigationItem = this.getNavigationItem(info);
      this.showDisplayItem(navigationItem);
    }
    this.delayedHardReset();
  }

  getNavigationItem(info: any): DisplayItem {
    return this.displayItem ? this.displayItem.navigationItem : null;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);

    if (this.isPaymentOperationInProgress) {
      return;
    }

    if (!this.saleService.order || !this.saleService.order.orderLines || this.saleService.order.orderLines.length === 0) {
      this.saleService.resetOrder();
      this.navigateToRootPage();
      return;
    }
  }

  hide(displayItem: DisplayItem, context: any) {
    super.hide(displayItem, context);

    if (this.isPaymentOperationInProgress) {
      return;
    }

    if (this.saleService.order && this.saleService.order.orderLines && this.saleService.order.orderLines.length !== 0) {
      this.saleService.resetOrder();
    }
  }

  get isPaymentConfirmationAvalible(): boolean {
    return this.saleService.paymentSession.amountPaidIn.isPositive;
  }
}
