import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';

@Injectable()
export class MoneyExchangeGridWorkflowService extends BaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.MoneyExchange;
  }

  processAction(displayItem: DisplayItem, context: any): boolean {
    const result = super.processAction(displayItem, context);
    if (!result) {
      return false;
    }

    if (displayItem) {
      this.dispatcherService.onButtonMoneyExchangeClick();
      return true;
    }

    return false;
  }
}
