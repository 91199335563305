import { CreditCardTerminalEventType } from './credit-card-terminal-event-type';

export class CreditCardTerminalEvent {
  eventType: CreditCardTerminalEventType;
  eventInfo: string;

  constructor(eventType: CreditCardTerminalEventType, eventInfo: string) {
    this.eventType = eventType;
    this.eventInfo = eventInfo;
  }

  static fromOther(x: CreditCardTerminalEvent): CreditCardTerminalEvent {
    return new CreditCardTerminalEvent(x.eventType, x.eventInfo);
  }

  toString(): string {
    return `${CreditCardTerminalEventType[this.eventType]} '${this.eventInfo == null ? '' : this.eventInfo}'`
  }
}
