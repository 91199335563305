import { Injectable, Injector } from "@angular/core";
import { LoggingService } from "../logging/logging.service";

@Injectable()
export class TurnstileSimulatorService {
  private _isEnterAllowed = false;

  constructor(
  ) {
  }

  openEnter() {
    if (this._isEnterAllowed)
      return;

    this._isEnterAllowed = true;
  }

  closeEnter() {
    if (!this._isEnterAllowed)
      return;

    this._isEnterAllowed = false;
  }

  get isEnterAllowed(): Boolean {
    return this._isEnterAllowed;
  }
}
