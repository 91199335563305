import { Injectable, Inject, Injector } from '@angular/core';
import { TouchTileService } from '../touch-tile/touch-tile.service';
import { TouchTileType } from '../../lib/touch-tile/touch-tile-type';
import { TouchTileColorSet } from '../../lib/touch-tile/touch-tile-color-set';
import { AdditionalPropertiesConfigurationService } from "../configuration/additional-properties-configuration.service";
import { RunningLightScenario } from '../../lib/touch-tile/running-light-scenario';
import { ScenarioType } from '../../lib/touch-tile/scenario-type';

@Injectable()
export class LiteTouchTileService {

  private isTicketInPrinter: boolean;

  constructor(
    private touchtileService: TouchTileService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
    protected injector: Injector,
  ) {
  }

  standby() {
    this.touchtileService.enableTouchTiles(
      [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.CoinAcceptorLight, TouchTileType.CreditCardLight],
      [
        new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorActive),
        new TouchTileColorSet([TouchTileType.CoinAcceptorLight, TouchTileType.CreditCardLight], this.additionalPropertiesConfigurationService.colorDefault),
      ],
      new RunningLightScenario(this.additionalPropertiesConfigurationService.colorActive, ScenarioType.On),
    );
  }

  coinsPayment() {
    this.touchtileService.enableTouchTiles(
      [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.CoinAcceptorLight],
      [
        new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorActive),
        new TouchTileColorSet([TouchTileType.CoinAcceptorLight], this.additionalPropertiesConfigurationService.colorDefault),
      ],
      new RunningLightScenario(this.additionalPropertiesConfigurationService.colorActive, ScenarioType.On),
    );
  }

  creditCardPayment() {
    this.touchtileService.enableTouchTiles(
      [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.CreditCardLight],
      [
        new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorActive),
        new TouchTileColorSet([TouchTileType.CreditCardLight], this.additionalPropertiesConfigurationService.colorDefault),
      ],
      new RunningLightScenario(this.additionalPropertiesConfigurationService.colorActive, ScenarioType.On),
    );
  }

  returningChange() {
    this.touchtileService.enableTouchTiles(
      [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.TicketBoxLight, TouchTileType.CoinBoxLight],
      [
        new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorSuccess),
        new TouchTileColorSet([TouchTileType.TicketBoxLight, TouchTileType.CoinBoxLight], this.additionalPropertiesConfigurationService.colorDefault),
      ],
      new RunningLightScenario(this.additionalPropertiesConfigurationService.colorSuccess, ScenarioType.LeftToRight),
    );
  }

  returningAmountPaid() {
    if (this.isTicketInPrinter) {
      this.touchtileService.enableTouchTiles(
        [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.CoinBoxLight, TouchTileType.TicketBoxLight],
        [
          new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorActive),
          new TouchTileColorSet([TouchTileType.CoinBoxLight, TouchTileType.TicketBoxLight], this.additionalPropertiesConfigurationService.colorDefault),
        ],
        new RunningLightScenario(this.additionalPropertiesConfigurationService.colorActive, ScenarioType.On),
      );
    } else {
      this.touchtileService.enableTouchTiles(
        [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.CoinBoxLight],
        [
          new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorActive),
          new TouchTileColorSet([TouchTileType.CoinBoxLight], this.additionalPropertiesConfigurationService.colorDefault),
        ],
        new RunningLightScenario(this.additionalPropertiesConfigurationService.colorActive, ScenarioType.On),
      );
    }
  }

  ticketPrinting() {
    this.touchtileService.enableTouchTiles(
      [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.TicketBoxLight, TouchTileType.CoinBoxLight],
      [
        new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorSuccess),
        new TouchTileColorSet([TouchTileType.TicketBoxLight, TouchTileType.CoinBoxLight], this.additionalPropertiesConfigurationService.colorDefault),
      ],
      new RunningLightScenario(this.additionalPropertiesConfigurationService.colorSuccess, ScenarioType.LeftToRight),
    );
  }

  turnstileEnterAllowed() {
    if (this.isTicketInPrinter) {
      this.touchtileService.enableTouchTiles(
        [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.TicketBoxLight],
        [
          new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorSuccess),
          new TouchTileColorSet([TouchTileType.TicketBoxLight], this.additionalPropertiesConfigurationService.colorDefault),
        ],
        new RunningLightScenario(this.additionalPropertiesConfigurationService.colorSuccess, ScenarioType.LeftToRight),
      );
    } else {
      this.touchtileService.enableTouchTiles(
        [TouchTileType.FrameLight, TouchTileType.LogoLight],
        [
          new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorSuccess),
        ],
        new RunningLightScenario(this.additionalPropertiesConfigurationService.colorSuccess, ScenarioType.LeftToRight),
      );
    }
  }

  rfidAccess() {
    this.touchtileService.enableTouchTiles(
      [TouchTileType.FrameLight, TouchTileType.LogoLight],
      [
        new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorActive),
      ],
      new RunningLightScenario(this.additionalPropertiesConfigurationService.colorActive, ScenarioType.On),
    );
  }

  rfidAccessDenied() {
    this.touchtileService.enableTouchTiles(
      [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.RFIDLight],
      [
        new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.RFIDLight], this.additionalPropertiesConfigurationService.colorError),
      ],
      new RunningLightScenario(this.additionalPropertiesConfigurationService.colorError, ScenarioType.On),
    );
  }

  maintenanceMode() {
    this.touchtileService.enableTouchTiles(
      [TouchTileType.FrameLight, TouchTileType.LogoLight, TouchTileType.CoinAcceptorLight, TouchTileType.CreditCardLight],
      [
        new TouchTileColorSet([TouchTileType.FrameLight, TouchTileType.LogoLight], this.additionalPropertiesConfigurationService.colorError),
        new TouchTileColorSet([TouchTileType.CoinAcceptorLight, TouchTileType.CreditCardLight], this.additionalPropertiesConfigurationService.colorError),
      ],
      new RunningLightScenario(this.additionalPropertiesConfigurationService.colorError, ScenarioType.On),
    );
  }

  setTicketInPrinter() {
    this.isTicketInPrinter = true;
  }

  resetTicketInPrinter() {
    this.isTicketInPrinter = false;
  }
}
