import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { ComponentsModule } from '../components/components.module';
import { LanguagesButtonComponent } from '../../components/general/languages/languages-button.component';
import { LanguagesComponent } from '../../components/general/languages/languages.component';
import { LanguageSelectionPopUpComponent } from '../../components/general/languages/language-selection-popup/language-selection-popup.component';


@NgModule({
  declarations: [
    LanguagesButtonComponent,
    LanguagesComponent,
    LanguageSelectionPopUpComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    VUTranslateModule,
    ComponentsModule,
  ],
  entryComponents: [
    LanguageSelectionPopUpComponent,
  ],
  exports: [
    LanguagesButtonComponent,
    LanguagesComponent,
    LanguageSelectionPopUpComponent,
  ]
})
export class LanguagesModule { }
