import { Injectable } from '@angular/core';
import { Observable, from, Subscription, of } from 'rxjs';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { SaleService } from '../../../services/sale.service';
import { OrderType, Money, Product, OrderLine } from '../../../lib/lib';
import { map, switchMap, tap } from 'rxjs/operators';
import { ExternalBaseService } from '../../external-base/services/external-base.service';
import { ExternalCardRechargeModel } from '../models/external-card-recharge-model';
import { ExternalPaymentType } from '../models/external-payment-type.enum';

@Injectable()
export class ExternalPaymentService extends ExternalBaseService {

  private _orderId: string;
  private _paymentControllerUrl: string;
  private _paymentType: string;
  private _returnUrl: string;
  private _cancelUrl: string;
  private _productId: number;
  private _cardNumber: string;
  private _ean13: string;
  private _cardBalance: number;
  private _personalNumber: string;

  private vuCommunicationService: VuCommunicationService;
  private saleService: SaleService;
  private _getProductsByIdsSubscription: Subscription;
  private _loadOrderSubscription: Subscription;

  protected init(): void {
    super.init();
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.saleService = this.injector.get(SaleService);
  }

  initialize(params: any): void {
    this._orderId = params.order_id;
    this._paymentControllerUrl = params.url;
    this._paymentType = params.payment_type;
    this._returnUrl = params.return_url;
    this._cancelUrl = params.cancel_url;
    this._productId = +params.product_id;
    this._cardNumber = params.card_number;
    this._ean13 = params.ean13;
    this._cardBalance = +params.card_balance || 0;
    this._personalNumber = params.personal_number;
    super.initialize(params);
  }

  protected _deactivate(): void {
    this.unsubscribeLoadOrderSubscription();
    this.unsubscriptionGetProductsByIdsSubscription();
    super._deactivate();
  }

  protected get canActivate(): boolean {
    return super.canActivate
      && (this._orderId || this._productId)
      && !!this._paymentControllerUrl;
  }

  protected internalActivate(): void {
    if (this._orderId) {
      this._orderPayment();
      return;
    }

    if (this._productId && this._cardNumber) {
      this.cardRecharge();
      return;
    }
  }

  private cardRecharge(): void {
    this.unsubscriptionGetProductsByIdsSubscription();
    this._getProductsByIdsSubscription = from(this.vuCommunicationService.vuHttp.getProductsByIds([this._productId]))
      .subscribe(products => {
        if (!products || products.length === 0) {
          super.activateComplete();
          return;
        }

        const model = new ExternalCardRechargeModel();
        model.product = products[0];
        model.cardNumber = this._cardNumber;
        model.ean13 = this._ean13;
        model.cardBalance = this._cardBalance;
        model.personalNumber = this._personalNumber;
        this.dispatcherService.externalCardRecharge(model);

        super.activateComplete();
      });
  }

  private unsubscriptionGetProductsByIdsSubscription(): void {
    if (this._getProductsByIdsSubscription) {
      this._getProductsByIdsSubscription.unsubscribe();
      this._getProductsByIdsSubscription = null;
    }
  }

  private _orderPayment(): void {
    this.unsubscribeLoadOrderSubscription();
    this._loadOrderSubscription = this._loadOrder(this._orderId).subscribe(
      () => {
        this.loggingService.info('ExternalPaymentService. _orderPayment.');

        if (this.saleService.order.amountTotal.isNegative) {
          this.dispatcherService.externalPayout();
          super.activateComplete();
          return;
        }

        if (this._paymentType === ExternalPaymentType.Card) {
          this.loggingService.info('ExternalPaymentService. _orderPayment. toPaymentCard');
          this.dispatcherService.toPaymentCard();
        } else {
          this.loggingService.info('ExternalPaymentService. _orderPayment. toPaymentCash');
          this.dispatcherService.toPaymentCash();
        }
      },
      (error) => {
        this.loggingService.info(`ExternalPaymentService. _orderPayment. Error: ${error}`);
        this.dispatcherService.back();
        this.activateComplete();
      });
  }

  private unsubscribeLoadOrderSubscription(): void {
    if (this._loadOrderSubscription) {
      this._loadOrderSubscription.unsubscribe();
      this._loadOrderSubscription = null;
    }
  }

  private _loadOrder(orderId: string): Observable<void> {

    return of(true).pipe(
      switchMap(() => {
        if (!this._paymentControllerUrl) {
          throw Error('External payment url is null');
        }

        if (orderId === '-1') {
          return this.vuCommunicationService.vuHttp.getOrderCache().pipe(
            switchMap(result => {
              let data = {
                properties: null,
                order_lines: null,
              };

              if (result) {
                data = {
                  properties: null,
                  order_lines: this._getPosOrderLines(result.orderLines)
                };
              }
              return this.createOrder(data);
            }),
            tap(_ => { this.saleService.order.type = OrderType.Sale; }),
          );
        }

        const requestValues = new Map<string, any>();
        requestValues.set('orderId', orderId);


        const paymentControllerUrl = this.configurationService.configuration.odooUrl + this._paymentControllerUrl;

        return from(this.vuCommunicationService.ExternalApiService
          .sendPostRequestOdooJson(paymentControllerUrl, requestValues))
          .pipe(switchMap((data) => this.createOrder(data)));
      }),
    );
  }

  private createOrder(
    saleOrder: {
      properties: any,
      order_lines: Array<{
        product_id: number,
        quantity: number,
        price: number,
        properties: any,
      }>
    }): Observable<void> {

    if (!saleOrder) {
      throw new Error('ExternalPaymentService. saleOrder is null');
    }

    if (!saleOrder.order_lines) {
      throw new Error('ExternalPaymentService. saleOrder no order lines');
    }

    return from(this.vuCommunicationService.vuHttp
      .getProductsByIds(saleOrder.order_lines.map(line => line.product_id)))
      .pipe(map((products: Product[]) => {
        this.saleService.resetOrder();
        for (const line of saleOrder.order_lines) {
          const product = products.find(item => item.id === line.product_id);
          if (!product) {
            continue;
          }
          const orderLine = new OrderLine(
            product,
            line.quantity,
            new Money(line.price, product.price.currencyCode));
          orderLine.quantity = line.quantity;
          this.saleService.order.orderLines.push(orderLine);
          if (line.properties) {
            Object.entries(line.properties).forEach(
              ([key, value]) => orderLine.updateProperties(key, value)
            );
          }
        }

        this.saleService.order.type = OrderType.SaleOrder;
        if (saleOrder.properties) {
          Object.entries(saleOrder.properties).forEach(
            ([key, value]) => this.saleService.order.updateProperties(key, value)
          );
        }
      }));
  }

  get isEnabled(): boolean {
    return super.isEnabled || window.location.href.includes('external-payment') || !!this.orderId;
  }

  get orderId(): string {
    return this._orderId;
  }

  get cancelUrl(): string {
    if (!this._cancelUrl) {
      return;
    }
    return this.configurationService.configuration.odooUrl + this._cancelUrl;
  }

  get returnUrl(): string {
    if (!this._returnUrl) {
      return;
    }
    return this.configurationService.configuration.odooUrl + this._returnUrl;
  }

  _getPosOrderLines(orderLines: Array<any>): Array<any> {
    const posOrderLines = [];
    if (orderLines && orderLines.length > 0) {
      for (const orderLine of orderLines) {
        const posOrderLine = {
          product_id: orderLine.productId,
          quantity: orderLine.quantity,
          price: orderLine.price,
          properties: {
            booking_date: orderLine.bookingDate,
            booking_time_schedule_slot_id: orderLine.bookingSlotId,
            group_size: orderLine.groupSize,
          },
        };

        posOrderLines.push(posOrderLine);
      }
    }
    return posOrderLines;
  }

}
