<select id="columnsCount" [(ngModel)]="configurationId">
  <option *ngFor="let r of configurationCount" [ngValue]="r">{{ r == 0 ? 'None': 'Configuration ' + (r) }}</option>
</select>
<label [attr.for]="'rowCount'">{{ ('Columns' | translate) + ':' }}</label>
<select id="rowCount" [(ngModel)]="rowCount">
  <option *ngFor="let r of rowNumbers" [ngValue]="r">{{ r }}</option>
</select>
<label [attr.for]="'columnsCount'">{{ ('Rows' | translate) + ':' }}</label>
<select id="columnsCount" [(ngModel)]="columnsCount">
  <option *ngFor="let r of columnsNumbers" [ngValue]="r">{{ r }}</option>
</select>
<label [attr.for]="'extendedDisplay'">{{ ('Extended Display' | translate) + ':' }}</label>
<select id="extendedDisplay" [(ngModel)]="extendedDisplay">
  <option *ngFor="let value of extendedDisplays" [ngValue]="value">{{ value }}</option>
</select>
