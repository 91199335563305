import { Component } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';
import { LanguageService } from '../../../services/language.service';
import { LanguageSelectionPopUpComponent } from './language-selection-popup/language-selection-popup.component';
import { ModalService } from '../../../services/gui/modal/modal-service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  moduleId: module.id,
  selector: 'languages-button',
  templateUrl: 'languages-button.component.html',
  styleUrls: ['languages-button.component.scss']
})
export class LanguagesButtonComponent {
  private modalRef: BsModalRef;

  constructor(
    private dispatcherService: DispatcherService,
    protected languageService: LanguageService,
    private modalService: ModalService,
  ) {
  }

  onClick(): void {
    const locales = this.languageService.avalibleLocalesForCustomer;
    if (locales && locales.length > 2) {
      if (this.modalRef) {
        return;
      }

      this.modalRef = this.modalService.show(
        LanguageSelectionPopUpComponent,
        null,
        (id) => {
          this.modalRef = null;
          if (id) {
            this.languageService.setSelectedLanguageById(id);
            this.dispatcherService.onUserActivity();
          }
        }
      );
    } else {
      if (locales && locales.length === 2 && locales[0] && locales[1]) {
        const lang1 = locales[0].id;
        const lang2 = locales[1].id;

        this.languageService.setSelectedLanguageById(this.languageService.getLanguage() === lang2 ? lang1 : lang2);
      }
    }
    this.dispatcherService.onUserActivity();
  }

  get flagFilePath(): string {
    switch (this.languageService.getLanguage()) {
      case 'en-US':
        return './../../../../static/images/lang_button.png';
      case 'uk-UA':
        return './../../../../static/images/lang_button_ua.png';
      case 'cs-CZ':
        return './../../../../static/images/lang_button_cz.png';
      case 'pl-PL':
        return './../../../../static/images/lang_button_pl.png';
      case 'fr-FR':
        return './../../../../static/images/lang_button_fr.png';
      case 'ro-RO':
        return './../../../../static/images/lang_button_ro.png';
      case 'tr-TR':
        return './../../../../static/images/lang_button_tr.png';
      default:
        return './../../../../static/images/lang_button_de.png';
    }
  }

  get currentLangClass(): string {
    return this.languageService.getLanguage() + '-lang';
  }
}
