import { Injectable, Injector } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { LoadingSpinnerService } from '../../../services/loading-spinner/loading-spinner.service';
import { NonOperationalService } from '../../../services/non-operational.service';
import { LoggingService } from '../../../services/logging/logging.service';
import { LanguageService } from '../../../services/language.service';
import { Subscription } from 'rxjs';
import { Params } from '@angular/router';

@Injectable()
export abstract class ExternalBaseService {

  private lang: string;
  private activateStart: boolean;
  private initializeInProgress: boolean;
  private enabled: boolean;
  private eventStateChangedSubscription: Subscription;
  private eventConfigurationChanged: Subscription;

  protected queryParams: Params;

  protected dispatcherService: DispatcherService;
  protected loadingSpinnerService: LoadingSpinnerService;
  protected configurationService: ConfigurationService;
  protected nonOperationalService: NonOperationalService;
  protected loggingService: LoggingService;
  protected languageService: LanguageService;
  private isPauseMode: boolean;

  protected inPauseActivation: boolean;

  constructor(
    protected injector: Injector,
  ) {
    this.init();
  }

  protected init(): void {
    this.dispatcherService = this.injector.get(DispatcherService);
    this.loadingSpinnerService = this.injector.get(LoadingSpinnerService);
    this.configurationService = this.injector.get(ConfigurationService);
    this.nonOperationalService = this.injector.get(NonOperationalService);
    this.loggingService = this.injector.get(LoggingService);
    this.languageService = this.injector.get(LanguageService);
  }

  initialize(queryParams: Params): void {
    this.queryParams = queryParams;
    this.enabled = true;
    this.lang = queryParams?.lang;

    this.loadingSpinnerService.show();
    this.initializeInProgress = true;
  }

  deactivate(): void {
    this._deactivate();
  }

  protected _deactivate(): void {
    this.unsubscribeEventConfigurationChanged();
    this.unsbuscribeEventStateChangedSubscription();
  }

  activate(): void {
    this.unsubscribeEventConfigurationChanged();
    this.eventConfigurationChanged = this.dispatcherService.onConfigurationChangedSubscribe((x) => {
      this.tryActivate();
      if (this.lang) {
        this.languageService.setSelectedLanguageById(this.lang);
      }
    });

    this.unsbuscribeEventStateChangedSubscription();
    this.eventStateChangedSubscription = this.nonOperationalService.eventStateChanged.subscribe((x) => {
      this.tryActivate();
    });

    this.tryActivate();
  }

  private unsubscribeEventConfigurationChanged(): void {
    if (this.eventConfigurationChanged) {
      this.eventConfigurationChanged.unsubscribe();
      this.eventConfigurationChanged = null;
    }
  }

  private unsbuscribeEventStateChangedSubscription(): void {
    if (this.eventStateChangedSubscription) {
      this.eventStateChangedSubscription.unsubscribe();
      this.eventStateChangedSubscription = null;
    }
  }

  private tryActivate(): void {
    if (!this.canActivate) {
      return;
    }

    this.activateStart = true;

    this.loggingService.info('ExternalBaseService. tryActivate started..');

    if (this.lang) {
      this.languageService.setSelectedLanguageById(this.lang);
    }

    this.internalActivate();
  }

  protected get canActivate(): boolean {
    return !this.activateStart
      && this.configurationService.configuration.odooUrl
      && this.configurationService.configuration.odooToken
      && this.nonOperationalService.state.isConnected;
  }

  protected internalActivate(): void {
    this.activateComplete();
  }

  activateComplete(): boolean {
    if (!this.initializeInProgress) {
      return false;
    }

    this.unpauseActivation();

    this.initializeInProgress = false;
    this.loadingSpinnerService.hide();
    return true;
  }

  pauseActivation(): boolean {
    if (!this.initializeInProgress || this.inPauseActivation) {
      return false;
    }

    this.inPauseActivation = true;
    this.loadingSpinnerService.hide();
    return true;
  }

  unpauseActivation(): boolean {
    if (!this.initializeInProgress || !this.inPauseActivation) {
      return false;
    }

    this.inPauseActivation = false;
    this.loadingSpinnerService.show();
    return true;
  }

  get isInitializeInProgress(): boolean {
    return this.initializeInProgress;
  }

  get isEnabled(): boolean {
    return this.enabled;
  }

  pauseActivate(): void {
    if (this.initializeInProgress && !this.isPauseMode) {
      this.loadingSpinnerService.hide();
    }
  }

  restoreActivate(): void {
    if (this.isPauseMode) {
      this.loadingSpinnerService.show();
    }
  }
}
