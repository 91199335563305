import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { CardDispenserBaseGridWorkflowService } from './card-dispenser-base-grid-workflow.service';
import { Subscription } from 'rxjs';

@Injectable()
export class CardDispenserMonitorGridWorkflowService extends CardDispenserBaseGridWorkflowService {

  private subscriptionAvailabilityChanged: Subscription;

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CardDispenserMonitor;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);
    if (this.cardDispenserService.isAvailable) {
      this.subscriptionAvailabilityChanged = this.cardDispenserService.availabilityChanged
        .subscribe(
          () => {
            if (!this.cardDispenserService.isAvailable) {
              this.cardDispenserNotAvailable();
            }
          }
        );
    } else {
      this.cardDispenserNotAvailable();
    }
  }

  hide(displayItem: DisplayItem, context: any) {
    super.hide(displayItem, context);

    if (this.subscriptionAvailabilityChanged) {
      this.subscriptionAvailabilityChanged.unsubscribe();
      this.subscriptionAvailabilityChanged = null;
    }
  }

  cardDispenserNotAvailable() {
    this.showCardDispenserNotAvailable();
    if (!this.navigateToNavigationItem()) {
      this.navigateBack();
    }
  }
}
