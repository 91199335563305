import { Action } from '@ngrx/store';
import { ShopState } from '../lib/lib';
import { PayloadAction } from '../lib/payload-action';

const initialState = new ShopState();

export class ShopActionType {
  static readonly SHOP_CAN_PAY_CASH = 'ShopCanPayCash';
  static readonly SHOP_CAN_PAY_CARD = 'ShopCanPayCard';
  static readonly SHOP_ORDER_UID = 'ShopOrderUid';
  static readonly SHOP_IS_ORDER_SAVING = 'ShopIsOrderSaving';
  static readonly SHOP_IS_TICKET_PRINTING = 'ShopIsTicketPrinting';
  static readonly SHOP_IS_RECEIPT_PRINTING = 'ShopIsReceiptPrinting';
}

export function shopReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;
  switch (action.type) {
    case ShopActionType.SHOP_CAN_PAY_CASH:
      state.canPayCash = action.payload;
      break;
    case ShopActionType.SHOP_CAN_PAY_CARD:
      state.canPayCard = action.payload;
      break;
    case ShopActionType.SHOP_ORDER_UID:
      state.orderUid = action.payload;
      break;
    case ShopActionType.SHOP_IS_ORDER_SAVING:
      state.isOrderSaving = action.payload;
      break;
    case ShopActionType.SHOP_IS_TICKET_PRINTING:
      state.isTicketPrinting = action.payload;
      break;
    case ShopActionType.SHOP_IS_RECEIPT_PRINTING:
      state.isReceiptPrinting = action.payload;
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? ShopState.fromOther(state) : state;
}
