import { Money, Order, GlobalSettings } from './lib';
import { ILog } from '../services/logging/log.interface';

export class PaymentSession {
  private order: Order;
  cardTerminalReceipt: string = null;
  private moneyFlow: Money[] = [];
  private log: ILog = GlobalSettings.getLog();
  private internalIsSessionOpen = false;
  private internalAmountTempToPayOut = Money.empty;
  private internalAmountTempPaidOut = Money.empty;
  isCancelled = false;
  amountChangePaidOut = Money.empty;
  minAmount: Money;
  maxAmount: Money;

  constructor(order: Order) {
    this.order = order;
  }

  resetAmountTempPayout() {
    this.amountTempPaidOut = Money.empty;
    this.amountTempToPayOut = Money.empty;
  }

  get amountTempToPayOut(): Money {
    return this.internalAmountTempToPayOut;
  }

  set amountTempToPayOut(value: Money) {
    this.internalAmountTempToPayOut = value;
  }

  get amountTempPaidOut(): Money {
    return this.internalAmountTempPaidOut;
  }

  set amountTempPaidOut(value: Money) {
    this.internalAmountTempPaidOut = value;
  }

  get amountToPay(): Money {
    return this.order.amountTotal;
  }

  get amountChange(): Money {
    let change = Money.empty;
    if (this.isFloatingAmount && this.maxAmount && this.maxAmount.isPositive) {
      change = this.amountPaidIn.distract(this.maxAmount);
    } else {
      change = this.amountPaidIn.distract(this.amountToPay);
    }
    return this.round(change.isPositive ? change : change.zero());
  }

  get amountPaidIn(): Money {
    return this.getTotalAmountDirection(true);
  }

  get amountPaidOut(): Money {
    return this.getTotalAmountDirection(false);
  }

  get amountToReturnOnCancel(): Money {
    const amount = this.amountPaidIn.distract(this.amountPaidOut);
    return this.round(amount.isPositive ? amount : amount.zero());
  }

  get isAmountChangePaidOut(): boolean {
    return this.amountPaidOut.distract(this.amountChange).value >= 0;
  }

  get isAmountPaidPaidOut(): boolean {
    return this.amountPaidOut.distract(this.amountPaidIn).value >= 0;
  }

  get amountRemainingToPay(): Money {
    return this.round(this.amountToPay.distract(this.amountPaidIn));
  }

  get paidAmountIncludingChange(): Money {
    return this.amountPaidIn.distract(this.amountPaidOut);
  }

  private getTotalAmountDirection(isPayIn: boolean) {
    let result = new Money(0, 'EUR');
    for (const m of this.moneyFlow) {
      if ((isPayIn && m.isPositive) || (!isPayIn && m.isNegative)) {
        result = result.add(m);
      }
    }
    return this.round(result.absolute());
  }

  registerMoney(x: Money) {
    if (this.internalIsSessionOpen) {
      this.moneyFlow.push(x);
      this.log.info(this);
      if (x.isNegative) {
        this.amountTempPaidOut = this.amountTempPaidOut.add(x.absolute());
      }
    }
  }

  registerCompleteAmountToPay() {
    if (this.amountToPay.isPositive) {
      this.registerMoney(this.amountToPay);
    } else {
      this.log.error(
        'registerAmountToPay. Negative and zero amounts not supported.'
      );
    }
  }

  openSession(minAmount: Money = null, maxAmount: Money = null) {
    this.internalIsSessionOpen = true;
    this.isCancelled = false;
    this.cardTerminalReceipt = null;
    this.minAmount = minAmount;
    this.maxAmount = maxAmount;
    this.log.info(`PaymentSession. Opened.`);
  }

  get isFloatingAmount(): boolean {
    return this.minAmount != null && this.minAmount.value >= 0 || this.maxAmount != null && this.maxAmount.isPositive;
  }

  closeSession() {
    this.internalIsSessionOpen = false;
    if (this.amountPaidOut.value > this.amountChange.value) {
      const m = `amountPaidOut: ${this.amountPaidOut}. > amountChange: ${
        this.amountChange
        }`;
      this.log.error(`PaymentSession. Validation failed. ${m}`);
    }
    this.log.info(`PaymentSession. Closed. ${this}`);
  }

  get isSessionOpen(): boolean {
    return this.internalIsSessionOpen;
  }

  private round(x: Money): Money {
    return new Money(GlobalSettings.numberRound(x.value), x.currencyCode);
  }

  toString() {
    const amountToPay = `amountToPay: ${this.amountToPay}`;
    const amountChange = `amountChange: ${this.amountChange}`;
    const amountPaidIn = `amountPaidIn: ${this.amountPaidIn}`;
    const amountPaidOut = `amountPaidOut: ${this.amountPaidOut}`;
    const amountTempToPayOut = `amountTempToPayOut: ${this.amountTempToPayOut}`;
    const amountTempPaidOut = `amountTempPaidOut: ${this.amountTempPaidOut}`;
    const isCancelled = `isCancelled: ${this.isCancelled}`;
    let moneyFlow = '';
    const minAmount = `minAmount: ${this.minAmount}`;
    const maxAmount = `maxAmount: ${this.maxAmount}`;
    for (const m of this.moneyFlow) {
      moneyFlow += `${m} `;
    }
    return `PaymentSession: ${amountToPay}. ${amountPaidIn}. ${amountChange}. ${amountPaidOut}. ${isCancelled}.
            ${amountTempToPayOut}. ${amountTempPaidOut}.
            ${moneyFlow}
            ${minAmount}. ${maxAmount}`;
  }
}
