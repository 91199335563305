export class ScreenSaverConfiguration {
  timeout: number;
  timeFrom: number;
  timeTo: number;
  mode: string;
  message: string;
  imageShowTimeout: number;
  imagesIds: string[];
  disableLntBoard: boolean;

  static fromJson(other: any): ScreenSaverConfiguration {
    const screenSaverConfiguration = new ScreenSaverConfiguration();
    screenSaverConfiguration.timeout = other.timeout;
    screenSaverConfiguration.timeFrom = other.timeFrom;
    screenSaverConfiguration.timeTo = other.timeTo;
    screenSaverConfiguration.mode = other.mode;
    screenSaverConfiguration.message = other.message;
    screenSaverConfiguration.imageShowTimeout = other.imageShowTimeout;
    screenSaverConfiguration.imagesIds = other.imagesIds;
    screenSaverConfiguration.disableLntBoard = other.disableLntBoard;
    return screenSaverConfiguration;
  }
}
