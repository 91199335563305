import { Injectable, Injector, EventEmitter } from '@angular/core';
import { LoggingService } from './logging/logging.service';
import { ScreenSaverConfiguration } from '../lib/screen-saver-configuration';
import { DateTimeHelper } from '../lib/date-time-helper';
import { DispatcherService } from './dispatcher.service';
import { TouchTileService } from './touch-tile/touch-tile.service';


@Injectable()
export class ScreenSaverService {

  private iConfiguration: ScreenSaverConfiguration;

  private loggingService: LoggingService;
  private dispatcherService: DispatcherService;
  private touchTileService: TouchTileService;

  private iStarted: boolean;

  private oTimeoutTimer: any = null;
  private iScheduleTimer: any = null;
  private readonly iScheduleCheckTimeoutSec = 15;
  private iScheduleShow: boolean;
  private iIsShow: boolean;

  eventShowScreenSaverStart: EventEmitter<any> = new EventEmitter();

  constructor(
    private injector: Injector,
  ) {
    this.loggingService = this.injector.get(LoggingService);
    this.dispatcherService = this.injector.get(DispatcherService);
    this.touchTileService = this.injector.get(TouchTileService);
  }

  public get configuration(): ScreenSaverConfiguration {
    return this.iConfiguration;
  }

  public set configuration(value: ScreenSaverConfiguration) {
    const isEqualsConfig = JSON.stringify(this.iConfiguration) === JSON.stringify(value);
    this.iConfiguration = value;
    this._restart_timeout_timer();
    if (!isEqualsConfig) {
      this._restart_schedule_timer();
    }
  }

  start() {
    if (!this.iStarted) {
      this.iStarted = true;
      this.loggingService.info('Screen Saver Service - Start');
    }

    this._restart_timeout_timer();

    this._restart_schedule_timer();
  }

  stop() {
    if (this.iStarted) {
      this.iStarted = false;
      this._clear_timeout_timer();
      this._clear_schedule_timer();
      this._stopShow();
      this.loggingService.info('Screen Saver Service - Stop');
    }
  }

  private _restart_schedule_timer() {
    if (!this.isScheduleActive) {
      this.loggingService.info(`Screen Saver Service - Schedule disabled`);
      return;
    }

    const fromTime =
    `${DateTimeHelper.getHour(this.iConfiguration.timeFrom)}:${('0' + DateTimeHelper.getMinutes(this.iConfiguration.timeFrom)).slice(-2)}`;
    const toTime =
    `${DateTimeHelper.getHour(this.iConfiguration.timeTo)}:${('0' + DateTimeHelper.getMinutes(this.iConfiguration.timeTo)).slice(-2)}`;

    this.loggingService.info(
      `Screen Saver Service - Schedule started, Timeout: ${this.iScheduleCheckTimeoutSec} sec, from time: ${fromTime}, to time: ${toTime}`);

    this._clear_schedule_timer();
    this.iScheduleTimer = setInterval(() => {
      this._check_schedule();
    }, this.iScheduleCheckTimeoutSec * 1000);
  }

  private _clear_schedule_timer() {
    if (!this.iScheduleTimer) {
      return;
    }
    clearInterval(this.iScheduleTimer);
    this.iScheduleTimer = null;
  }

  private _restart_timeout_timer() {
    this._clear_timeout_timer();

    if (!this.iConfiguration || this.iConfiguration.timeout < 1) {
        this.loggingService.info(`Screen Saver Service - Timeout disabled`);
        return;
    }

    this.loggingService.info(`Screen Saver Service - Timeout restart, Timeout: ${this.iConfiguration.timeout} sec`);

    this.oTimeoutTimer = setTimeout(() => {
      this._startShow();
      this.loggingService.info(`Screen Saver Service - Timeout show start`);
    }, this.iConfiguration.timeout * 1000);
  }

  private _clear_timeout_timer() {
    if (!this.oTimeoutTimer) {
      return;
    }
    clearTimeout(this.oTimeoutTimer);
    this.oTimeoutTimer = null;
  }

  private _check_schedule() {
    if (this._schedule_is_time) {
      this._startShow();
      if (!this.iScheduleShow) {
        this.loggingService.info(`Screen Saver Service - Schedule show start`);
        this.iScheduleShow = true;
      }
    } else {
      if (this.iScheduleShow) {
        this.loggingService.info(`Screen Saver Service - Schedule show stop`);
        this.iScheduleShow = false;
      }
      if (!this.iConfiguration || this.iConfiguration.timeout < 1) {
        this.forceStopShow();
      }
    }
  }

  get isScheduleActive(): boolean {
    return this.iConfiguration
      && this.iConfiguration.timeTo >= 0
      && this.iConfiguration.timeFrom >= 0
      && this.iConfiguration.timeFrom !== this.iConfiguration.timeTo;
  }

  private get _schedule_is_time(): boolean {
    if (!this.isScheduleActive) {
      return false;
    }

    const now = DateTimeHelper.now;
    const today = DateTimeHelper.getToday(now);

    const dateFrom = DateTimeHelper.createDate(today, this.iConfiguration.timeFrom);
    const dateTo = DateTimeHelper.createDate(today, this.iConfiguration.timeTo);

    if (this.iConfiguration.timeFrom > this.iConfiguration.timeTo) {
      return now <= dateTo || now >= dateFrom;
    } else {
      return now >= dateFrom && now <= dateTo;
    }
  }

  private _startShow(): boolean {
    if (this.iIsShow) {
      return false;
    }

    this.iIsShow = true;

    if (this.iConfiguration.disableLntBoard) {
      this.touchTileService.switchLntBoard(false);
    }

    this.loggingService.info(`Screen Saver Service - Start show`);

    if (this.iConfiguration) {
      this.loggingService.info(`Screen Saver Service - Start show, Mode: ${this.iConfiguration.mode}`);
    }

    this.eventShowScreenSaverStart.emit();

    return true;
  }

  private _stopShow() {
    this.loggingService.info('Screen Saver Service - Stop show');

    if (this.iIsShow)
      this.touchTileService.switchLntBoard(true);

    this.iIsShow = false;
  }

  forceStopShow() {
    if (this.iIsShow) {
      this.dispatcherService.onUserActivity();
      this.dispatcherService.machineHardResetRoot();
    }
  }
}

