import { Component, Injector, ViewChild, } from '@angular/core';
import { Product } from '../../../lib/lib';
import { LanguageService } from '../../../services/language.service';
import { BaseModalComponent } from '../base/base-modal-component';
import { CashKeyboardComponent } from '../cash-keyboard/cash-keyboard.component';

@Component({
  selector: 'app-input-custom-price-modal',
  templateUrl: './input-custom-price-modal.component.html',
  styleUrls: ['./input-custom-price-modal.component.css']
})
export class InputCustomPriceModalComponent extends BaseModalComponent {

  private languageService: LanguageService;

  @ViewChild('cashAmount', { static: false }) cashAmount: CashKeyboardComponent;

  constructor(
    private injector: Injector,
  ) {
    super();
    this.languageService = this.injector.get(LanguageService);
  }

  get product(): Product {
    return this.internalData.product;
  }

  get headerCaption(): string {
    if (!this.product) {
      return '';
    }

    return this.product.translateName(this.language) || this.product.name;
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  close(): void {
    const price = Number.parseInt(this.cashAmount.textValue) || 0;
    super.close(price);
  }

  cancel(): void {
    super.close(0);
  }
}
