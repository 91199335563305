import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { ExternalTicketService } from './services/external-ticket.service';
import { ExternalTicketComponent } from './components/external-ticket/external-ticket.component';

@NgModule({
  declarations: [
    ExternalTicketComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    SharedModule,
  ],
  providers: [
    ExternalTicketService,
  ]
})
export class ExternalTicketModule { }
