import { Money } from '../lib';

export class AcceptedCash {
  coins: Money[];
  banknotes: Money[];

  constructor(coins: Money[], banknotes: Money[]) {
    this.coins = coins;
    this.banknotes = banknotes;
  }

  get isEmpty() {
    if (!this.coins && !this.banknotes)
      return true;

    return Money.isArrayEmptyOrZero(this.coins) && Money.isArrayEmptyOrZero(this.banknotes);
  }

  moneyToString(money: Money[]): string {
    let result = '';
    if (money == null) {
      return result;
    }

    for (const m of money) {
      result += `${m}; `;
    }
    return result;
  }

  toString() {
    return `coins: '${this.moneyToString(this.coins)}'. banknotes: '${this.moneyToString(this.banknotes)}'`;
  }

  containsMoney(money: Money): boolean {
    return Money.cointainsMoneyInArray(this.coins, money) || Money.cointainsMoneyInArray(this.banknotes, money);
  }

  static fromJson(data: any): AcceptedCash {
    if (data == null) {
      return null;
    }

    let coins = Money.toMoneyArray(data.coins);
    let banknotes = Money.toMoneyArray(data.banknotes);

    return new AcceptedCash(coins, banknotes);
  }

  static fromJsonArray(rules: any): AcceptedCash[] {
    if (rules == null) {
      return null;
    }
    return rules.map(item => { return AcceptedCash.fromJson(item) });
  }
}
