import { Utilities } from './lib';

export class VuState {
  isOfflineMode = false;
  isServiceMode = false;
  isMultipleAccessError = false;
  isBurglaryMode = false;
  isMaintenanceMode = false;
  isLoadingMode = false;
  maintenaceModeReasons: string[] = [];
  minHardwareConfigurationViolation = '';
  isAutoSaleMode = false;

  get isOutOfOrder() {
    return this.isOfflineMode
      || this.isAutoSaleMode
      || this.isServiceMode
      || this.isBurglaryMode
      || this.isMaintenanceMode
      || this.isLoadingMode
      || this.isMultipleAccessError;
  }

  static fromOther(other: any): VuState {
    return Object.assign(new VuState(), other);
  }

  public toString(): string {
    return `isOutOfOrder: '${this.isOutOfOrder}':
        isMaintenanceMode: '${this.isMaintenanceMode}'.
        maintenaceModeReasons: '${this.maintenaceModeReasons}'.
        minHardwareConfigurationViolation: '${this.minHardwareConfigurationViolation}'
        isOfflineMode: '${this.isOfflineMode}'.
        isAutoSaleMode: '${this.isAutoSaleMode}'.
        isBurglaryMode: '${this.isBurglaryMode}'.
        isServiceMode: '${this.isServiceMode}'.
        isLoadingMode: '${this.isLoadingMode}'.`;
  }

  public equals(other: VuState): boolean {

    if (!Utilities.areArraysEqual(this.maintenaceModeReasons, other.maintenaceModeReasons)) {
      return false;
    }

    return this.isMaintenanceMode === other.isMaintenanceMode
      && this.isOfflineMode === other.isOfflineMode
      && this.isAutoSaleMode === other.isAutoSaleMode
      && this.isServiceMode === other.isServiceMode
      && this.isBurglaryMode === other.isBurglaryMode
      && this.maintenaceModeReasons === other.maintenaceModeReasons
      && this.minHardwareConfigurationViolation === other.minHardwareConfigurationViolation
      && this.isLoadingMode === other.isLoadingMode;
  }
}

export enum VuRole {
  Entry = 'Entry',
  Exit = 'Exit',
}

