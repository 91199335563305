import { Component, ViewEncapsulation } from '@angular/core';
import { MessageService, MessageType, Message } from '../../../services/message.service';
import { MachineSaleShopService } from '../../../services/machines/machine-sale.service';
import { SaleService } from '../../../services/sale.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { ShowBasketButtonType } from '../../../lib/basket/show-basket-button-type';

@Component({
  selector: 'app-basket-button',
  templateUrl: './basket-button.component.html',
  styleUrls: ['./basket-button.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BasketButtonComponent {
  constructor(
    private messageService: MessageService,
    private configurationService: ConfigurationService,
    private machineSaleShopService: MachineSaleShopService,
    private saleService: SaleService,
  ) {
  }

  get visible(): boolean {
    if (
      this.showBasketButtonOnHeaderMode === ShowBasketButtonType.None ||
      this.orderLines.length === 0) {
      return false;
    }

    const state = this.machineSaleShopService.state;
    return state && (state === 'idle' || state === 'displayMode');
  }

  get showBasketButtonOnHeaderMode() {
    return !this.configurationService.configuration ||
      !this.configurationService.configuration.showBasketButtonOnHeader ||
      this.configurationService.configuration.showBasketButtonOnHeader;
  }

  onClick(): void {
    this.messageService.sendMessage(new Message(MessageType.VisualItemLeafSelected));
  }

  get orderLines() {
    if (!this.saleService.order) {
      return [];
    }
    return this.saleService.order.orderLines || [];
  }

  get count() {
    switch (this.showBasketButtonOnHeaderMode) {
      case ShowBasketButtonType.LineCount:
        const lineCount = this.orderLines.filter(x => x.quantity > 0).length;
        return lineCount;
      case ShowBasketButtonType.ItemsCount:
        let itemsCount = 0;
        this.orderLines.forEach(orderLine => {
          itemsCount += orderLine.quantity;
        });
        return itemsCount;
      default:
        return 0;
    }
  }
}
