export class FmcuState {
  isBusy: boolean;
  timeout: number;
  barcode: string;

  static createFromAny(data: any): FmcuState {
    const fmcuState = new FmcuState();

    fmcuState.isBusy = data.isBusy;
    fmcuState.timeout = data.timeout || 0;
    fmcuState.barcode = data.barcode || '';

    return fmcuState;
  }
}
