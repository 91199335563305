export class Category {
  id: number;
  name: string;
  checkCardType: string;

  static isCategory(other: any): boolean {
    return other != null && other.constructor.name === Category.name;
  }

  static fromJson(data: any): Category {
    if (data == null || data.id === -1) {
      return null;
    }

    return new Category(
      data.id,
      data.name,
      data.checkCardType,
    );
  }

  constructor(
    id: number,
    name: string,
    checkCardType: string,
  ) {
    this.id = id;
    this.name = name;
    this.checkCardType = checkCardType;
  }

  public toString(): string {
    let m = `Product. id: '${this.id}'. `;
    m += `name: '${this.name}'. `;
    m += `checkCardType: '${this.checkCardType}'. `;
    return m;
  }

  get type(): string {
    return "Category";
  }
}
