import { Injectable, Injector, EventEmitter } from '@angular/core';
import { LoggingService } from './../logging/logging.service';
import { Subject, Observable } from 'rxjs';
import { VuCommunicationService } from './../vu/vu-communication.service';
import { AcceptedCash, Money } from '../../lib/lib';
import { MoneyExchangeRules } from '../../lib/money-exchange/money-exchange-rules';


@Injectable()
export class MoneyExchangeService {

  private vuCommunicationService: VuCommunicationService;

  public eventMoneyPayin: EventEmitter<Money> = new EventEmitter<Money>(true);
  public eventMoneyPayout: EventEmitter<Money> = new EventEmitter<Money>(true);
  public eventReadyToMoneyExchange: EventEmitter<any> = new EventEmitter<any>(true);
  public eventMoneyExchangePayoutFinish: EventEmitter<any> = new EventEmitter<any>(true);

  constructor(
    private injector: Injector,
  ) {
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.vuCommunicationService.vuConnection.eventMoneyExchangePayin.subscribe(money => this.eventMoneyPayin.emit(money));
    this.vuCommunicationService.vuConnection.eventMoneyExchangePayout.subscribe(money => this.eventMoneyPayout.emit(money));
    this.vuCommunicationService.vuConnection.eventMoneyExchangeReadyToMoneyExchange.subscribe(_ => this.eventReadyToMoneyExchange.emit());
    this.vuCommunicationService.vuConnection.eventMoneyExchangePayoutFinish.subscribe(_ => this.eventMoneyExchangePayoutFinish.emit());
  }

  public getMoneyExchangeRules(): Observable<MoneyExchangeRules> {
    return this.vuCommunicationService.vuHttp.getMoneyExchangeRules();
  }

  public isTransactionExist(): Observable<boolean> {
    return this.vuCommunicationService.vuHttp.isMoneyExchangeTransactionExist();
  }

  public beginTransaction(acceptedCash: AcceptedCash): Observable<boolean> {
    return this.vuCommunicationService.vuHttp.beginMoneyExchangeTransaction(acceptedCash);
  }

  public rollbackTransaction(): Observable<boolean> {
    return this.vuCommunicationService.vuHttp.rollbackMoneyExchangeTransaction();
  }

  public commitTransaction(): Observable<boolean> {
    return this.vuCommunicationService.vuHttp.commitMoneyExchangeTransaction();
  }

  public payoutMoney(payoutAcceptedCash: AcceptedCash): Observable<boolean> {
    return this.vuCommunicationService.vuHttp.moneyExchangePayoutMoney(payoutAcceptedCash);
  }
}
