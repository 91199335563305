<div class="modal-body">
  <div>
    <app-card-dispenser-read-card (rfidCardRead)="onRfidCardRead($event)"></app-card-dispenser-read-card>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning pull-right button-base button-medium" (click)="onCancel()">
    {{ 'Cancel' | translate }}
  </button>
</div>
