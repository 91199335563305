import { Injectable } from "@angular/core";
import { DavCard } from "../../lib/kletterzentrum/dav-card";
import { DavCardType } from "../../lib/kletterzentrum/dav-card-type";

@Injectable()
export class DavCardService {
    private _davCardInfo: DavCard;

    davCardType: DavCardType;

    constructor (
    ) {
    }

    set davCardInfo(davCard: DavCard) {
        this._davCardInfo = davCard;
    }

    get davCardInfo(): DavCard {
        return this._davCardInfo;
    }

    reset() {
      this._davCardInfo = null;
      this.davCardType = DavCardType.None;
    }

    get isSectionIllertissenMember() : boolean {
      return this.davCardType == DavCardType.MemberDavSectionIllerissen;
    }
}
