import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { CardDispenserBaseGridWorkflowService } from './card-dispenser-base-grid-workflow.service';

@Injectable()
export class CardDispenserCaptureGridWorkflowService extends CardDispenserBaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CardDispenserCapture;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);
    if (this.cardDispenserService.isAvailable) {
      this.cardDispenserService.captureCard();
    } else {
      this.showCardDispenserNotAvailable();
    }
  }

}
