import { Action } from '@ngrx/store';
import { Footer } from '../lib/footer';
import { PayloadAction } from '../lib/payload-action';

const initialState = new Footer();

export const PAGE_FOOTER_VISIBLE = 'PageFooterVisible';

export function pageFooterReducer(state = initialState, action: PayloadAction) {
    let isChanged = true;
    switch (action.type) {
        case PAGE_FOOTER_VISIBLE:
            state.visible = action.payload;
            break;
        default:
            isChanged = false;
            break;
    }
    return isChanged ? Footer.fromOther(state) : state;
}
