import { ScenarioType } from "./scenario-type";
import { ScenarioTypeSpeedType } from "./scenario-type-speed-type";
import { Color } from "../color";

export class RunningLightScenario {

  constructor(
    public color: String = "#ffffff",
    public scenario: ScenarioType = ScenarioType.Off,
    public speed: ScenarioTypeSpeedType = ScenarioTypeSpeedType.Fast,
  ) {
  }

  toJSON(): any {
    return {
      color: Color.colorToHex(this.color),
      scenario: this.scenario,
      speed: this.speed,
    };
  }

  static fromOther(other: any): RunningLightScenario {
    return Object.assign(new RunningLightScenario(), other);
  }

  get scenarioText(): string {
    switch (this.scenario) {
      case ScenarioType.Off:
        return "Off";
      case ScenarioType.On:
        return "On";
      case ScenarioType.LeftToRight:
        return "Left to right";
      case ScenarioType.RightToLeft:
        return "Right to left";
      case ScenarioType.InsideToOutside:
        return "Inside to outside";
      case ScenarioType.OutsideToInside:
        return "Outside to inside";
    }
    return '';
  }

  get speedText(): string {
    switch (this.speed) {
      case ScenarioTypeSpeedType.Slow:
        return "Slow";
      case ScenarioTypeSpeedType.Moderate:
        return "Moderate";
      case ScenarioTypeSpeedType.Fast:
        return "Fast";
      case ScenarioTypeSpeedType.Fastest:
        return "Fastest";
    }
    return '';
  }

  public toString(): string {
    return `scenario: '${this.scenario}' color:'${this.color}' speed:'${this.speed}'`;
  }
}
