import { RfidCardData } from '../card-dispenser/rfid-card-data';
import { DictionaryHelper } from '../dictionary-helper';
import { Ticket } from '../ticket/ticket';

export class OrderSaveResult {
  orderId: number;
  orderUid: string;
  isSuccess: boolean;
  error: string;
  properties: any;
  private internalDictionaryHelper: DictionaryHelper;

  static fromOther(other: any): OrderSaveResult {
    return new OrderSaveResult(other.orderId,
      other.orderUid,
      other.isSuccess,
      other.error,
      other.properties);
  }

  constructor(
    orderId: number,
    orderUid: string,
    isSuccess: boolean,
    error: string,
    properties: any = {}
  ) {
    this.orderId = orderId;
    this.orderUid = orderUid;
    this.isSuccess = isSuccess;
    this.error = error;
    this.properties = properties;
    this.internalDictionaryHelper = new DictionaryHelper(this.properties);
  }

  get barcodesOfCreatedTickets(): string[] {
    return this.internalDictionaryHelper.getStringArray('barcodes_of_created_tickets');
  }

  get tickets(): Ticket[] {
    const ticketsAny = this.internalDictionaryHelper.getProperty('tickets');
    if (ticketsAny && ticketsAny.length > 0) {
      return ticketsAny.map(item => Ticket.fromJson(item));
    }
    return [];
  }

  get rfidCardData(): RfidCardData {
    return RfidCardData.createFromAny(this.internalDictionaryHelper.getProperty('rfid_card_data'));
  }

  get warningMessages(): string[] {
    return this.internalDictionaryHelper.getStringArray('warning_messages');
  }

  get forcePrintReceipt(): boolean {
    return this.internalDictionaryHelper.getBoolean('force_print_receipt');
  }

  toString(): string {
    let result = `orderId: ${this.orderId}.`;
    result += `orderUid: ${this.orderUid}.`;
    result += `isSuccess: ${this.isSuccess}.`;
    result += `error: ${this.error}`;
    result += `properties: ${this.internalDictionaryHelper}`;
    return result;
  }
}
