import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';

const TIMER_INTERVAL_START = 800;
const TIMER_INTERVAL_WORKING_MAX = 200;
const TIMER_INTERVAL_WORKING_MIN = 100;

@Component({
  moduleId: module.id,
  selector: 'count-button',
  templateUrl: 'count-button.component.html',
  styleUrls: ['count-button.component.scss'],
})
export class CountButtonComponent {
  @Input() disabled: boolean;
  @Input() cssClass: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  private count: number = 0;
  private timeoutHandler: any;
  private timerInterval: number;

  constructor(private dispatcherService: DispatcherService) {
    this.resetTimerInterval();
  }

  onButtonClick() {
    this.onClick.emit();
    this.dispatcherService.onUserActivity();
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  @HostListener('touchend')
  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = null;
    }
  }

  @HostListener('mousedown')
  @HostListener('touchstart')
  mousedown() {
    this.resetTimerInterval();
    this.startTimer();
  }

  private resetTimerInterval() {
    this.timerInterval = TIMER_INTERVAL_START;
    this.count = 0;
  }

  private setTimerInterval() {
    let step = this.count ** 2;
    let decrement = step < TIMER_INTERVAL_WORKING_MIN ? step : TIMER_INTERVAL_WORKING_MIN;
    this.timerInterval = TIMER_INTERVAL_WORKING_MAX - decrement;
  }

  private startTimer() {
    let scope = this;
    this.timeoutHandler = setTimeout(() => {
      if (scope.timeoutHandler) {
        scope.count++;
        scope.setTimerInterval();
        scope.onButtonClick();
        scope.startTimer();
      }
    }, scope.timerInterval);
  }
}
