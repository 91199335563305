import { Injectable } from '@angular/core';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { OutputInputService } from '../../../../output-input/output-input.service';

@Injectable()
export class KeypadGridWorkflowService extends BaseGridWorkflowService {
  private outputInputService: OutputInputService;

  init(
  ) {
    super.init();
    this.outputInputService = this.injector.get(OutputInputService);
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Keypad;
  }

  keypadOutputEvent(keyEvent: { char: string, action: any }): void {
    this.outputInputService.sendKeyEvent(keyEvent);
  }
}
