import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatapickerBaseControl } from '../datepicker-base/datepicker-base-control';
import { LanguageService } from '../../services/language.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class DatapickerComponent extends DatapickerBaseControl {
  constructor(
    protected languageService: LanguageService,
    protected messageService: MessageService,
  ) {
    super(languageService, messageService);
    this.dpConfig.showWeekNumbers = false;
  };
}
