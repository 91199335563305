import { MachineBaseService } from './machine-base.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WorkflowStepType, WorkflowStepState, MachineInactivitySettings } from '../../lib/lib';
import { NotificationForEntranceComponent } from '../../components/general/notification-for-entrance/notification-for-entrance.component';
import { Subscription } from 'rxjs';
import { OpenGateTypeEnum } from '../../lib/gate/open-gate-type.enum';
import { ModalService } from '../gui/modal/modal-service';
import { Injectable } from '@angular/core';
import { SaleService } from '../sale.service';
import { VuAutomaticActionType } from 'src/app/lib/ticket/ticket-vu-automatic-action-type';

@Injectable()
export class MachineGateService extends MachineBaseService {
  private notificationForEntrance: BsModalRef;
  private subscriptionGateTransactionBegin: Subscription;
  private subscriptionGateTransactionEnd: Subscription;
  private subscriptionGateReadBarcode: Subscription;
  private modalService: ModalService;
  private saleService: SaleService;

  barcode = '1';
  baseUrl: string;
  openGateType: string = OpenGateTypeEnum.Immediately;
  timeoutMs: number;
  protected failedOpenEnterDialogTimeout: number = 3 * 1000;
  private timer: any;

  entered: boolean;
  openSuccess: boolean;

  init(): void {
    super.init();
    this.modalService = this.injector.get(ModalService);
    this.saleService = this.injector.get(SaleService);
  }

  get machineName(): string {
    return 'Gate Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toGateReadBarcode', from: ['off'], to: 'gateReadBarcode' },
      { name: 'toFailedOpenEnter', from: ['gateReadBarcode', 'gateWaitingForEnter'], to: 'failedOpenEnter' },
      { name: 'toGateWaitingForEnter', from: ['gateReadBarcode'], to: 'gateWaitingForEnter' },
    );
  }

  protected getMethods(): any {
    return super.getMethods({
      onToGateReadBarcode: () => {
        this.log.info('gateReadBarcode');
        if (this.waitForEntrance) {
          this.showNotificationForEntrance(NotificationForEntranceComponent.waiting, 'Entrance validation is in progress');
        }
        this.unsubscribeEvents();
        this.dispatcherService.isBackButtonEnabled = false;
        this.dispatcherService.workflowAddStep(WorkflowStepType.GateReadBarcode);
        this._subscribeAndTryOpenGate();
      },
      onToGateWaitingForEnter: () => {
        this.log.info('gateWaitingForEnter');
        this.showNotificationForEntrance(NotificationForEntranceComponent.accessAllowed, 'Please go to the entrance', this.timeoutSeconds);
        this.dispatcherService.workflowAddStep(WorkflowStepType.GateWaitingForEnter);
      },
      onToFailedOpenEnter: () => {
        this.log.info('failedOpenEnter');
        this.showNotificationForEntrance(NotificationForEntranceComponent.accessDenied, 'Entrance not allowed');
        this._autoCloseDialog(this.failedOpenEnterDialogTimeout);
      },
      onToOff: () => {
        this.log.info('off');
        this.unsubscribeEvents();
        this.dispatcherService.isBackButtonEnabled = true;
        this._killTimeout();
        this.closeNotificationForEntrance();
        this.doAsync(() => {
          this.dispatcherService.gateOpenComplete({entered: this.entered, openSuccess: this.openSuccess});
        }, 'onToOff');
      },
    });
  }

  get timeoutSeconds(): number {
    return this.timeoutMs ? this.timeoutMs / 1000 : 0;
  }

  protected _subscribeAndTryOpenGate(): void {
    this.subscriptionGateTransactionBegin = this.vuConnection.eventGateTransactionBegin.subscribe(x => this.onEventGateTransactionBegin(x));
    this.subscriptionGateTransactionEnd = this.vuConnection.eventGateTransactionEnd.subscribe(x => this.onEventGateTransactionEnd(x));
    this.subscriptionGateReadBarcode = this.vuHttp.gateReadBarcode(this.barcode, this.baseUrl, this.openGateType, this.timeoutSeconds)
      .subscribe(() => { }, e => {
        this.log.error(`_subscribeAndTryOpenGate. Bad reply: '${e.message || e}'`);
        this.onEventGateTransactionBegin(false);
        if (this.waitForEntrance) {
          this._autoCloseDialog(this.failedOpenEnterDialogTimeout);
        }
      });
  }

  protected _autoCloseDialog(timeout: number): void {
    this.timer = setTimeout(() => {
      if (this.state !== 'failedOpenEnter') {
        return;
      }
      this.closeNotificationForEntrance();
      this.dispatcherService.onUserActivity();
      this.machine.toOff();
    }, timeout);
  }

  protected _killTimeout(): void {
    if (!this.timer) {
      return;
    }

    setTimeout(this.timer);
    this.timer = null;
  }

  machineStart(): void {
    if (this.isOff) {
      this.machine.toGateReadBarcode();
    }
  }

  machineStop(): void {
    if (!this.isOff) {
      this.machine.toOff();
    }
  }

  protected onEventGateTransactionBegin(x: boolean): void {
    if (this.state !== 'gateReadBarcode') {
      return;
    }

    this.entered = false;
    this.openSuccess = x;

    this.log.debug(`onEventGateTransactionBegin: '${x}'`);
    if (x) {
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteSuccess);
      if (this.waitForEntrance) {
        this.machine.toGateWaitingForEnter();
      } else {
        this.machine.toOff();
      }
    } else {
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
      this.machine.toFailedOpenEnter();
    }
  }

  protected onEventGateTransactionEnd(x: boolean): void {
    if (this.state !== 'gateWaitingForEnter' && this.state !== 'failedOpenEnter') {
      return;
    }

    this.entered = x;

    this.log.debug(`onEventGateTransactionEnd: '${x}'`);
    if (x) {
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteSuccess);
      this.machine.toOff();
    } else {
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
      this.machine.toFailedOpenEnter();
    }
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    this.closeNotificationForEntrance();
    super.onMachineTimeoutModalCancel(machineName);
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'flowEnd':
        return new MachineInactivitySettings(10000, true);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }

  private setNotificationForEntrance(displayType: string, description: string, timeout: number = 0): void {
    this.notificationForEntrance.content.setDisplayType(displayType);
    this.notificationForEntrance.content.setNotificationText(description);
    this.notificationForEntrance.content.setGateTimeout(timeout);

    if (timeout !== 0) {
      this.notificationForEntrance.content.startTimer(timeout);
    }
  }

  private showNotificationForEntrance(displayType: string, description: string, timeout: number = 0): void {
    if (this.additionalPropertiesConfigurationService.isLiteMode) {
      return;
    }
    if (this.notificationForEntrance) {
      this.setNotificationForEntrance(displayType, description, timeout);
      return;
    }
    this.dispatcherService.languagesButtonModalActivate(false);
    this.notificationForEntrance = this.modalService.show(
      NotificationForEntranceComponent,
      {
        isGateOnTheLeftSide: this.additionalPropertiesConfigurationService.isGateOnTheLeftSide
      },
      () => {
        this.notificationForEntrance = null;
      }
    );

    this.setNotificationForEntrance(displayType, description, timeout);
  }

  private closeNotificationForEntrance(): void {
    this.dispatcherService.languagesButtonModalActivate(true);
    this.modalService.close(this.notificationForEntrance);
  }

  protected unsubscribeEvents(): void {
    if (this.subscriptionGateTransactionBegin) {
      this.subscriptionGateTransactionBegin.unsubscribe();
      this.subscriptionGateTransactionBegin = null;
    }
    if (this.subscriptionGateTransactionEnd) {
      this.subscriptionGateTransactionEnd.unsubscribe();
      this.subscriptionGateTransactionEnd = null;
    }
    if (this.subscriptionGateReadBarcode) {
      this.subscriptionGateReadBarcode.unsubscribe();
      this.subscriptionGateReadBarcode = null;
    }
  }

  get waitForEntrance(): boolean {
    return this.additionalPropertiesConfigurationService.isReadBarcodeAtGateAfterPayment
      || this.saleService?.order?.orderLines?.some(
        line => line?.product?.vuAutomaticActionType === VuAutomaticActionType.FMCUWaitForEntrance);
  }
}

