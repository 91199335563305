import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class OutputInputService implements OnDestroy {

  private subject = new Subject<any>();

  constructor() { }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }

  sendKeyEvent(keyEvent: {char: string, action: any}): void {
    this.subject.next(keyEvent);
  }

  getKeyEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}
