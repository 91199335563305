import { Injectable, Injector, EventEmitter } from '@angular/core';
import { VuCommunicationService } from '../vu/vu-communication.service';
import { IVuHttp } from '../vu/http/vu-http.interface';
import { IVuConnection } from '../vu/connection/vu-connection.interfaces';
import { Subscription } from 'rxjs';
import { AdditionalPropertiesConfigurationService } from '../configuration/additional-properties-configuration.service';

@Injectable()
export class TurnstileService {
  private vuCommunicationService: VuCommunicationService;
  private vuHttp: IVuHttp;
  private vuConnection: IVuConnection;
  private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService;
  private turnstileEnterSubscription: Subscription;
  private _isEnterAllowed = false;

  eventTurnstileEnter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected injector: Injector,
  ) {
    this.vuCommunicationService = injector.get(VuCommunicationService);
    this.vuHttp = this.vuCommunicationService.vuHttp;
    this.vuConnection = this.vuCommunicationService.vuConnection;
    this.turnstileEnterSubscription = this.vuConnection.eventOnTurnstileEnter.subscribe(() => this.onTurnstileEnter());
    this.additionalPropertiesConfigurationService = this.injector.get(AdditionalPropertiesConfigurationService);
    // this.turnstileEnterSubscription.unsubscribe();
  }

  openEnter() {
    if (this._isEnterAllowed)
      return;

    this._isEnterAllowed = true;
    this.vuHttp.openEnter().subscribe();
  }

  closeEnter() {
    if (!this._isEnterAllowed)
      return;

    this._isEnterAllowed = false;
    this.vuHttp.closeEnter().subscribe();
  }

  get isEnterAllowed(): Boolean {
    return this._isEnterAllowed;
  }

  onTurnstileEnter() {
    this._isEnterAllowed = false;
    this.eventTurnstileEnter.emit();
  }

  get fastOpen(): boolean {
    return this.additionalPropertiesConfigurationService.isTurnstileFastOpenEnabled;
  }
}
