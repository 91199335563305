import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseModalComponent } from 'src/app/components/general/base/base-modal-component';
import { BarcodeInputMethod } from 'src/app/lib/barcode-reader/barcode-input-method';
import { NumberScanModalResult } from 'src/app/lib/scan/number-scan-modal-result';
import { BarcodeReaderService } from 'src/app/services/barcode/barcode-reader.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';

@Component({
  selector: 'app-barcode-scan-modal',
  templateUrl: './barcode-scan-modal.component.html',
  styleUrls: ['./barcode-scan-modal.component.css']
})
export class BarcodeScanModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  private barcodeScannedSubscription: Subscription;
  private barcodeInputMethod = BarcodeInputMethod.VirtualKeyboard;

  constructor(
    private barcodeReaderService: BarcodeReaderService,
    private configurationService: ConfigurationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.barcodeInputMethod = this.configurationService.barcodeInputMethod;
    this.barcodeScannedSubscription = this.barcodeReaderService.barcodeScanned.subscribe(
      barcode => {
        this.close(new NumberScanModalResult(barcode));
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeBarcodeScannedSubscription();
  }

  unsubscribeBarcodeScannedSubscription(): void {
    if (this.barcodeScannedSubscription) {
      this.barcodeScannedSubscription.unsubscribe();
      this.barcodeScannedSubscription = null;
    }
  }

  get barcodeInputMethodDescription(): string {
    switch (this.barcodeInputMethod) {
      case BarcodeInputMethod.BarcodeReader:
        return 'Attach your ticket!';
      case BarcodeInputMethod.VirtualKeyboard:
      case BarcodeInputMethod.VirtualKeyboardCharsOnly:
      case BarcodeInputMethod.VirtualKeyboardNumbersOnly:
        return 'Type in and enter the ticket code!';
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardCharsOnly:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardNumbersOnly:
        return 'Attach your ticket or type in and enter the ticket code!';
    }

    return 'Attach your ticket!';
  }
}
