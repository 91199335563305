import { Injectable, Injector } from '@angular/core';
import { VuCommunicationService } from '../vu/vu-communication.service';
import { Ticket } from '../../lib/lib';
import { Observable } from 'rxjs';
import { IVuHttp } from '../vu/http/vu-http.interface';
import { map } from 'rxjs/operators';

@Injectable()
export class TicketService {
  private vuHttp: IVuHttp;

  constructor(
    injector: Injector,
  ) {
    const vuCommunicationService = injector.get(VuCommunicationService);
    this.vuHttp = vuCommunicationService.vuHttp;
  }

  getTicketInfo(barcode: string): Observable<Ticket> {
    return this.vuHttp.getTicketInfo(barcode)
      .pipe(
        map(result => {
          return (result instanceof Ticket) ? result : Ticket.fromJson(result);
        }),
      );
  }

  activateOneDayTicket(ticket_code: string): Observable<Ticket> {
    return this.vuHttp.activateOneDayTicket(ticket_code);
  }
}
