import { Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { MessageModalComponent } from 'src/app/components/modal/message-modal/message-modal.component';
import { ModalService } from 'src/app/services/gui/modal/modal-service';

@Injectable()
export class MessageModalService {
  private modalRef: BsModalRef;
  private defaultAutoCloseTimeout = 3000;

  constructor(
    private modalService: ModalService,
  ) {
  }

  public showError(
    message: string,
    closeCallback: () => void = null,
  ): void {
    this.showErrors([message], closeCallback);
  }

  public showErrors(
    messages: string[],
    closeCallback: () => void = null,
  ): void {

    if (!messages || messages.length === 0) {
      if (closeCallback) {
        closeCallback();
      }
      return;
    }

    this.showMessages(messages, 'error-message-modal', this.defaultAutoCloseTimeout, closeCallback);
  }

  public show(
    message: string,
    modalClass: string,
    autoCloseTimeout: number,
    closeCallback: () => void,
  ): void {
    this.showMessages([message], modalClass, autoCloseTimeout, closeCallback);
  }

  public showMessages(
    messages: string[],
    modalClass: string,
    autoCloseTimeout: number,
    closeCallback: () => void,
  ): void {
    if (!this.modalService) {
      return;
    }

    this.modalRef = this.modalService.show(
      MessageModalComponent,
      {
        messages,
        modalClass,
        autoCloseTimeout,
      },
      closeCallback
    );
  }

  public close(): void {
    if (!this.modalService || !this.modalRef) {
      return;
    }

    this.modalService.close(this.modalRef);
    this.modalRef = null;
  }

  public showErrorObservable(message: string): Observable<void> {
    return new Observable(subscriber => {
      this.showError(message,
        () => {
          subscriber.next();
          subscriber.complete();
        });
    });
  }
}
