import { StepConfigurationType } from "./step-configuration-type";

export class StepConfiguration {
  type: StepConfigurationType;
  text: string;
  disabled: boolean;
  active: boolean;
  success: boolean;
  visible: boolean;
  error: boolean;

  constructor(
    type: StepConfigurationType,
    text?: string,
  ) {
    this.type = type;
    this.text = text;
    this.visible = true;
    this.active = false;
  }

  get imageName(): string {
    switch (this.type) {
      case StepConfigurationType.Coins:
        return this.prefix + 'coins.svg';
      case StepConfigurationType.Change:
        return this.prefix + 'change.svg';
      case StepConfigurationType.Card:
        return this.prefix + 'card.svg';
      case StepConfigurationType.SaveOrder:
        return this.prefix + 'upload.svg';
      case StepConfigurationType.Print:
        return this.prefix + 'print.svg';
      case StepConfigurationType.EntryLeft:
        return this.prefix + 'entry_left.svg';
      case StepConfigurationType.EntryRight:
        return this.prefix + 'entry_right.svg';
      case StepConfigurationType.RFID:
        return this.prefix + 'rfid.svg';
      case StepConfigurationType.TakePrintedStuff:
        return this.prefix + 'print_qrcode.svg';
      case StepConfigurationType.Amount:
      default:
        return '';
    }
  }

  get prefix(): string {
    return '/static/images/svg/';
  }

}
