import { Action } from '@ngrx/store';
import { MessageView } from '../lib/lib';
import { PayloadAction } from '../lib/payload-action';

const initialState = new MessageView();

export class MessageViewActionType {
  static readonly MESSAGE_VIEW_UPDATE = 'MessageViewUpdate';
  static readonly MESSAGE_VIEW_TEXT = 'MaintenanceViolation';
}

export function messageViewReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;
  switch (action.type) {
    case MessageViewActionType.MESSAGE_VIEW_UPDATE:
      state = action.payload;
      break;
    case MessageViewActionType.MESSAGE_VIEW_TEXT:
      state.text = action.payload;
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? MessageView.fromOther(state) : state;
}
