import { Component, Input } from '@angular/core';

import { Money, CashType } from '../../../lib/lib';

@Component({
  moduleId: module.id,
  selector: 'denomination',
  templateUrl: 'denomination.component.html',
  styleUrls: ['denomination.component.scss']
})
export class DenominationComponent {
  @Input() money: Money;
  @Input() cashType: CashType;

  get cssClass() {
    let cashType = this.cashType === CashType.Coin ? 'coin' : 'banknote';
    return `denomination ${cashType}`;
  }
}
