import { CIOBoardPinEventEnum } from './cioboard-pin-event-enum';

export class CIOBoardPinEvent {

  constructor(
    public name: CIOBoardPinEventEnum,
    public state: boolean,
  ) {
  }

  public static fromOther(other: any) {
    return new CIOBoardPinEvent(
      other.name,
      other.state,
    );
  }

  public static fromArray(other: any): CIOBoardPinEvent[] {
    if (!other || !Array.isArray(other)) {
      return [];
    }
    return other.map(item => this.fromOther(item));
  }
}
