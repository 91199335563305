import { TranslatePropertyHelper } from '../languages/translation-properties-helper';

export class ProductVariant {
  name: string;
  productId: number;
  translations: any;
  private translatePropertyHelper: TranslatePropertyHelper;

  constructor(
    name: string,
    productId: number,
    translations: any,
  ) {
    this.name = name;
    this.productId = productId;
    this.translations = translations;
    this.translatePropertyHelper = new TranslatePropertyHelper(translations ? translations : null);
  }

  static createFromAny(data: any): ProductVariant {
    if (data == null || data.id === -1) {
      return null;
    }
    const obj = data;
    return new ProductVariant(
      obj.name,
      obj.product_id,
      obj.translations,
    );
  }

  static createFromAnyArray(array: any): ProductVariant[] {
    if (!array) {
      return [];
    }
    return array.map(this.createFromAny).filter(item => item != null);
  }

  translateName(language: string, defaultValue: string = ''): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'attribute_value', defaultValue);
  }
}
