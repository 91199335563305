export enum StepConfigurationType {
  Coins = 'Coins',
  Change = 'Change',
  Amount = 'Amount',
  Card = 'Card',
  RFID = 'RFID',
  SaveOrder = 'SaveOrder',
  Print = 'Print',
  EntryLeft = 'EntryLeft',
  EntryRight = 'EntryRight',
  TakePrintedStuff = 'TakePrintedStuff',
}
