import { Component, OnInit } from '@angular/core';
import { IVuConnectionSimulator } from '../../../services/vu/connection/vu-connection.interfaces';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { PinState } from '../../../lib/cioboard/pin-state';
import { CIOBoardEvent } from '../../../lib/cioboard/coiboard-event';

@Component({
  selector: 'app-cioboard-event-simulator',
  templateUrl: './cioboard-event-simulator.component.html',
  styleUrls: ['./cioboard-event-simulator.component.sass']
})
export class CioboardEventSimulatorComponent implements OnInit {
  In0 = false;
  In1 = false;
  In2 = false;
  In3 = false;
  In4 = false;
  In5 = false;
  private oldState: PinState;
  private newState: PinState;
  private vuSimulator: IVuConnectionSimulator;

  constructor(
    private vuCommunicationService: VuCommunicationService,
  ) {
    this.vuSimulator = this.vuCommunicationService.vuConnectionSimulator;
    this.oldState = this.createCurrentPinState();
    this.newState = this.createCurrentPinState();
  }

  ngOnInit() {
  }

  public onSendEvent(): void {
    this.newState = this.createCurrentPinState();
    this.vuSimulator.onCIOBoardStateChanged(new CIOBoardEvent(this.oldState, this.newState));
    this.oldState = this.createCurrentPinState();
  }

  createCurrentPinState(): PinState {
    return new PinState(this.In0, this.In1, this.In2, this.In3, this.In4, this.In5);
  }
}
