import { Router } from '@angular/router';
import { Injectable, Injector, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  MachineInactivity,
  MachineInactivitySettings,
} from '../../lib/lib';
import { IMachine } from './machine.interface';
import { VuCommunicationService } from '../vu/vu-communication.service';

import * as StateMachine from 'javascript-state-machine/dist/state-machine.js';
import { IVuHttp } from '../vu/http/vu-http.interface';
import { IVuConnection } from '../vu/connection/vu-connection.interfaces';
import { LoggingService } from '../logging/logging.service';
import { MessageService, Message, MessageType } from '../message.service';
import { DispatcherService } from '../dispatcher.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { AdditionalPropertiesConfigurationService } from '../configuration/additional-properties-configuration.service';
import { IExternalApiService } from '../vu/external-api/external-api.interface';
import { filter } from 'rxjs/operators';

@Injectable()
export abstract class MachineBaseService implements IMachine {
  protected machine: any;
  protected messageSubscription: Subscription;
  protected isInitialized = false;
  protected vuHttp: IVuHttp;
  protected vuConnection: IVuConnection;
  protected externalApiService: IExternalApiService;
  protected log: LoggingService;
  protected messageService: MessageService;
  protected dispatcherService: DispatcherService;
  private vuCommunicationService: VuCommunicationService;
  protected configurationService: ConfigurationService;
  protected additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService;

  eventSwitchedOff = new EventEmitter<any>(true);
  eventPending = new EventEmitter<any>(true);
  eventStateChanged = new EventEmitter<any>(false);

  constructor(
    protected injector: Injector,
    protected router: Router,
  ) {
    this.log = this.injector.get(LoggingService);
    this.messageService = this.injector.get(MessageService);
    this.dispatcherService = this.injector.get(DispatcherService);
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.configurationService = this.injector.get(ConfigurationService);
    this.vuHttp = this.vuCommunicationService.vuHttp;
    this.vuConnection = this.vuCommunicationService.vuConnection;
    this.externalApiService = this.vuCommunicationService.ExternalApiService;
    this.additionalPropertiesConfigurationService = this.injector.get(AdditionalPropertiesConfigurationService);
    this.init();
  }

  init(): void {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    this.machine = new StateMachine({
      init: this.getInitState(),
      transitions: this.getTransitions(),
      methods: this.getMethods(),
    });

    this.messageSubscription = this.messageService.getSubscription()
      .pipe(filter((x: Message) => this.getMessages().indexOf(x.messageType) > -1))
      .subscribe(message => this.onMessage(message));
  }

  protected getInitState(): string {
    return 'off';
  }

  protected getTransitions(...transitions: any[]): any[] {
    const baseTransitions = [
      { name: 'toOff', from: '*', to: 'off' },
      { name: 'goto', from: '*', to: (s: string) => s },
    ];

    return baseTransitions.concat(transitions);
  }

  protected getMethods(...methods: any[]): any {
    const scope = this;
    const baseMethods = {
      onAfterTransition: (event: any) => {
        scope.logMachine(event, `${event.transition}. (${event.from} -> ${event.to}).`);
        scope.onAfterTransition(event);
      },
      onInvalidTransition: (transition: any, from: any, to: any) => {
        scope.logState('warning', name, transition, from, to);
      },
    };

    return methods == null || methods.length < 1 ? methods : this.mergeDicts(baseMethods, methods[0]);
  }

  get state(): string {
    return this.machine.state;
  }

  abstract get machineName(): string;

  private logState(fname: string, message: string, transition: any, from: any, to: any): void {
    const m = `${message}:  ${transition}. (${from}->).`;
    this.log[fname](`${this.machineName}. ${m}`);
  }

  abstract machineStart(): void;

  machineStop(): void {
    this.machine.toOff();
  }

  /** Return true if the iherited classes must not handle the message */
  protected onMessage(message: Message): boolean {
    if (this.isOff) {
      return true;
    }
    switch (message.messageType) {
      case MessageType.MachineHardReset:
        this.hardReset();
        return true;
      case MessageType.MachineTimeoutModalCancel:
        this.onMachineTimeoutModalCancel(message.info);
        return true;
      default:
        break;
    }

    return false;
  }

  protected getMessages(...messages: MessageType[]): MessageType[] {
    return [MessageType.MachineHardReset, MessageType.MachineTimeoutModalCancel].concat(messages);
  }

  protected logMachine(event: any, message: string): void {
    this.log.info(`${this.machineName}. ${message}`);
  }

  protected onAfterTransition(event: any): void {
    const from = event.from;
    const to = event.to;

    if (from !== to) {
      this.machineInactivityChangeTracking(false, from);
      this.machineInactivityChangeTracking(true, to);
      this.eventStateChanged.emit();
    }

    if (to === 'off') {
      this.eventSwitchedOff.emit();
    }
  }

  protected doAsync(f: any, contextMessage: string = null): void {
    setTimeout(() => {
      this.do(f, `async. ${contextMessage}`);
    }, 1);
  }

  protected do(f: any, contextMessage: string = null): void {
    try {
      f();
    } catch (e) {
      this.log.error(`${this.machineName}. do. ${contextMessage === null ? f : contextMessage}. ${e.message}`);
    }
  }

  protected hardReset(): void {
    this.do(() => this.machine.toOff(true), 'hardReset');
  }

  protected mergeDicts(a: any, b: any): any {
    return Object.assign({}, a, b);
  }

  protected isInState(...states: string[]): boolean {
    if (states == null) {
      return false;
    }

    return states.indexOf(this.state) > -1;
  }

  protected getsAllStatesExceptFor(...states: string[]): string[] {
    states = states ? states : [];
    if (!this.machine) {
      return [];
    }
    const result = this.machine.allStates().filter((x: string) => states.indexOf(x) < 0);
    return result;
  }

  protected get isOff(): boolean {
    return this.machine.state === 'off';
  }

  protected get isIdle(): boolean {
    return this.machine.state === 'idle';
  }

  protected machineInactivityChangeTracking(isStartTracking: boolean, state: string, customSettings?: MachineInactivitySettings): void {
    if (this.timeoutTrackingStates.indexOf(state) < 0) {
      return;
    }

    const settings = customSettings ? customSettings : this.getMachineInactivitySettings(state);
    const machineInactivity = new MachineInactivity(isStartTracking,
      this.machineName,
      state,
      settings.customTimeout,
      settings.skipToOpenTimeoutModal);
    this.dispatcherService.machineInactivityChangeTracking(machineInactivity);
  }

  reloadInactivityTracking(customSettings?: MachineInactivitySettings): void {
    this.dispatcherService.machineInactivityChangeTracking(new MachineInactivity(false, this.machineName, this.state));
    this.machineInactivityChangeTracking(true, this.state, customSettings);
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    return new MachineInactivitySettings();
  }

  protected get timeoutTrackingStates(): string[] {
    return [];
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    if (machineName === this.machineName) {
      this.dispatcherService.machineHardResetRoot();
    }
  }

  protected get isExitRole(): boolean {
    return this.configurationService.isRoleExit;
  }

  get gridMode(): boolean {
    return this.configurationService && this.configurationService.configuration
      && !!this.configurationService.configuration.displayConfigurationId;
  }
}
