export class Header {
  visible = true;
  text: string[];

  static fromOther(other: Header): Header {
    return Object.assign(new Header(), other);
  }

  constructor() {
    this.resetText();
  }

  resetText() {
    this.text = [''];
  }
}
