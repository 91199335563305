export class PrinterHelper {

  public static get supporedPrinterTypes(): string[] {
    return ['KPM150', 'KPM180', 'TL60', 'VKPII', 'VKPIII'];
  }

  public static isPrinterPrisenter(printerType: string): boolean {
    return printerType === 'KPM150'
      || printerType === 'KPM180'
      || printerType === 'VKPIII'
      || printerType === 'VKPII'
      ;
  }
}
