import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-spinner-balls',
  templateUrl: './spinner-balls.component.html',
  styleUrls: ['./spinner-balls.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerBallsComponent {
  constructor(
  ) {
  }

}
