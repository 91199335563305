<label [attr.for]="'timeout'">{{ ('Timeout' | translate) + ':' }}</label>
<select id="timeout" [(ngModel)]="configuration.timeout" (change)="onTimeoutChange($event)">
  <option *ngFor="let r of timeouts" [ngValue]="r">{{ r }}</option>
</select>
<label [attr.for]="'mode'">{{ ('Mode' | translate) + ':' }}</label>
<select id="mode" [(ngModel)]="configuration.mode" (change)="onModeChange($event)">
  <option value="none">{{ 'None' | translate }}</option>
  <option value="text">{{ 'Text' | translate }}</option>
  <option value="images">{{ 'Images' | translate }}</option>
</select>
<label [attr.for]="'imageShowTimeout'">{{ ('Image show timeout' | translate) + ':' }}</label>
<select id="imageShowTimeout" [(ngModel)]="configuration.imageShowTimeout" (change)="onImageShowTimeoutChange($event)">
  <option *ngFor="let r of timeouts" [ngValue]="r">{{ r }}</option>
</select>
<label [attr.for]="'timeFrom'">{{ ('From time' | translate) + ':' }}</label>
<app-float-time id="timeFrom" [(ngModel)]="configuration.timeFrom" (change)="onTimeFromChange($event)"></app-float-time>
<label [attr.for]="'timeTo'">{{ ('To time' | translate) + ':' }}</label>
<app-float-time id="timeTo" [(ngModel)]="configuration.timeTo" (change)="onTimeToChange($event)"></app-float-time>
