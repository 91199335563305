<div class="modal-header">
  <div *ngIf="currentProductWithVariants" class="modal-title text-center normal-text">{{ productName }}</div>
</div>
<div class="modal-body">
  <div class="visual-items">
    <div class="scroll scrollbar">
      <div *ngIf="currentProductWithVariants" class="container entire-width">
        <div *ngIf="productsWithVariants && productsWithVariants.length && productsWithVariants.length != 1"
          class="text-center">
          {{currentProductWithVariantsIndex + 1}}/{{productsWithVariants.length}}
        </div>
        <div class="row text-center">
          <app-select-product-variant [variants]="currentProductWithVariants.variants"
            (selectedProductIdChange)="selectProduct($event)"></app-select-product-variant>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success pull-left button-base button-medium" (click)="back()">
    {{ 'Back' | translate }}
  </button>
  <button *ngIf="isNextButtonAvailable" type="button" class="btn btn-success pull-right button-base button-medium"
    (click)="selectProduct(-1)">
    {{ 'Next' | translate }}
  </button>
</div>
