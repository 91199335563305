export class DateTimeHelper {
  static getHour(time: number): number {
    let hour = Math.floor(time);
    const min = Math.round((time % 1) * 60);
    if (min === 60) {
      hour = hour + 1;
    }
    return hour;
  }

  static getMinutes(time: number): number {
    let min = Math.round((time % 1) * 60);
    if (min === 60) {
      min = 0;
    }
    return min;
  }

  static get now(): Date {
    return new Date(Date.now());
  }

  static get today(): Date {
    return this.getToday(this.now);
  }

  static getToday(now: Date): Date {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  static createDate(today: Date, time: number): Date {
    const hour = this.getHour(time);
    const min = this.getMinutes(time);
    return new Date(today.getTime() + hour * 60 * 60 * 1000 + min * 60 * 1000);
  }

  static createFloatTime(hour: number, minute: number): number {
    return hour + Math.round(minute / 60 * 100) / 100;
  }
}
