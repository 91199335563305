import { Component, OnInit, Injector } from '@angular/core';
import { TouchTileSimulatorService } from '../../../services/touch-tile/touch-tile-simulator.service';
import { TouchTile } from '../../../lib/touch-tile/touch-tile';
import { ScenarioType } from '../../../lib/touch-tile/scenario-type';

@Component({
  selector: 'app-touch-tile-simulator',
  templateUrl: './touch-tile-simulator.component.html',
  styleUrls: ['./touch-tile-simulator.component.css']
})
export class TouchTileSimulatorComponent implements OnInit {
  selectedTouchTileId: string;
  private touchTileSimulatorService: TouchTileSimulatorService;

  constructor(
    protected injector: Injector,
  ) {
    this.touchTileSimulatorService = injector.get(TouchTileSimulatorService);
  }

  ngOnInit() {
  }

  get touchTiles(): TouchTile[] {
    return this.touchTileSimulatorService.touchTiles;
  }

  get runningLightScenario(): string {

    const runningLightScenario = this.touchTileSimulatorService.runningLightScenario;
    if (!runningLightScenario)
      return 'Off';

    return `${runningLightScenario.scenarioText.toLowerCase()}, ${runningLightScenario.speedText.toLowerCase()}, ${runningLightScenario.color}`;
  }

  get scenarioGradient(): string {
    const runningLightScenario = this.touchTileSimulatorService.runningLightScenario;
    if (!runningLightScenario)
      return 'unset';

    switch (runningLightScenario.scenario) {
      case ScenarioType.On:
        return 'linear-gradient(' + runningLightScenario.color + ',' + runningLightScenario.color + ')';
      case ScenarioType.LeftToRight:
        return 'linear-gradient(to right, ' + runningLightScenario.color + ', #ffffff)';
      case ScenarioType.RightToLeft:
        return 'linear-gradient(to right, #ffffff,' + runningLightScenario.color + ')';
      case ScenarioType.InsideToOutside:
        return 'linear-gradient(to right, #ffffff,' + runningLightScenario.color + ', #ffffff)';
      case ScenarioType.OutsideToInside:
        return 'linear-gradient(to right,' + runningLightScenario.color + ',#ffffff, ' + runningLightScenario.color + ')';
    }

    return 'unset';
  }

  testChangeColorsClick() {
    this.touchTileSimulatorService.testChangeColor();
  }

  testChangeSwitchesClick() {
    this.touchTileSimulatorService.testChangeSwitches();
  }
}
