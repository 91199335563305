import { Injectable } from '@angular/core';
import { CashlessPaymentProviderEventType } from 'src/app/lib/payment/cashless-payment-provider-event-type';
import { BluecodeEvent } from 'src/app/lib/bluecode/bluecode-event';
import { BluecodeEventType } from 'src/app/lib/bluecode/bluecode-event-type';
import { CashlessBaseProviderService } from './cashless-base-provider.service';
import { Subscription } from 'rxjs';


@Injectable()
export class CashlessBluecodeProviderService extends CashlessBaseProviderService {

  private _bluecodeEvent: Subscription;

  get name(): string {
    return CashlessBluecodeProviderService.name;
  }

  init(): void {
    this.cashlessPaymentMessageStore.reset();
    this._subscribeEvents();
  }

  transactionBegin(): void {
  }

  protected _subscribeEvents(): void {
    this._bluecodeEvent = this.vuCommunicationService.vuConnection.eventBluecodeEvent.subscribe(
      (x: BluecodeEvent) => this.onEventBluecode(x)
    );

    super._subscribeEvents();
  }

  protected _unsubscribeEvents(): void {
    if (this._bluecodeEvent) {
      this._bluecodeEvent.unsubscribe();
      this._bluecodeEvent = null;
    }
    super._unsubscribeEvents();
  }

  private onEventBluecode(x: BluecodeEvent): void {
    this.log.info(`onEventBluecodeEvent. ${x}`);
    switch (x.eventType) {
      case BluecodeEventType.PaymentRegistered:
        this.raiseCashlessEvent(CashlessPaymentProviderEventType.TextChanged, 'Please scan QR code with your Bluecode-App');
        this.cashlessPaymentMessageStore.terminalText = x.checkinCode;
        break;
      case BluecodeEventType.PaymentRequestConfirmation:
        this.raiseCashlessEvent(CashlessPaymentProviderEventType.TextChanged, 'Please confirm payment with your Bluecode-App');
        this.cashlessPaymentMessageStore.terminalText = x.checkinCode;
        break;
      case BluecodeEventType.PaymentCompleted:
        this.saleService.remoteTransaction.closeRemoteTransaction(true, 'Bluecode. PaymentCompleted').then(() => {
          this.raiseCashlessEvent(CashlessPaymentProviderEventType.PaymentCompleted, '');
          this.cashlessPaymentMessageStore.terminalText = null;
        }); // to cashless machine ?
        break;
      case BluecodeEventType.PaymentAborted:
        this.raiseCashlessEvent(CashlessPaymentProviderEventType.PaymentAborted, '');
        this.cashlessPaymentMessageStore.terminalText = null;
        break;
      default:
        this.log.error(`This BluecodeEvent not supported: ${x}`);
        break;
    }
  }
}
