import { Guid, Product, Money, PaymentSession } from '../lib';
import { TicketParameters } from '../ticket/ticket-parameters';
import { OrderLine } from './order-line';
import { PaymentMethod } from '../payment/payment-method';
import { OrderType } from './order-type';
import { TicketType } from '../ticket/ticket-type';

export class Order {
  id = 0;
  uid = Guid.newGuid();
  private internalOrderLines: OrderLine[] = [];
  isReceipt = false;
  isPrefilledOrder = false;
  private internalPaymentMethod = PaymentMethod.Cash;
  private internalPaymentSession = new PaymentSession(this);
  barcode = '';
  giftCardEan13 = '';
  giftCardName = '';
  giftCardPartialPaymentAmount = 0;
  type = OrderType.Sale;
  originalOrderId = 0;
  isCardTerminalReceipt = false;
  properties: Map<string, object> = new Map<string, object>();

  get paymentSession(): PaymentSession {
    return this.internalPaymentSession;
  }

  get paymentMethod(): PaymentMethod {
    return this.internalPaymentMethod;
  }

  set paymentMethod(x: PaymentMethod) {
    this.internalPaymentMethod = x;
  }

  deleteOrderLine(line: OrderLine, removeDependentOrderLines: boolean = true): void {
    if (removeDependentOrderLines) {
      this._removeDependentOrderLines(line);
    }

    const index = this.internalOrderLines.indexOf(line);
    if (index > -1) {
      this.internalOrderLines.splice(index, 1);
    }
  }

  getAllOrderLineBarcodes(orderLines: OrderLine[] = this.orderLines): string[] {
    let barcodes = '';
    if (orderLines.length === 0) {
      return [];
    }

    orderLines.forEach(element => {
      barcodes += barcodes === '' ? element.barcode : ';' + element.barcode;
    });

    return barcodes.split(';');
  }

  private _removeDependentOrderLines(line: OrderLine): void {
    if (!line) {
      return;
    }
    const linesToDelete = [];

    const additionalProductOrderLines = this.getOrderLinesWithProductIds(line.additionalProductIds);
    let orderLines = this.internalOrderLines;
    if (!additionalProductOrderLines || !orderLines) {
      return;
    }

    orderLines = orderLines.filter(item => item.uid !== line.uid);
    if (orderLines.length === 0) {
      return;
    }

    for (const additionalProductOrderLine of additionalProductOrderLines) {
      if (!additionalProductOrderLine) {
        continue;
      }

      let hasLinkFromAnotherOrderLine = false;

      for (const orderLine of orderLines) {
        if (orderLine && orderLine.containAdditionalProductWithId(additionalProductOrderLine.productId)) {
          hasLinkFromAnotherOrderLine = true;
          break;
        }
      }

      if (!hasLinkFromAnotherOrderLine) {
        linesToDelete.push(additionalProductOrderLine);
      }
    }

    for (const lineToDelete of linesToDelete) {
      this.deleteOrderLine(lineToDelete, false);
    }
  }

  getOrderLinesWithProductIds(productIds: number[]): OrderLine[] {
    if (!productIds) {
      return null;
    }
    return this.orderLines.filter(orderLine => productIds.includes(orderLine.productId));
  }

  get orderLines(): OrderLine[] {
    return this.internalOrderLines;
  }

  set orderLines(orderLines: OrderLine[]) {
    this.internalOrderLines = orderLines;
  }

  addProduct(
    product: Product,
    quantity: number,
    customePrice: Money = null,
    barcode: string = null,
    partner: TicketParameters = null,
    separateLines: boolean = false,
    davCardNumber: string = null,
  ): OrderLine {
    let lines = this.orderLines;
    let orderLines = lines.filter(x => x.productId === product.id);
    let orderLine = orderLines.length > 0 ? orderLines[0] : null;
    if (orderLine == null || partner != null || separateLines || product.additionalProperties["is_dav_card_required"]) {
      orderLine = new OrderLine(
        product,
        quantity,
        customePrice,
        barcode,
        partner,
        davCardNumber
      );
      lines.push(orderLine);
    } else {
      orderLine.quantity += quantity > 0 ? quantity : 0;
    }

    return orderLine;
  }

  get amountGiftPartial(): Money {
    const amountTotal = this.amountTotal;
    return new Money(this.giftCardPartialPaymentAmount, amountTotal.currencyCode);
  }

  get amountTotal(): Money {
    const amount = this.internalOrderLines ?
      this.internalOrderLines
        .map(x => x.amount)
        .reduce((a, b) => a.add(b), Money.empty) : Money.empty;

    return amount.distract(new Money(this.giftCardPartialPaymentAmount, amount.currencyCode));
  }

  get amountReceived(): Money {
    return this.paymentSession.amountPaidIn;
  }

  get amountReturned(): Money {
    return this.paymentSession.amountPaidOut;
  }

  get hasRfidCard(): boolean {
    for (const orderLine of this.orderLines) {
      if (orderLine.rfidCard) {
        return true;
      }
    }

    return false;
  }

  get hasTicketsToPrint(): boolean {
    const lines = this.orderLines;
    const orderLines = lines.filter(
      x =>
        x.product
        && x.product.ticketType === TicketType.Paper
        && !x.product.disableCreateTicket
        && x.quantity > 0
    );
    return orderLines.length > 0;
  }

  updateOrderLineWithFloatingAmount(product: Product, amount: Money): void {
    const lines = this.orderLines;
    const orderLines = lines.filter(x => x.productId === product.id);
    const orderLine = orderLines.length > 0 ? orderLines[0] : null;
    if (!orderLine || !amount) {
      return;
    }
    orderLine.price.value += amount.value;
  }

  registerMoney(money: Money): void {
    this.paymentSession.registerMoney(money);
  }

  openSession(minAmount: Money = null, maxAmount: Money = null): void {
    this.paymentSession.openSession(minAmount, maxAmount);
  }

  closeSession(): void {
    this.paymentSession.closeSession();
  }

  convertToRefundType(): void {
    this.originalOrderId = this.id;
    this.id = 0;
    this.type = OrderType.SaleRefund;
    this.orderLines.forEach(x => x.convertToRefundType());
    this.giftCardPartialPaymentAmount = -this.giftCardPartialPaymentAmount;
  }

  addRfidCardBarcode(barCode: string): void {
    for (const orderLine of this.orderLines) {
      if (orderLine.rfidCard) {
        if (!orderLine.barcode) {
          orderLine.barcode = barCode;
        }
        orderLine.updateProperties('sale_card', true);
      }
      if (!orderLine.barcode && (orderLine.product.isCard || orderLine.product.scanCardRequested)) {
        orderLine.barcode = barCode;
      }
    }
  }

  // addGitCardEan13(gitCardEan13: string) {
  //   this.giftCardEan13 = gitCardEan13;
  // }

  toString(): string {
    let lines = '';
    for (const line of this.orderLines) {
      lines += `\n\t${line}`;
    }
    const amountTotal = `amountTotal: ${this.amountTotal}.`;
    const amountReceived = `amountReceived: ${this.amountReceived}.`;
    const amountReturned = `amountReturned: ${this.amountReturned}.`;

    return `Order. id: '${this.id}'. uid: '${this.uid}'. ${amountTotal} ${amountReceived} ${amountReturned} ${lines}`;
  }

  updateProperties(key: string, value: any): void {
    if (!key || !this.properties) {
      return;
    }

    this.properties.set(key, value);
  }

  get isTotalAmountZero(): boolean {
    return this.amountTotal.isZero;
  }

  get hasZeroQuantity(): boolean {
    return this.orderLines.some(x => x.quantity === 0);
  }

  get hasOrderLinesZeroQuantity(): boolean {
    return this.orderLines.every(x => x.quantity === 0);
  }

}
