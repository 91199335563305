import { TicketInfoComponent } from '../ticket-info/ticket-info.component';
import { Component } from '@angular/core';
import { StatusInfo } from '../../../lib/status-info';
import { TicketStatus } from '../../../lib/lib';

@Component({
  selector: 'ticket-info-two-sided',
  templateUrl: '../ticket-info/ticket-info.component.html',
  styleUrls: ['../ticket-info/ticket-info.component.css'],
})
export class TicketInfoTwoSidedComponent extends TicketInfoComponent {

  get statusInfo(): StatusInfo {
    const status = this.ticket == null ? TicketStatus.Unknown : this.ticket.status;

    return StatusInfo.getStyleByTicketAccessStatus(status);
  }
}
