<div class="modal-header">
  <div class="modal-title normal-text pull-left">{{ headerCaption | translate }}</div>
</div>
<div class="modal-body">
  <div *ngIf="showGroupSize" class="group-info" align="center">
    <span class="number-input-label">{{ groupSizeCaption | translate }}</span>
    <app-number-spinner [(ngModel)]="ticketParameters.groupSize" [min]="groupSizeMin" [max]="groupSizeMax">
    </app-number-spinner>
  </div>
  <div align="center">
    <app-datapicker-range *ngIf="appointmentEnabled" [cssClass]="'datapicker-range-input'"
      [placeholder]="'Choose time' | translate" [minDate]="appointmentDateFrom" [maxDate]="appointmentDateTo"
      (valueChange)="onDateRangeSelect($event)"></app-datapicker-range>
    <app-select *ngIf="appointmentKeywords && appointmentKeywords.length > 0" [multiple]="true"
      [items]="appointmentKeywords" [placeholder]="'Choose keywords' | translate" (onSelect)="onSelectKeywords($event)">
    </app-select>
  </div>
  <div *ngIf="isPartnerRequired">
    <div align="center">
      <input type="text" #firstNameInput placeholder="{{ 'First Name' | translate }}" class="name-input"
        (focus)="onFocus(FieldType.FirstName)" (click)="updateCaretPos(firstNameInput)"
        [(ngModel)]="ticketParameters.firstName" appAutofocus />
      <input type="text" #lastNameInput placeholder="{{ 'Last Name' | translate }}" class="name-input"
        (focus)="onFocus(FieldType.LastName)" (click)="updateCaretPos(lastNameInput)"
        [(ngModel)]="ticketParameters.lastName" />
      <div *ngIf="showBirthdayField" align="center">
        <app-datepicker placeholder="{{ 'Birthday' | translate }}" [cssClass]="'birthday-input'" [maxDate]="today"
          (valueChange)="onDatepickerSelect($event)" readonly></app-datepicker>
      </div>
    </div>
    <on-screen-keyboard #screenKeyboard (keyPress)="keyPress($event)"></on-screen-keyboard>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning pull-left button-base button-medium" (click)="close(false)">
    {{ 'Cancel' | translate }}
  </button>
  <button type="button" class="btn btn-success pull-right button-base button-medium" (click)="close(true)"
    [disabled]="isOkDisabled">
    {{ 'OK' | translate }}
  </button>
</div>
