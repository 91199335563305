import { Component, OnInit } from '@angular/core';
import { Guid } from '../../../lib/lib';
import { BarcodeReaderSimulatorService } from '../../../services/barcode/barckode-reader-simulator.service';

@Component({
  selector: 'app-barcode-reader-simulator',
  templateUrl: './barcode-reader-simulator.component.html',
  styleUrls: ['./barcode-reader-simulator.component.css']
})
export class BarcodeReaderSimulatorComponent implements OnInit {

  barcode: string;

  constructor(
    private barcodeReaderSimulatorService: BarcodeReaderSimulatorService,
  ) {
  }

  ngOnInit() {
  }

  onScanRandomBarcodeidClick() {
    this.barcodeReaderSimulatorService.barcodeRead(Guid.newGuid());
  }

  get barcodes(): Array<string> {
    return [
      "2550026667410002016201614011975",
      "1030026667420002017201714011976",
      "TAD6GS7A",
      "F8ETS91B",
      "K8VSAT3D",
    ];
  }

  onScanBarcodeClick() {
    if(!this.barcode) {
      return;
    }

    this.barcodeReaderSimulatorService.barcodeRead(this.barcode);
  }
}
