<div class='ticket normal-text'>
  <ticket-status  *ngIf="showTicketStatus" [ticket]="ticket" [ticketInfoWithTimePeriodMode]="ticketInfoWithTimePeriodMode"></ticket-status>
  <ticket-access-status *ngIf="!showTicketStatus" [ticket]="ticket"></ticket-access-status>
  <div class="row buttons">
    <div class="col-lg-12">
      <div class="pull-left">
        <simple-button *ngIf="isTicketHistoryButtonVisible" [text]="'Ticket History'" [enabled]="true" [cssClass]="'btn btn-primary button-base ticket-history-button'"
          (onClick)="onTicketUseClick()"></simple-button>
      </div>
      <div class="pull-right">
        <simple-button *ngIf="ticket.outstandingOvertime > 0 && isRoleExit && !ticket.todayUsed" [text]="'Pay Outstanding Overtime'"
          [enabled]="subProduct != null" [cssClass]="'btn btn-primary button-base pay-button'" (onClick)="onPayClick()"></simple-button>
      </div>
      <div class="pull-right">
        <simple-button *ngIf="ticket.CanBeRefunded" [text]="'Return Amount'" [enabled]="true" [cssClass]="'btn btn-primary button-base return-balance-button'"
          (onClick)="onReturnClick()"></simple-button>
      </div>
    </div>
  </div>
</div>
