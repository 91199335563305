import { Injectable } from '@angular/core';
import { InMemoryDataService } from '../in-memory-data.service';
import { Ticket } from '../../lib/ticket/ticket';
import { Guid, TicketStatus } from '../../lib/lib';

@Injectable()
export class TicketSimulatorService {

  private internalTicketDic = [];

  constructor(
    private inMemoryDataService: InMemoryDataService,
  ) {
  }

  generateTicketValidEnter() {
    const ticket = this._generateTicket();
    ticket.quantity = 1;
    ticket.canEnter = true;
    ticket.canExit = false;
    ticket.additionalProperties.ticket_status = TicketStatus.Valid;
    return ticket;
  }

  generateTicketValidExit() {
    const ticket = this._generateTicket();
    ticket.quantity = 1;
    ticket.canEnter = false;
    ticket.canExit = true;
    ticket.additionalProperties.ticket_status = TicketStatus.WrongSide;
    return ticket;
  }

  generateTicketValidTodayUsed() {
    const ticket = this._generateTicket();
    ticket.quantity = 12;
    ticket.quantityUsed = 1;
    ticket.canEnter = false;
    ticket.canExit = false;
    ticket.todayUsed = true;
    ticket.additionalProperties.ticket_status = TicketStatus.TodayUsed;
    return ticket;
  }

  generateTicketInvalidUsed() {
    const ticket = this._generateTicket();
    ticket.quantity = 1;
    ticket.quantityUsed = 1;
    ticket.quantityUsedTotal = 1;
    ticket.canEnter = false;
    ticket.canExit = false;
    ticket.todayUsed = true;
    ticket.additionalProperties.ticket_status = TicketStatus.Used;
    return ticket;
  }

  generateTicketInvalidClosed() {
    const ticket = this._generateTicket();
    ticket.quantityUsed = ticket.quantity;
    ticket.additionalProperties.ticket_status = TicketStatus.Used;
    return ticket;
  }

  generateTicketInvalidOvertime() {
    const ticket = this._generateTicket();
    ticket.quantityUsed = ticket.quantity - 1;
    ticket.totalOvertime = 31 + 5;
    ticket.outstandingOvertime = 31;
    ticket.additionalProperties.ticket_status = TicketStatus.InvalidOvertime;
    return ticket;
  }

  generateTicketUnknown() {
    const ticket = this._generateTicket();
    ticket.isUnknown = true;
    ticket.additionalProperties.ticket_status = TicketStatus.Unknown;
    return ticket;
  }

  generateTicketStaff() {
    const ticket = this._generateTicket();
    ticket.canEnter = true;
    ticket.canExit = true;
    ticket.additionalProperties.ticket_status = TicketStatus.Staff;
    return ticket;
  }

  generateTicketValidTime() {
    const ticket = this._generateTicket();
    const date = new Date();
    date.setDate(date.getDate() + 1);
    ticket.validUntil = date.toLocaleString();
    ticket.additionalProperties.ticket_status = TicketStatus.Valid;
    return ticket;
  }

  generateTicketInvalidTime() {
    const ticket = this._generateTicket();
    const date = new Date();
    date.setDate(date.getDate() - 1);
    ticket.validUntil = date.toLocaleString();
    ticket.additionalProperties.ticket_status = TicketStatus.Expired;
    return ticket;
  }

  generateTicketValidOutOfSchedule() {
    const ticket = this._generateTicket();
    ticket.quantity = 1;
    ticket.canEnter = false;
    ticket.canExit = false;
    ticket.additionalProperties.ticket_status = TicketStatus.OutOfSchedule;
    return ticket;
  }

  generateTicketInvalidRandomProductTicket() {
    return this._generateTicketInMemory(Guid.newGuid());
  }

  generateVirtualTicketValid() {
    const ticket = this._generateTicket();
    ticket.additionalProperties.virtual_ticket = true;
    ticket.quantity = 11;
    ticket.quantityUsed = 1;
    ticket.additionalProperties.ticket_status = TicketStatus.Valid;
    return ticket;
  }

  generateVirtualTicketInvalid() {
    const ticket = this._generateTicket();
    ticket.additionalProperties.virtual_ticket = true;
    ticket.quantity = 11;
    ticket.quantityUsed = 11;
    ticket.additionalProperties.ticket_status = TicketStatus.InvalidVirtualTicket;
    return ticket;
  }

  getTicketInfo(barcode: string): Ticket {

    let ticket = this.internalTicketDic[barcode];
    if (ticket) {
      return ticket;
    }

    ticket = new Ticket();
    ticket.code = barcode;
    ticket.isUnknown = true;

    return ticket;
  }

  activateOneDayTicket(ticketCode: string): Ticket {
    const ticket = new Ticket();
    if (ticketCode === 'F8ETS91B') {
      ticket.errorMessage = 'Ticket not found';
    } else {
      ticket.code = 'XXX';
    }
    return ticket;
  }

  private _generateTicket(): Ticket {
    const barcode = this._generateBarcode();
    return this._generateTicketInMemory(barcode);
  }

  private _generateTicketInMemory(barcode: string): Ticket {
    const ticket = this.inMemoryDataService.generateTicket(barcode);
    this.internalTicketDic[ticket.code] = ticket;
    return ticket;
  }

  private _generateBarcode(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (let i = 0; i < 8; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  generateTicketFromToValid() {
    const ticket = this._generateTicket();
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(10);
    date.setMinutes(0);
    date.setSeconds(0);
    ticket.additionalProperties.ticket_status = TicketStatus.Valid;
    ticket.additionalProperties.valid_from = date.toLocaleString();
    date.setHours(12);
    ticket.additionalProperties.valid_to = date.toLocaleString();
    ticket.quantity = 1;
    ticket.quantityUsed = 0;
    ticket.quantityUsedTotal = 0;
    return ticket;
  }

  generateTicketFromToInvalid() {
    const ticket = this._generateTicket();
    const date = new Date();
    date.setDate(date.getDate() - 1);
    date.setHours(10);
    date.setMinutes(0);
    date.setSeconds(0);
    ticket.additionalProperties.ticket_status = TicketStatus.Valid;
    ticket.additionalProperties.valid_from = date.toLocaleString();
    date.setHours(12);
    ticket.additionalProperties.valid_to = date.toLocaleString();
    ticket.quantity = 1;
    ticket.quantityUsed = 0;
    ticket.quantityUsedTotal = 0;
    return ticket;
  }

  generateTicketFromToDisabled() {
    const ticket = this._generateTicket();
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(10);
    date.setMinutes(0);
    date.setSeconds(0);
    ticket.additionalProperties.ticket_status = TicketStatus.Valid;
    ticket.additionalProperties.valid_from = date.toLocaleString();
    date.setHours(12);
    ticket.additionalProperties.valid_to = date.toLocaleString();
    ticket.quantity = 1;
    ticket.quantityUsed = 0;
    ticket.quantityUsedTotal = 0;
    ticket.additionalProperties.is_disabled = true;
    return ticket;
  }

}
