import { Injectable } from '@angular/core';
import { CovidService } from './covid.service';

@Injectable()
export class AccessGuardService {

  constructor(
    private covidService: CovidService,
  ) {
  }

  get isEnabled(): boolean {
    return this.covidService.isCovidCheckRequiredSettings;
  }

  get isCheckRequired(): boolean {
    return this.covidService.isCovidCheckRequired;
  }

  reset(): void {
    this.covidService.reset();
  }
}
