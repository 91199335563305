<div class="modal-header">
  <div class="modal-title normal-text center">{{ title | translate }} <span *ngIf="groupSize">{{ personNumber }} /
      {{groupSize }} </span></div>
</div>
<div class="modal-body">
  <div class="box normal-text animation" [ngClass]="state">
    <span *ngIf="isShowMessage">{{ message | translate }}</span>
    <span *ngIf="!isShowMessage">{{ scanResultMessage | translate}}</span>
  </div>
</div>
<div class="modal-footer">
  <table class="pull-left" *ngIf="cards.length != 0 && groupSize">
    <thead>
      <tr>
        <th *ngFor="let card of cards; let i = index">
          <i class="fa fa-2x fa-thumbs-up green" aria-hidden="true"></i> {{ i + 1 }} - {{ 'Person' | translate }}
        </th>
      </tr>
    </thead>
  </table>
  <button type="button" class="btn btn-success pull-right button-base button-medium" (click)="close(false)">
    {{ 'Close' | translate }}
  </button>
</div>
