export class AgeRestriction {
  personCount: number;
  minAge: number;
  maxAge: number;

  isAgeValid(age: number): boolean {
    return (isNaN(this.minAge) ? true : this.minAge <= age)
      && (isNaN(this.maxAge) ? true : this.maxAge >= age);
  }

  static fromString(data: string): AgeRestriction {
    if (!data)
      return null;
    const rule = data.split("-");
    if (!rule)
      return null;

    const ageRestriction = new AgeRestriction();

    switch (rule.length) {
      case 2:
        // format "2-16;70- ; -6"
        ageRestriction.minAge = Number.parseInt(rule[0]);
        ageRestriction.maxAge = Number.parseInt(rule[1]);
        break;
      case 3:
        // format "1-2-16;-70- ; --6"
        ageRestriction.personCount = Number.parseInt(rule[0]);
        ageRestriction.minAge = Number.parseInt(rule[1]);
        ageRestriction.maxAge = Number.parseInt(rule[2]);
        break;
      default:
        return null;
    }
    return ageRestriction;
  }
}

