import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BaseModalComponent } from '../base/base-modal-component';

@Component({
  selector: 'app-notification-for-entrance',
  templateUrl: './notification-for-entrance.component.html',
  styleUrls: ['./notification-for-entrance.component.css']
})
export class NotificationForEntranceComponent extends BaseModalComponent implements OnInit, OnDestroy {

  static waiting = 'waiting';
  static accessAllowed = 'accessAllowed';
  static accessDenied = 'accessDenied';

  @Input()
  isGateOnTheLeftSide: boolean;
  @Input()
  notificationText: string;
  @Input()
  timeout: number;

  timeLeft: number;
  private interval: any;
  private displayType: string;

  constructor() {
    super();
    this.setDisplayType(NotificationForEntranceComponent.accessAllowed);
    this.setNotificationText(null);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  startTimer() {
    this.stopTimer();
    this.timeLeft = this.timeout;
    this.interval = setInterval(() => {
      this.timeLeft -= 1;
      if (this.timeLeft <= 0) {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  setGateTimeout(value: number) {
    if (!value) {
      this.timeout = 0;
    } else
      this.timeout = value;
  }

  setNotificationText(value: string) {
    if (!value) {
      this.notificationText = 'Please go to the entrance';
    } else {
      this.notificationText = value;
    }
  }

  setDisplayType(value: string) {
    this.displayType = value;
  }

  get isWaiting(): boolean {
    return this.displayType === NotificationForEntranceComponent.waiting;
  }

  get isAccessAllowed(): boolean {
    return this.displayType === NotificationForEntranceComponent.accessAllowed;
  }

  get isAccessDenied(): boolean {
    return this.displayType === NotificationForEntranceComponent.accessDenied;
  }
}
