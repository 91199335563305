import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CreditCard } from '../../../lib/lib';

@Component({
  moduleId: module.id,
  selector: 'accepted-credit-cards',
  templateUrl: 'accepted-credit-cards.component.html',
  styleUrls: ['accepted-credit-cards.component.scss']
})
export class AcceptedCreditCardsComponent {
  _acceptedCreditCards: CreditCard[] = [];

  get acceptedCreditCards(): CreditCard[] {
    return this._acceptedCreditCards;
  }

  @Output()
  onCardClick = new EventEmitter<any>();

  @Input()
  set acceptedCreditCards(value: CreditCard[]) {
    this._acceptedCreditCards = value == null ? [] : value;
  }

  get cssClass(): string {
    return `accepted-cash-denomination`;
  }

  cardClick(x: any) {
    this.onCardClick.emit(x);
  }
}
