export class CreditCardTerminalState {
  isCardInserted = false;

  static fromJson(x: any): CreditCardTerminalState {
    return Object.assign(new CreditCardTerminalState(), x);
  }

  clone(): CreditCardTerminalState {
    return CreditCardTerminalState.fromJson(this);
  }

  toString(): string {
    return `isCardInserted: ${this.isCardInserted}`;
  }
}
