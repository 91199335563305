export class Issue {
  id: number;
  name: string;
  description: string;
  date: Date;
  category: string;
  json_data: string;
  log: string;

  static createFromAny(other: Issue): Issue {
    const issue = new Issue();
    issue.id = other.id;
    issue.name = other.name;
    issue.description = other.description;
    issue.date = other.date;
    issue.category = other.category;
    issue.json_data = other.json_data;
    issue.log = other.log;
    return issue;
  }

  toString() {
    let m = `id: ${this.id}. `;
    m += `name: ${this.name}. `;
    m += `description: ${this.description}. `;
    m += `date: ${this.date}. `;
    m += `category: ${this.category}. `;
    return `${m}`;
  }
}
