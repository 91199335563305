import { MachineInactivitySettings, } from '../../lib/lib';
import { MachineBaseService } from './machine-base.service';
import { Injectable } from '@angular/core';
import { CovidService } from 'src/app/modules/access-guard/services/covid.service';
import { ExternalUseService } from 'src/app/modules/external-base/services/external-use.service';
import { CovidCheckOperation } from 'src/app/modules/access-guard/models/covid-check-operation';
import { Message, MessageType } from '../message.service';


@Injectable()
export class MachineAccessGuardService extends MachineBaseService {

  private covidService: CovidService;
  private externalUseService: ExternalUseService;

  init(): void {
    super.init();
    this.covidService = this.injector.get(CovidService);
    this.covidService.validationCompleted.subscribe((result: boolean) => this.onCovidValidationCompleted(result));
    this.externalUseService = this.injector.get(ExternalUseService);
  }

  get machineName(): string {
    return 'Access Guard Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toIdle', from: '*', to: 'idle' },
      { name: 'toCovidCheck', from: ['idle'], to: 'covidCheck' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToIdle(): void {
        if (scope.externalUseService.isEnabled) {
          scope.externalUseService.pauseActivate();
        }
        if (scope.covidService.isCovidCheckRequired) {
          scope.doAsync(() => scope.machine.toCovidCheck(), 'onToIdle.');
          return;
        }

        scope.externalUseService.restoreActivate();
        scope.doAsync(() => scope.machine.toOff(), 'onToIdle.');
      },
      onToCovidCheck(): void {
        scope.router.navigateByUrl('/covid-check');
      },
    });
  }

  machineStart(withoutTransition = false): void {
    if (this.isInState('off')) {
      this.machine.toIdle();
    }
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ReloadInactivityTracking,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.ReloadInactivityTracking:
        if (state === 'covidCheck') {
          this.reloadInactivityTracking();
        }
        break;
    }

    return false;
  }

  protected get timeoutTrackingStates(): string[] {
    const result = [];
    if (this.covidService?.currentCovidCheckOperation !== CovidCheckOperation.WaitCertificateScan) {
      result.push('covidCheck');
    }
    return result;
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'covidCheck':
        if (this.covidService?.currentCovidCheckOperation === CovidCheckOperation.CertificateNotValid) {
          return new MachineInactivitySettings(10000, true);
        } else {
          return new MachineInactivitySettings(20000, false);
        }
      default:
        return super.getMachineInactivitySettings(state);
    }
  }

  onCovidValidationCompleted(result: any): void {
    this.doAsync(() => this.machine.toIdle(), 'onCovidValidationCompleted.');
  }
}
