import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { SaleService } from '../../../../sale.service';

@Injectable()
export class CardPaymentGridWorkflowService extends BaseGridWorkflowService {

  private saleService: SaleService;

  init(
  ) {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.dispatcherService.onDisplayModeCashlessPaymentComplete((message) => {
      if (!this.displayItem) {
        return;
      }

      this.userActivity();

      const info = message.info !== undefined ? message.info : false;
      if (info) {
        this.paymentComplete();
      } else {
        this.saleService.closePaymentSession();
        this.navigateBack();
      }
    });
  }

  paymentComplete() {
    this.navigateToUrlOrNavigationItem(this.displayItem);
    this.delayedHardReset();
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CardPayment;
  }

  show(displayItem: DisplayItem, context: any): void {
    this.displayGridWorkflowService.displayGridInactivityService.disableInactivityTimer();
    super.show(displayItem, context);
    this._checkContextAndFillOrder(context);
    this.dispatcherService.toDisplayModeCashlessPayment(false);
  }

  _checkContextAndFillOrder(context: any) {
    const amount = context.cardPaymentAmount;
    const product = context.cardPaymentProduct;
    if (amount && product && this.saleService &&
      this.saleService.order && this.saleService.order.paymentSession &&
      !this.saleService.order.paymentSession.isSessionOpen) {
      const rfidCardCode = this.getRfidCardCodeFormattedData(context);
      this.saleService.order.addProduct(product, 1, amount, rfidCardCode);

      if (context && context.responseRfidCardData) {
        this.saleService.order.updateProperties('responseRfidCardData', context.responseRfidCardData?.data || '');
      }
    }
  }

  get hardResetProccessDisplayItem(): DisplayItem {
    // TODO Turn off card terminal
    return null;
  }
}
