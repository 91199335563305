import {
  Ticket,
  MachineInactivitySettings,
  PrintTaskType,
  WorkflowStepType,
  TeaserType,
  WorkflowStepState,
  PrintTaskResult,
} from '../../lib/lib';
import { MachineBaseService } from './machine-base.service';
import { Message, MessageType } from '../message.service';
import { BarcodeReaderService } from '../barcode/barcode-reader.service';
import { TicketService } from '../ticket/ticket.service';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MachineTicketActivationService extends MachineBaseService {
  private ticketService: TicketService;
  private barcodeReaderService: BarcodeReaderService;

  private internalErrorMessage: string;
  private internalBarcodeReaderSubscription: Subscription;
  private internalActivateOneDayTicketSubscription: Subscription;
  private internalPrintTaskResultReceivedSubscription: Subscription;

  init(): void {
    super.init();
    this.barcodeReaderService = this.injector.get(BarcodeReaderService);
    this.ticketService = this.injector.get(TicketService);
  }

  get machineName(): string {
    return 'Ticket Activation Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toPending', from: ['off', 'ticketLoading', 'ticketPrinting', 'ticketInvalid'], to: 'pending' },
      { name: 'toTicketLoading', from: ['pending'], to: 'ticketLoading' },
      { name: 'toTicketInvalid', from: ['ticketLoading'], to: 'ticketInvalid' },
      { name: 'toTicketPrinting', from: ['ticketLoading'], to: 'ticketPrinting' },
      { name: 'toFlowEnd', from: ['*'], to: 'flowEnd' },
    );
  }

  protected getMethods(): any {
    return super.getMethods({
      onToPending: () => {
        if (this.dispatcherService.barcode)
          this.doAsync(() => {
            this.machine.toTicketLoading();
          }, 'onToPending');
        this.router.navigateByUrl('/ticket-activation-wait-for');
        this.eventPending.emit();
        this.dispatcherService.isBackButtonEnabled = true;
        this.doAsync(() => {
          this.internalBarcodeReaderSubscription = this.barcodeReaderService.barcodeScanned.subscribe(
            (x: string) => this.onBarcodeScanned(x));
        }, 'onToPending');
      },
      onToTicketLoading: () => {
        this.router.navigateByUrl('/workflow');
        let workflowName = 'Already purchased card, receive ticket for access';
        this.dispatcherService.workflowReset(workflowName, WorkflowStepType.Wait);
        this.dispatcherService.teaserReset();
        this.dispatcherService.isBackButtonEnabled = false;
        const barcode = this.dispatcherService.barcode;
        this.doAsync(() => {
          this.internalActivateOneDayTicketSubscription = this.ticketService.activateOneDayTicket(barcode).subscribe(
            result => {
              this.onActivateOneDayTicket(result);
            });
        }, 'onToTicketLoading');
      },
      onToTicketPrinting: () => {
        this.dispatcherService.workflowLastStepStateSet();
        this.dispatcherService.workflowAddStep(WorkflowStepType.TicketPrinting);
        const barcode = this.dispatcherService.barcode;
        this.doAsync(() => {
          this.internalPrintTaskResultReceivedSubscription = this.vuConnection.eventPrintTaskResultReceived.subscribe(
            (x: PrintTaskResult) => this.onPrintTaskResultReceived(x));
          this.vuHttp.printTicket(barcode)
            .catch((x) => {
              this.onPrintTicketsResultReceived(false);
            });
        }, 'onToTicketLoading');
      },
      onToTicketInvalid: () => {
        this.dispatcherService.workflowLastStepStateSet();
        this.dispatcherService.workflowAddStep(WorkflowStepType.InvalidTicket, ...[this.internalErrorMessage]);
        this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
        this.dispatcherService.isBackButtonEnabled = true;
        this.dispatcherService.backButtonTextSet('Back');
        this.doAsync(() => {
          this.machine.toFlowEnd();
        }, 'onToFlowEnd');
      },
      onToFlowEnd: () => {
        this.dispatcherService.barcode = null;
        this._unsubscribe();
        this.dispatcherService.isBackButtonEnabled = true;
      },
      onToOff: (event: any, isHardReset: false) => {
        this.dispatcherService.workflowReset('', WorkflowStepType.None);
      },
    });
  }

  machineStart(withoutTransition = false): void {
    this.machine.toPending();
  }

  private onPrintTaskResultReceived(result: PrintTaskResult): void {
    if (result.printTask.printTaskType === PrintTaskType.Ticket) {
      this.onPrintTicketsResultReceived(result.isSuccess);
    }
  }

  private onPrintTicketsResultReceived(result: boolean): void {
    this._unsubscribePrintTaskResultReceived();

    this.log.info(`MachineTicketActivationService. onPrintTicketsResultReceived. result: ${result}`);
    if (result) {
      this.dispatcherService.teaserAddItem(TeaserType.TakeTicket);
      this.dispatcherService.workflowLastStepStateSet();
    } else {
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
    }
    this.machine.toFlowEnd();
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
      MessageType.Back,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
      case MessageType.Back:
        this._unsubscribe();
        if (this.isInState('ticketInvalid')) {
          this.machine.toPending();
        } else {
          this.machine.toOff();
        }
        break;
      default:
        break;
    }
    return false;
  }

  onBarcodeScanned(barcode: string): void {
    if (!this.isInState('pending')) {
      return;
    }

    this._unsubscribeBarcodeReader();
    this.dispatcherService.barcode = barcode;
    this.machine.toTicketLoading();
  }

  onActivateOneDayTicket(result: Ticket): void {

    this._unsubscribeActivateOneDayTicket();

    if (result.errorMessage) {
      this.internalErrorMessage = result.errorMessage;
      this.machine.toTicketInvalid();
      return;
    }

    this.dispatcherService.barcode = result.code;
    this.machine.toTicketPrinting();
  }

  protected get timeoutTrackingStates(): string[] {
    return ['pending', 'ticketPrinting', 'ticketLoading', 'flowEnd'];
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'pending':
      case 'ticketPrinting':
      case 'ticketLoading':
        return new MachineInactivitySettings(60000, false);
      case 'flowEnd':
        return new MachineInactivitySettings(10000, true);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }

  private _unsubscribe(): void {
    this._unsubscribeBarcodeReader();
    this._unsubscribeActivateOneDayTicket();
    this._unsubscribePrintTaskResultReceived();
  }

  private _unsubscribePrintTaskResultReceived(): void {
    if (this.internalPrintTaskResultReceivedSubscription) {
      this.internalPrintTaskResultReceivedSubscription.unsubscribe();
      this.internalPrintTaskResultReceivedSubscription = null;
    }
  }

  private _unsubscribeBarcodeReader(): void {
    if (this.internalBarcodeReaderSubscription) {
      this.internalBarcodeReaderSubscription.unsubscribe();
      this.internalBarcodeReaderSubscription = null;
    }
  }

  private _unsubscribeActivateOneDayTicket(): void {
    if (this.internalActivateOneDayTicketSubscription) {
      this.internalActivateOneDayTicketSubscription.unsubscribe();
      this.internalActivateOneDayTicketSubscription = null;
    }
  }
}
