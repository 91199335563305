import { Component, forwardRef, Inject, Injector, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { NgModel, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TimepickerComponent } from 'ngx-bootstrap/timepicker';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ElementBase } from 'src/app/components/general/base/element-base';
import { LanguageService } from 'src/app/services/language.service';
import { Message, MessageService, MessageType } from 'src/app/services/message.service';


const TIMER_INTERVAL_START = 800;
const TIMER_INTERVAL_WORKING_MAX = 200;
const TIMER_INTERVAL_WORKING_MIN = 100;


@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true
    }
  ]
})
export class DateTimeComponent extends ElementBase<Date> implements OnInit, OnDestroy {

  isShowMeridian: boolean;

  private messageServiceSubscription: Subscription;
  private _timeValue: Date;

  @Input()
  minDate: Date;

  @Input()
  maxDate: Date;

  @Input()
  minuteStep: number = 1;

  @ViewChild(NgModel) model: NgModel;

  @ViewChild('timepicker', { static: false }) timepicker: TimepickerComponent;

  private count = 0;
  private timeoutHandler: any;
  private timerInterval: number;
  private upDownComponent: any;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private messageService: MessageService,
    private languageService: LanguageService,
    private bsLocaleService: BsLocaleService,
  ) {
    super(validators, asyncValidators);
    this._timeValue = new Date();
  }

  ngOnInit(): void {
    this.updateTimepickerLocale();

    this.messageServiceSubscription = this.messageService.getSubscription()
      .pipe(
        filter((x: Message) => [MessageType.LanguageChanged].indexOf(x.messageType) > -1),
      )
      .subscribe(message => this.onMessage(message));
  }

  ngOnDestroy(): void {
    this._unsubscribeMessageServiceSubscription();
  }

  get timeValue(): Date {
    return this._timeValue;
  }

  set timeValue(value: Date) {
    if (!value) {
      return;
    }
    if (this._timeValue) {
      this._timeValue.setHours(value.getHours());
      this._timeValue.setMinutes(value.getMinutes());
    }
    if (this.value) {
      this.value.setHours(value.getHours());
      this.value.setMinutes(value.getMinutes());
      this.onChange(this.value);
    }
  }

  get maxTimeValue(): Date {
    if (this.isToday(this.value)) {
      return this.maxDate;
    }
    return null;
  }

  isToday(value: Date): boolean {
    return (new Date().toDateString() === value.toDateString());
  }

  onBsValueChange(value: Date): void {
    this.value = value;

    if (this.isToday(this.value) && this.timeValue > new Date()) {
      this.timeValue = new Date();
      this.timepicker.writeValue(this.timeValue);
    }
  }

  writeValue(value: Date): void {
    this.timeValue = value;
    super.writeValue(value);
  }

  private onMessage(message: Message): void {
    if (message.messageType === MessageType.LanguageChanged) {
      this.updateTimepickerLocale();
    }
  }

  updateTimepickerLocale(): void {
    const locale = this.languageService.getLanguage();
    if (locale === 'en-US') {
      this.isShowMeridian = true;
    } else {
      this.isShowMeridian = false;
    }
    this.bsLocaleService.use(locale.slice(0, 2));
  }

  private _unsubscribeMessageServiceSubscription(): void {
    if (this.messageServiceSubscription) {
      this.messageServiceSubscription.unsubscribe();
      this.messageServiceSubscription = null;
    }
  }

  onMouseDown(event: any): void {
    this.upDownComponent = event.target;
    this.resetTimerInterval();
    this.startTimer();
  }

  onTouchStart(event): void {
    event?.preventDefault();
    this.upDownComponent = event?.target;
    this.upDownComponent?.click();
    this.resetTimerInterval();
    this.startTimer();
  }

  onMouseUp(event: any): void {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = null;
      this.upDownComponent = null;
    }
  }

  private resetTimerInterval(): void {
    this.timerInterval = TIMER_INTERVAL_START;
    this.count = 0;
  }

  private setTimerInterval(): void {
    const step = this.count * 2;
    const decrement = step < TIMER_INTERVAL_WORKING_MIN ? step : TIMER_INTERVAL_WORKING_MIN;
    this.timerInterval = TIMER_INTERVAL_WORKING_MAX - decrement;
  }

  private startTimer(): void {
    clearTimeout(this.timeoutHandler);
    this.timeoutHandler = setTimeout(() => {
      if (this.timeoutHandler) {
        this.count++;
        this.setTimerInterval();
        this.upDownComponent?.click();
        this.startTimer();
      }
    }, this.timerInterval);
  }

}
