export enum CovidCheckOperation {
  WaitCertificateScan,
  WaitCertificateScanStarted,
  ValidateCertificate,
  WaitTestCertificateScan,
  WaitTestCertificateScanStarted,
  ValidateTestCertificate,
  CertificateNotValid,
  CertificateValid,
}
