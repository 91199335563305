import { Money, AcceptedCash } from '../lib';

export class MoneyExchangeRule {
  constructor(
    public from: Money,
    public to: AcceptedCash[],
  ) {
  }

  get isEmpty(): boolean {
    if (!this.from || !this.to)
      return true;

    if(this.to.filter(item => !item.isEmpty).length == 0)
      return true;

    return false;
  }

  static fromJson(data: any): MoneyExchangeRule {
    if (data == null) {
      return null;
    }

    let from = Money.fromJSON(data.from);
    let to = AcceptedCash.fromJsonArray(data.to);

    return new MoneyExchangeRule(from, to);
  }

  static fromJsonArray(rules: any): MoneyExchangeRule[] {
    if (rules == null) {
      return null;
    }
    return rules.map(item => { return MoneyExchangeRule.fromJson(item) });
  }

  toString() {
    return `from: '${this.from}'. to: '${this.to}'`;
  }
}
