import { Injectable } from '@angular/core';
import { ExternalBaseService } from '../../external-base/services/external-base.service';

@Injectable()
export class ExternalTicketService extends ExternalBaseService {

  private barcodeOn: boolean;

  protected internalActivate(): void {
  }

  get isEnabled(): boolean {
    return super.isEnabled || window.location.href.includes('external-ticket');
  }

  set barcodeLedEnabled(enabled: boolean) {
    this.barcodeOn = enabled;
  }

  deactivate(): void {
    if (this.dispatcherService.vuHttp != null && this.barcodeOn) {
      this.dispatcherService.vuHttp.barcodeLedEnable(false);
    }
  }
}
