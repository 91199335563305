import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';

@Injectable()
export class PinGridWorkflowService extends BaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Pin;
  }

  pinInputComplete(pin: string): void {
    if (!this.isPinpadEnabled) {
      return;
    }
    this.userActivity();
    this.displayGridWorkflowService.updateContext({
      pincode: pin
    });

    this.navigateToUrlOrNavigationItem(this.displayItem);
  }

  get isPinpadEnabled(): boolean {
    return this.displayItem != null;
  }

  fillExternalApiRequestData(requestData: Map<string, any>, context: any) {
    if (context == null) {
      return;
    }

    if (context.pincode != null) {
      requestData.set('pincode', context.pincode);
    }

    if (context.barcode != null) {
      requestData.set('barcode', context.barcode);
    }
  }
}
