import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '../general/base/base-modal-component';

@Component({
  selector: 'app-print-modal',
  templateUrl: './print-modal.component.html',
  styleUrls: ['./print-modal.component.css']
})
export class PrintModalComponent extends BaseModalComponent {

  get printError(): boolean {
    return this.data ? this.data.printError : false;
  }

  set printError(varue: boolean) {
    if (this.data)
      this.data.printError = varue;
  }

  close() {
    super.close(null);
  }
}
