<div class="modal-body">
  <div>
    <app-rfid-card-scan (rfidCardScanned)="onRfidCardScanned($event)"></app-rfid-card-scan>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning pull-right button-base button-medium" (click)="close(false)">
    {{ 'Cancel' | translate }}
  </button>
</div>
