import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AcceptedCash, Money, OrderLine, OrderType, PaymentMethod, Product } from '../../lib/lib';
import { ExternalCardRechargeModel } from '../../modules/external-payment/models/external-card-recharge-model';
import { SaleService } from '../sale.service';
import { VuCommunicationService } from '../vu/vu-communication.service';

@Injectable()
export class UnrestrictedCashPaymentService {

  private vuCommunicationService: VuCommunicationService;
  private isTransactionStarted: boolean;
  private saleService: SaleService;
  private product: Product;
  private _cardRechargeModel: ExternalCardRechargeModel;

  constructor(
    protected injector: Injector,
  ) {
    this.vuCommunicationService = injector.get(VuCommunicationService);
    this.vuCommunicationService.vuConnection.eventMoneyChanged.subscribe(x => this.onMoneyChanged(x));
    this.saleService = injector.get(SaleService);
  }

  initialize(): Observable<any> {
    return this.vuCommunicationService.vuHttp.resetUnrestrictedCashPaymentTransaction();
  }

  beginTransaction(model: ExternalCardRechargeModel): Observable<any> {
    return of(true).pipe(
      switchMap(x => {
        if (this.isTransactionStarted) {
          return of(x);
        }
        this.isTransactionStarted = true;
        this._cardRechargeModel = model;
        this.product = model.product;

        this.createOrder(model);
        this.openOrderPaymentSession();
        return this.vuCommunicationService.vuHttp.beginUnrestrictedCashPaymentTransaction();
      })
    );
  }

  private createOrder(model: ExternalCardRechargeModel) {
    this.saleService.resetOrder();
    const orderLine = new OrderLine(
      model.product,
      1,
      new Money(0, model.product.price.currencyCode),
      model.cardNumber);
    orderLine.updateProperties('gift_card_name', model.cardNumber);
    orderLine.updateProperties('gift_card_ean13', model.ean13);
    orderLine.updateProperties('gift_card', true);

    this.saleService.order.orderLines.push(orderLine);

    this.saleService.order.type = OrderType.Sale;
  }

  private openOrderPaymentSession() {
    this.saleService.openPaymentSession(PaymentMethod.Cash, null);
  }

  commitTransaction(): Observable<any> {
    return of(true).pipe(switchMap(x => {
      if (!this.isTransactionStarted) {
        return of(x);
      }
      this.isTransactionStarted = false;
      this._cardRechargeModel = null;
      return this.vuCommunicationService.vuHttp.commitUnrestrictedCashPaymentTransaction();
    }));
  }

  private onMoneyChanged(money: Money) {
    if (!this.isTransactionStarted) {
      return;
    }

    this.saleService.order.updateOrderLineWithFloatingAmount(this.product, money);
  }

  get cardRechargeModel(): ExternalCardRechargeModel {
    return this._cardRechargeModel;
  }
}
