import { AgeRestriction } from "./age-restriction";

export class AgeRestrictionValidator {
  private _ageRestrictions: AgeRestriction[] = new Array<AgeRestriction>();

  static fromString(data: string): AgeRestrictionValidator {

    const ageRestrictionValidator = new AgeRestrictionValidator();

    if (!data)
      return ageRestrictionValidator;

    const restrictionArray = data.split(";");
    if (!restrictionArray)
      return ageRestrictionValidator;

    for (let element of restrictionArray) {
      if (!element)
        continue;
      const ageRestriction = AgeRestriction.fromString(element);
      if (!ageRestriction)
        continue;
      ageRestrictionValidator._ageRestrictions.push(ageRestriction);
    };

    return ageRestrictionValidator;
  }

  isAgeValid(age: number): boolean {
    if (!this._ageRestrictions || this._ageRestrictions.length == 0)
      return true;

    for (let ageRestriction of this._ageRestrictions) {
      if (ageRestriction.isAgeValid(age))
        return true;
    }

    return false;
  }

  isAgesValid(ages: number[], groupSize: number): boolean {
    if (!ages)
      return false;
    if (!this._ageRestrictions || this._ageRestrictions.length == 0)
      return true;

    const usedAgeRestictionsCountByIndex = {};

    const avalibleAgeRestictionsCountByIndex = this.getAvalibleAgeRestictionsCountByIndex();

    for (let ageIndex = 0; ageIndex < ages.length; ageIndex++) {
      let age = ages[ageIndex];
      let found: boolean = false;
      for (let index = 0; index < this._ageRestrictions.length; index++) {
        let ageRestriction = this._ageRestrictions[index];
        if (!ageRestriction || !ageRestriction.isAgeValid(age))
          continue;

        if (!this.isAvalibleAgeRestictions(avalibleAgeRestictionsCountByIndex, index, groupSize - (ageIndex + 1)))
          return false;

        found = true
        if (ageRestriction.personCount == 0)
          break;

        let usedCount = usedAgeRestictionsCountByIndex[index] || 0;
        usedCount++;
        if (usedCount > ageRestriction.personCount)
          return false;
        usedAgeRestictionsCountByIndex[index] = usedCount;

        let avalibleCount = avalibleAgeRestictionsCountByIndex[index];
        if (avalibleCount != null) {
          avalibleCount--;
          avalibleAgeRestictionsCountByIndex[index] = avalibleCount;
        }

        break;
      }
      if (!found)
        return false;
    }

    return true;
  }

  getAvalibleAgeRestictionsCountByIndex() {
    const avalibleAgeRestictionsCountByIndex = {};

    for (let index = 0; index < this._ageRestrictions.length; index++) {
      let ageRestriction = this._ageRestrictions[index];
      if (!ageRestriction || ageRestriction.personCount == 0 || isNaN(ageRestriction.personCount))
        continue;

      avalibleAgeRestictionsCountByIndex[index] = ageRestriction.personCount;
    }

    return avalibleAgeRestictionsCountByIndex;
  }

  isAvalibleAgeRestictions(avalibleAgeRestictionsCountByIndex, index, requestedCount) {
    if (!avalibleAgeRestictionsCountByIndex)
      return false;

    let avalibleAgeRestictionsCount = avalibleAgeRestictionsCountByIndex[index]
    if (avalibleAgeRestictionsCount == null) {
      let count = 0;
      for (let key in avalibleAgeRestictionsCountByIndex) {
        let value = avalibleAgeRestictionsCountByIndex[key];
        if (value)
          count += value;
      }

      return requestedCount >= count;
    }

    return true;
  }

}
