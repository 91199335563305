export enum CreditCardTerminalEventType {
  CardInserted,
  CardRemoved,
  TerminalTextChanged,
  FundsReservationIsCompleted,
  PaymentCompleted,
  PaymentAborted,
  ReceiptReceived,
  RevertTransactionCompleted,
  RevertTransactionFailed
}
