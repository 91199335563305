export class TranslatePropertyHelper {

  translateProperties: any;

  constructor(
    translateProperties: any,
  ) {
    this.translateProperties = translateProperties;
  }

  getTranslation(language: string) {
    if (!this.translateProperties) {
      return null;
    }
    return this.translateProperties[language];
  }

  getTranslationByFieldName(language: string, translateName: string, defaultValue: string = '') {
    const translation = this.getTranslation(language);
    if (!translation) {
      return defaultValue;
    }

    return translation[translateName] || defaultValue;
  }
}
