import { TouchTileType } from "./touch-tile-type";

export class TouchTile {
    name: TouchTileType;
    enabled: Boolean;
    color: String;

    static fromOther(other: TouchTile): TouchTile {
        return Object.assign(new TouchTile(), other);
   }
}

