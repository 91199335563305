<div class="modal-header">
  <div class="modal-title normal-text pull-left">{{ barcodeInputMethodDescription | translate }}</div>
</div>
<div class="modal-body">
  <div>
    <app-barcode-scan></app-barcode-scan>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning pull-right button-base button-medium" (click)="close(false)">
    {{ 'Cancel' | translate }}
  </button>
</div>
