import { AcceptedCash, Money } from '../../lib/lib';
import { MoneyExchangeRule } from '../../lib/money-exchange/money-exchange-rule';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { DispatcherService } from '../dispatcher.service';

@Injectable()
export class MoneyExchangeSimulatorService {
  isEnabled: boolean;

  private _acceptedCoins: Map<number, boolean> = new Map<number, boolean>();
  private _acceptedBanknotes: Map<number, boolean> = new Map<number, boolean>();
  private _moneys: Map<number, Money> = new Map<number, Money>();

  private _coinsRules: MoneyExchangeRule[];
  private _banknoteRules: MoneyExchangeRule[];

  private _moneyExchangeInPorgress: boolean;
  private _exchangeMoney: Money = Money.empty;
  private _returnMoney: Money = Money.empty;
  private _acceptedCashForPayin: AcceptedCash;
  private _acceptedCashForPayout: AcceptedCash;
  private _payoutedMoney: Money = Money.empty;

  private dispatcherService: DispatcherService;

  moneyExchangeReadyToMoneyExchange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private injector: Injector,
  ) {
    this.dispatcherService = injector.get(DispatcherService);
  }

  get banknoteRules(): MoneyExchangeRule[] {
    return this._banknoteRules;
  }

  set banknoteRules(value: MoneyExchangeRule[]) {
    this._banknoteRules = value;
    this._fillAcceptedMoneys(value, this._acceptedBanknotes);
  }

  get acceptedBanknotes(): Money[] {
    return this._getAcceptedMoneys(this._acceptedBanknotes);
  }

  get coinsRules(): MoneyExchangeRule[] {
    return this._coinsRules;
  }

  set coinsRules(value: MoneyExchangeRule[]) {
    this._coinsRules = value;
    this._fillAcceptedMoneys(value, this._acceptedCoins);
  }

  get acceptedCoins(): Money[] {
    return this._getAcceptedMoneys(this._acceptedCoins);
  }

  get acceptedCoinsRules(): MoneyExchangeRule[] {
    return this._getAcceptedRules(this._coinsRules, this._acceptedCoins);
  }

  get acceptedBanknotesRules(): MoneyExchangeRule[] {
    return this._getAcceptedRules(this._banknoteRules, this._acceptedBanknotes);
  }

  private _fillAcceptedMoneys(value: MoneyExchangeRule[], acceptedMoneys: Map<number, boolean>) {
    acceptedMoneys.clear()
    if (value) {
      value.forEach(element => {
        this._moneys.set(element.from.value, element.from);
        acceptedMoneys.set(element.from.value, true);
      });
    }
  }

  private _getAcceptedMoneys(acceptedMoneys: Map<number, boolean>): Money[] {
    var result = new Array<Money>();
    for (let key of Array.from(acceptedMoneys.keys())) {
      if (acceptedMoneys.get(key))
        result.push(this._moneys.get(key));
    }
    return result;
  }

  private _getAcceptedRules(rules: MoneyExchangeRule[], acceptedMoneys: Map<number, boolean>): MoneyExchangeRule[] {
    var result = new Array<MoneyExchangeRule>();
    for (let rule of rules) {
      if (acceptedMoneys.get(rule.from.value))
        result.push(rule);
    }
    return result;
  }

  get acceptedCash(): AcceptedCash {
    return new AcceptedCash(
      this.acceptedCoins,
      this.acceptedBanknotes,
    );
  }

  setAcceptMoney(money: Money, accept: boolean) {
    if (this._acceptedCoins.has(money.value))
      this._acceptedCoins.set(money.value, accept);
    if (this._acceptedBanknotes.has(money.value))
      this._acceptedBanknotes.set(money.value, accept);
  }

  beginTransaction(acceptedCash: AcceptedCash): boolean {
    if (this._moneyExchangeInPorgress)
      return false;

    this._moneyExchangeInPorgress = true;

    var result = this._acceptMoneyForPayin(acceptedCash);
    if (!result)
      this._moneyExchangeInPorgress = false;

    return result;
  }

  rollbackTransaction(): boolean {
    if (!this._moneyExchangeInPorgress)
      return false;

    if (this._exchangeMoney.isZero || this._acceptedCashForPayout) {
      this._resetAllState();
      return true;
    }

    this._returnMoney = this._exchangeMoney;
    this._exchangeMoney = Money.empty;

    this._cashDevicesState(false, true);
    return true;
  }

  commitTransaction(): boolean {
    if (!this._moneyExchangeInPorgress)
      return false;

    this._resetAllState();
    return true;
  }

  payoutMoney(acceptedCash: AcceptedCash): boolean {
    return this._acceptMoneyForPayout(acceptedCash);
  }

  private _resetAllState() {
    this._reset();
    this._moneyExchangeInPorgress = false;
  }

  private _reset() {
    this._acceptedCashForPayin = null;
    this._acceptedCashForPayout = null;
    this._exchangeMoney = Money.empty;
    this._returnMoney = Money.empty;
    this._payoutedMoney = Money.empty;
    this._stopAcceptMoney();
  }

  onPayin(money: Money): boolean {
    if (!this._moneyExchangeInPorgress && (!this._acceptedCashForPayin || !this._acceptedCashForPayin.containsMoney(money)))
      return false;

    this._reset();
    this._exchangeMoney = money;
    return true;
  }

  onPayout(money: Money): boolean {
    if (!this._moneyExchangeInPorgress)
      return false;

    if (this._returnMoney.isEquals(money)) {
      this._resetAllState();
      return true;
    }

    if (!this._returnMoney.isZero)
      return false;

    if (!this._acceptedCashForPayout || !this._acceptedCashForPayout.containsMoney(money))
      return false;

    this._payoutedMoney = this._payoutedMoney.add(money);
    if (this._exchangeMoney.isEqualsOrGreater(this._payoutedMoney))
      this._stopAcceptMoney();

    return true;
  }

  private _acceptMoneyForPayin(acceptedCash: AcceptedCash): boolean {
    if (!acceptedCash)
      return false;

    this._acceptedCashForPayin = acceptedCash;

    this._cashDevicesState(true, false);
    return true;
  }

  private _acceptMoneyForPayout(acceptedCash: AcceptedCash): boolean {
    if (!acceptedCash)
      return false;

    this._acceptedCashForPayout = acceptedCash;

    this._cashDevicesState(false, true);
    return true;
  }

  private _stopAcceptMoney() {
    this._cashDevicesState(false, false);
  }

  private _cashDevicesState(payin: boolean, payout: boolean) {
    this.dispatcherService.cashDevicesStatePayIn(payin);
    this.dispatcherService.cashDevicesStatePayOut(payout);
  }

  readyToMoneyExchange() {
    this.moneyExchangeReadyToMoneyExchange.emit();
  }
}
