import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { ComponentsModule } from '../components/components.module';
import { ReceiptModule } from '../receipt/receipt.module';
import { PaymentCardComponent } from '../../components/shop/payment-card/payment-card.component';
import { AcceptedCreditCardsComponent } from '../../components/shop/accepted-credit-cards/accepted-credit-cards.component';
import { PaymentCashlessComponent } from 'src/app/components/shop/payment-cashless/payment-cashless.component';
import { PaymentBluecodeComponent } from 'src/app/components/shop/payment-bluecode/payment-bluecode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PaymentGiftCardComponent } from 'src/app/components/shop/payment-gift-card/payment-gift-card.component';

// TODO ASK ROUTES MOVE THERE

@NgModule({
  declarations: [
    PaymentCashlessComponent,
    PaymentCardComponent,
    PaymentBluecodeComponent,
    PaymentGiftCardComponent,
    AcceptedCreditCardsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    VUTranslateModule,
    ComponentsModule,
    ReceiptModule,
    QRCodeModule,
  ],
  exports: [
    PaymentCashlessComponent,
    PaymentCardComponent,
  ]
})
export class PaymentCashlessModule { }
