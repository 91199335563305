import { Component, Input } from '@angular/core';
import { GlobalSettings, Money } from '../../../lib/lib';

@Component({
  selector: 'app-min-max-money',
  templateUrl: './min-max-money.component.html',
  styleUrls: ['./min-max-money.component.css']
})
export class MinMaxMoneyComponent {
  @Input() min: Money;
  @Input() max: Money;
  @Input() minName: string = 'Minimum';
  @Input() maxName: string = 'Maximum';

  get minValue(): string {
    return this.min ? this.min.toStringCurrencySign() : '';
  }

  get maxValue(): string {
    return this.max ? this.max.toStringCurrencySign() : '';
  }

  get isMinMaxEqual(): boolean {
    return this.minValue && this.maxValue && this.minValue === this.maxValue;
  }
}
