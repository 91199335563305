import { Injectable, Injector } from '@angular/core';
import { Money, Ticket, OrderSaveResult, PrintTaskResult } from '../../../lib/lib';
import { VuConnectionBase } from './vu-connection-base.service';
import { TurnstileSimulatorService } from '../../turnstile/turnstile-simulator.service';
import { CIOBoardEvent } from '../../../lib/cioboard/coiboard-event';
import { MoneyExchangeSimulatorService } from '../../money-exchange/money-exchange-simulator.service';
import { ConfigurationService } from '../../configuration/configuration.service';

@Injectable()
export class SignalRSimulatorService extends VuConnectionBase {

  private configurationService: ConfigurationService;
  private moneyExchangeSimulatorService: MoneyExchangeSimulatorService;


  constructor(
    protected injector: Injector,
    private turnstileSimulatorService: TurnstileSimulatorService,
  ) {
    super(injector);
  }

  init(): void {
    this.isConneсted = true;
    this.configurationService = this.injector.get(ConfigurationService);
    this.moneyExchangeSimulatorService = this.injector.get(MoneyExchangeSimulatorService);
    this.moneyExchangeSimulatorService.moneyExchangeReadyToMoneyExchange.subscribe(_ => this.moneyExchangeReadyToMoneyExchange());
    super.init();
  }

  changeNavigationUrl(url: string): void {
    this.navigationUrlChanged.emit(url);
  }

  changeConnection(connected: boolean): void {
    this.isConneсted = connected;
    this.eventConnectionChanged.emit(connected);
  }

  writeLogMessages(messages: string[]): Promise<any> {

    const isSent = Math.floor(Math.random() * 10) === 0;

    // if (isSent) {
    //     messages.forEach(message => {
    //         console.log('Simlog: %c' + message, 'color:gray');
    //     });
    // }

    return new Promise<any>((resolve, reject) => {
      if (isSent) {
        resolve(null);
      } else {
        reject();
      }
    });
  }

  barcodeRead(barcode: string): void {
    super.barcodeRead(barcode);
  }

  barcodeReadStarted(): void {
    super.barcodeReadStarted();
  }

  scanTicket(ticket: Ticket): void {
    super.scanTicket(ticket);
  }

  moneyChanged(money: Money): void {
    super.moneyChanged(money);
    this.eventMoneyChanged.emit(money);

    if (money.isPositive) {
      if (this.moneyExchangeSimulatorService.onPayin(money)) {
        this.eventMoneyExchangePayin.emit(money);
      }
    } else {
      if (this.moneyExchangeSimulatorService.onPayout(money.negate())) {
        this.eventMoneyExchangePayout.emit(money.negate());
      }
    }
  }

  moneyExchangeReadyToMoneyExchange(): void {
    super.moneyExchangeReadyToMoneyExchange();
  }

  returnAmountFinished(): void {
    super.returnAmountFinished();
  }

  returnChangeFinished(): void {
    super.returnChangeFinished();
  }

  orderSaveResultReceived(result: OrderSaveResult): void {
    super.orderSaveResultReceived(result);
    this.eventOrderSaveResultReceived.emit(result);
  }

  printTaskResultReceived(result: PrintTaskResult): void {
    super.printTaskResultReceived(result);
    this.eventPrintTaskResultReceived.emit(result);
  }

  printTicketRemoved(result: PrintTaskResult): void {
    super.printTicketRemoved(result);
    this.eventPrintTicketRemoved.emit(result);
  }

  connectionChanged(isConnected: boolean): void {
    super.connectionChanged(isConnected);
  }

  onTurnstileEnter(): void {
    this.turnstileSimulatorService.closeEnter();
    super.onTurnstileEnter();
  }

  onInvalidRfidCardInserted(): void {
    super.onInvalidRfidCardInserted();
  }

  onGateTransactionBegin(x: boolean): void {
    super.onGateTransactionBegin(x);
  }

  onGateTransactionEnd(x: boolean): void {
    super.onGateTransactionEnd(x);
  }

  onBnaValidationStarted(): void {
    super.onBnaValidationStarted();
  }

  onBnaValidationFinished(): void {
    super.onBnaValidationFinished();
  }

  onCIOBoardStateChanged(x: CIOBoardEvent): void {
    super.onCIOBoardStateChanged(x);
  }

  onAllCoinsNotAcceptedEvent(x: any): void {
    super.onAllCoinsNotAcceptedEvent(x);
  }

  onAllBillsNotAcceptedEvent(x: any): void {
    super.onAllBillsNotAcceptedEvent(x);
  }
}
