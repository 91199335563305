import { Injectable } from '@angular/core';
import { Product, Money, DtoTicketUse, CreditCard, Ticket, VisualItem, AcceptedCash } from '../lib/lib';
import { DisplayConfiguration } from '../lib/display/configuration/display-configuration';
import { DisplayConfigurationPageCaptionEnum } from '../lib/display/configuration/display-configuration-page-caption.enum';
import { LogisticsRequestStateEnum } from '../lib/logistics/logistics-request-state-enum';
import { LogisticsRequestLine } from '../lib/logistics/logistics-request-line';
import { DisplayItem } from '../lib/display/display-item/display-item';
import { MoneyExchangeRule } from '../lib/money-exchange/money-exchange-rule';

let toMoney = (value: number) => new Money(value, 'EUR');
const product1 = new Product(1, 'Hour Adults (from 18 years)', toMoney(2.50), 0.19, 5, 60, false,
  {
    'ticket_type': 'Paper',
    'is_partner_required': true,
    'translations':
    {
      'de_DE':
      {
        'name': 'Stundenkarte Erwachsene (ab 18 Jahre)',
        'ticket_display_name': 'Stundenkarte Erwachsene<br/>(ab 18 Jahre)<br/>@price'
      }
    },
    'age_restriction': '18-'
  });
const product2 = new Product(2, 'Hour Children and Adolescents (4-17 years)', toMoney(2.00), 0.19, 6, 60, true,
  {
    'ticket_type': 'Paper',
    'translations':
    {
      'de_DE':
      {
        'name': 'Stundenkarte Kinder und Jugendliche (4-17 Jahre)',
        'ticket_display_name': 'Stundenkarte Kinder und Jugendliche<br/>(4-17 Jahre)<br/>@price'
      }
    },
    'additional_product_ids': [5, 7]
  });
const product3 = new Product(3, '12-Karte Erwachsene (ab 18 Jahre)', toMoney(25.00), 0.19, 5, 60, false,
  {
    'ticket_type': 'Card',
    'appointment_enabled': true,
    'group_size_min': 1,
    'group_size_max': 10,
    'display_birthday_field': true,
    'appointment_keywords': 'TEST;STES;AFDS',
    'is_partner_required': true,
    'translations':
    {
      'de_DE':
      {
        'name': '12-Karte Erwachsene (ab 18 Jahre)',
        'ticket_display_name': '12-Karte Erwachsene<br/>(ab 18 Jahre)<br/>@price'
      }
    }
  });
const product4 = new Product(4, '12-Karte Kinder und Jugendliche (4-17 Jahre)', toMoney(16.00), 0.19, 6, 60, false, { "ticket_type": "Card" });
const product5 = new Product(5, 'Additional Product', toMoney(1.00), 0.19, null, 30, false, { "ticket_type": "Paper" });
const product6 = new Product(6, 'Kinder und Jugendliche je angefangene ½ Stunde', toMoney(0.50), 0.19, null, 30, false, { "ticket_type": "Paper", 'additional_product_ids': [5] });
const product7 = new Product(7, 'Additional Product 2', toMoney(3.00), 0.19, null, 30, false,
  {
    "ticket_type": "Paper",
    "qty_min": 1,
    "qty_max": 1
  });
const product8 = new Product(8, 'Card loading', toMoney(0.00), 0.19, null, 60, false,
  {
    "ticket_type": "Card",
    "additional_product_ids": [7],
    "qty_min": 1,
    "qty_max": 1
  });

const product_k111 = new Product(11, 'Tageskarte Erwachsene', toMoney(7.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k121 = new Product(12, 'Tageskarte Erwachsene', toMoney(9.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k131 = new Product(13, 'Tageskarte Erwachsene', toMoney(11.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});

const product_k112 = new Product(14, 'Tageskarte Ermäßigt', toMoney(5.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k122 = new Product(15, 'Tageskarte Ermäßigt', toMoney(7.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k132 = new Product(16, 'Tageskarte Ermäßigt', toMoney(9.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});

const product_k123 = new Product(18, 'Tageskarte Kind', toMoney(4.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k113 = new Product(17, 'Tageskarte Kind', toMoney(3.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k133 = new Product(19, 'Tageskarte Kind', toMoney(5.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});

const product_k211 = new Product(11, '11er Karte Erwachsene', toMoney(70.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k221 = new Product(12, '11er Karte Erwachsene', toMoney(90.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k231 = new Product(13, '11er Karte Erwachsene', toMoney(110.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});

const product_k212 = new Product(14, '11er Karte Ermäßigt', toMoney(50.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k222 = new Product(15, '11er Karte Ermäßigt', toMoney(70.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k232 = new Product(16, '11er Karte Ermäßigt', toMoney(90.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});

const product_k213 = new Product(17, '11er Karte Kind', toMoney(30.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k223 = new Product(18, '11er Karte Kind', toMoney(40.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});
const product_k233 = new Product(19, '11er Karte Kind', toMoney(50.00), 0.19, 1, 60, false, {"ticket_type": "Paper"});

const product_k234 = new Product(20, 'Family', toMoney(10.00), 0.19, 1, 60, false, {"ticket_type": "Paper", "group_size_min": 3, "group_size_max": 5});

const acceptedCoins: Money[] = [
  new Money(0.10, 'EUR'),
  new Money(0.20, 'EUR'),
  new Money(0.50, 'EUR'),
  new Money(1.00, 'EUR'),
  new Money(2.00, 'EUR')
];

const acceptedBanknotes: Money[] = [
  new Money(5.00, 'EUR'),
  new Money(10.00, 'EUR'),
];

const acceptedCreditCards: CreditCard[] = [
  new CreditCard('MasterCard'),
  new CreditCard('Visa'),
  new CreditCard('EC')
];

@Injectable()
export class InMemoryDataService {

  get acceptedCash() {
    return {
      coins: acceptedCoins,
      banknotes: acceptedBanknotes,
    };
  }

  getVisualItems(type: string): any {
    switch (type) {
      case 'store':
        return this.createVisualItemsStoreKletterzentrum();
      case 'info':
        return this.createVisualItemsInfo();
      case 'displayConfiguration':
        return this.createDisplayConfiguration();
      default:
        return [];
    }
  }

  createVisualItemsStore(): any {
    let cat1 = [
      {
        id: 1, name: 'Hour Adults<br/>(from 18 years)<br/>@price', sequence: 1, data: product1
      },
      {
        id: 2, name: 'Hour Children and Adolescents<br/>(4-17 years)<br/>@price', sequence: 2, data: product2
      },
      {
        id: 3, name: '12 Card Adults<br/>(from 18 years)<br/>@price', sequence: 3, data: product3
      },
      {
        id: 4, name: '12-Karte Kinder und Jugendliche<br/>(4-17 Jahre)<br/>@price', sequence: 4, data: product4
      },
      {
        id: 5, name: 'Categories', sequence: 5,
        data: {
          'translations':
          {
            'de_DE':
            {
              'name': 'Kategorien',
            }
          }
        },
        children: [
          {
            id: 6, name: 'Categories 1', sequence: 5, children: [
              {
                id: 7, name: 'Product 1<br/>@price', sequence: 1, data: product6
              },
              {
                id: 8, name: 'Product 2<br/>@price', sequence: 1, data: product6
              }
            ]
          },
          {
            id: 9, name: 'Categories 2', sequence: 5, children: [
              {
                id: 10, name: 'Product 1<br/>@price', sequence: 1, data: product6
              },
              {
                id: 11, name: 'Product 2<br/>@price', sequence: 1, data: product6
              },
              {
                id: 11, name: 'Product 3<br/>@price', sequence: 1, data: product6
              }
            ]
          },
          {
            id: 12, name: 'Categories 3', sequence: 5, children: [
              {
                id: 13, name: 'Product 1<br/>@price', sequence: 1, data: product6
              }
            ]
          },
        ]
      },
    ];

    // return [
    //     { id: 1, name: 'Category 1', sequence: 10, children: cat1 },
    // ];

    return cat1;
  }

  createVisualItemsStoreKletterzentrum(): any {
    return [
      {
        id: 0, name: 'Member DAV Section Illertissen', sequence: 1, data: { type: "Category", checkCardType: "DavIllertissen" }, children: [
          {
            id: 1, name: 'Tageskarte Erwachsene<br/>@price', sequence: 2, data: product_k111
          },
          {
            id: 2, name: 'Tageskarte Ermäßigt<br/>@price', sequence: 3, data: product_k112
          },
          {
            id: 3, name: 'Tageskarte Kind<br/>@price', sequence: 4, data: product_k113
          },
          {
            id: 4, name: '11er Karte Erwachsene<br/>@price', sequence: 5, data: product_k211
          },
          {
            id: 5, name: '11er Karte Ermäßigt<br/>@price', sequence: 6, data: product_k212
          },
          {
            id: 6, name: '11er Karte Kind<br/>@price', sequence: 7, data: product_k213
          },
        ]
      },
      {
        id: 10, name: 'Member DAV', sequence: 2, data: { type: "Category", checkCardType: "Dav" }, children: [
          {
            id: 11, name: 'Tageskarte Erwachsene<br/>@price', sequence: 2, data: product_k121
          },
          {
            id: 12, name: 'Tageskarte Ermäßigt<br/>@price', sequence: 3, data: product_k122
          },
          {
            id: 13, name: 'Tageskarte Kind<br/>@price', sequence: 4, data: product_k123
          },
          {
            id: 14, name: '11er Karte Erwachsene<br/>@price', sequence: 5, data: product_k221
          },
          {
            id: 15, name: '11er Karte Ermäßigt<br/>@price', sequence: 6, data: product_k222
          },
          {
            id: 16, name: '11er Karte Kind<br/>@price', sequence: 7, data: product_k223
          },
        ]
      },
      {
        id: 20, name: 'Non member DAV', sequence: 3, data: { type: "Category", checkCardType: "None" }, children: [
          {
            id: 21, name: 'Tageskarte Erwachsene<br/>@price', sequence: 2, data: product_k131
          },
          {
            id: 22, name: 'Tageskarte Ermäßigt<br/>@price', sequence: 3, data: product_k132
          },
          {
            id: 23, name: 'Tageskarte Kind<br/>@price', sequence: 4, data: product_k133
          },
          {
            id: 24, name: '11er Karte Erwachsene<br/>@price', sequence: 5, data: product_k231
          },
          {
            id: 25, name: '11er Karte Ermäßigt<br/>@price', sequence: 6, data: product_k232
          },
          {
            id: 26, name: '11er Karte Kind<br/>@price', sequence: 7, data: product_k233
          },
          {
            id: 27, name: 'Family<br/>@price', sequence: 8, data: product_k234
          },
        ]
      },
    ]
  }

  get products() {
    return [product1, product2, product3, product4, product5, product6, product7, product8];
  }

  createVisualItemsInfo(): any {
    return [
      { id: 1, name: 'Belegungsplan', sequence: 1, data: { imageSrc: '/static/images/volatile/belegungsplan-2016-2017.png' } },
      { id: 2, name: 'Kurse', sequence: 2, data: { imageSrc: '/static/images/volatile/kurse.png' } },
    ];
  }

  getTicketUse(code: string): DtoTicketUse[] {
    return [
      new DtoTicketUse('2017-11-21T18:25:43.511Z', 'Entry'),
      new DtoTicketUse('2017-11-21T18:25:30.511Z', 'Entry'),
      new DtoTicketUse('2017-11-21T18:26:43.511Z', 'Exit'),
      new DtoTicketUse('2017-10-20T19:26:42.511Z', 'Entry'),
      new DtoTicketUse('2017-10-20T19:26:43.511Z', 'Exit'),
    ];
  }

  get acceptedCreditCards() {
    return acceptedCreditCards;
  }

  generateTicket(code: string = 'VAQZGS0W'): Ticket {
    let products = this.products.filter(x => x.subProductId != null);
    let index = Math.floor(Math.random() * products.length);
    let product = products[index];
    let ticket = new Ticket();
    ticket.createDate = '2017-09-30T18:25:43.511Z';
    ticket.code = code;
    ticket.productName = product.name;
    ticket.productId = product.id;
    ticket.quantity = product.quantity;
    return ticket;
  }

  logisticsRequestNotFound(): any {
    return {
      "items": [
        {
          "id": 7770001,
          "name": "Bad state",
          "sequence": 0,
          "product_reference": false,
          "pos_x": 1,
          "pos_y": 1,
          "size_horizontal": 57,
          "size_vertical": 20,
          "html_template": '<div class="grid-cell-container-middle" style="background: SkyBlue;"><div class="grid-cell-container-inner">Barcode not Found</div></div>',
          "type": "category",
        },
        {
          "id": 7770011,
          "name": "Bad state",
          "sequence": 0,
          "product_reference": false,
          "pos_x": 1,
          "pos_y": 1,
          "size_horizontal": 57,
          "size_vertical": 20,
          "html_template": '<div class="grid-cell-container-middle" style="background: SkyBlue;"><div class="grid-cell-container-inner">Barcode not Found</div></div>',
          "type": "static",
        }
      ],
      "items_tree": [
        {
          "item_id": 7770001,
          "items": [
            {
              "item_id": 7770011,
              "items": []
            }
          ]
        }
      ],
    }
  }

  logisticsRequestUsed(): any {
    return {
      "items": [
        {
          "id": 7770002,
          "name": "Bad state",
          "sequence": 0,
          "product_reference": false,
          "pos_x": 1,
          "pos_y": 1,
          "size_horizontal": 57,
          "size_vertical": 20,
          "html_template": '<div class="grid-cell-container-middle" style="background: yellow;"><div class="grid-cell-container-inner">Ticket is used</div></div>',
          "type": "category",
        },
        {
          "id": 7770022,
          "name": "Bad state",
          "sequence": 0,
          "product_reference": false,
          "pos_x": 1,
          "pos_y": 1,
          "size_horizontal": 57,
          "size_vertical": 20,
          "html_template": '<div class="grid-cell-container-middle" style="background: yellow;"><div class="grid-cell-container-inner">Ticket is used</div></div>',
          "type": "static",
        }
      ],
      "items_tree": [
        {
          "item_id": 7770002,
          "items": [
            {
              "item_id": 7770022,
              "items": []
            }
          ]
        }
      ],
    }
  }

  createLogisticsRequest(): any[] {
    return [{
      "id": 1,
      "name": "FR00001",
      "partner": "Jhon",
      "state": "new",
      "logisticsRequestLine": [{
        "id": 1,
        "productId": "",
        "quantity": 1,
        "ticketBarCode": "12345678",
        "displayItem": {
          "items": [
            {
              "id": 7770003,
              "name": "Singapur",
              "sequence": 0,
              "product_reference": false,
              "pos_x": 1,
              "pos_y": 1,
              "size_horizontal": 57,
              "size_vertical": 20,
              "html_template": '<div class="grid-cell-container-middle" style="background: SkyBlue;"><div class="grid-cell-container-inner">Item1</div></div>',
              "type": "category",
            },
            {
              "id": 7770033,
              "name": "Item1",
              "sequence": 0,
              "product_reference": false,
              "pos_x": 1,
              "pos_y": 1,
              "size_horizontal": 57,
              "size_vertical": 20,
              "html_template": '<div class="grid-cell-container-middle" style="background: #a1a1a1;"><div class="grid-cell-container-inner">Singapur</div></div>',
              "type": "static",
            }
          ],
          "items_tree": [
            {
              "item_id": 7770003,
              "items": [
                {
                  "item_id": 7770033,
                  "items": []
                }
              ]
            }
          ],
        }
      }]
    },
    {
      "id": 2,
      "name": "FR00002",
      "partner": "Bill",
      "state": LogisticsRequestStateEnum.InProgress,
      "logisticsRequestLine": [{
        "id": 2,
        "productId": "",
        "quantity": 1,
        "ticketBarCode": "87654321",
        "displayItem": {
          "items": [
            {
              "id": 7770004,
              "name": "France",
              "sequence": 0,
              "product_reference": false,
              "pos_x": 1,
              "pos_y": 1,
              "size_horizontal": 57,
              "size_vertical": 20,
              "html_template": '<div class="grid-cell-container-middle" style="background: SkyBlue;"><div class="grid-cell-container-inner">Item2</div></div>',
              "type": "category",
            },
            {
              "id": 7770044,
              "name": "Item2",
              "sequence": 0,
              "product_reference": false,
              "pos_x": 1,
              "pos_y": 1,
              "size_horizontal": 57,
              "size_vertical": 20,
              "html_template": '<div class="grid-cell-container-middle" style="background: #a1a1a1;"><div class="grid-cell-container-inner">France</div></div>',
              "type": "static",
            }
          ],
          "items_tree": [
            {
              "item_id": 7770004,
              "items": [
                {
                  "item_id": 7770044,
                  "items": []
                }
              ]
            }
          ],
        }
      }],
    },
    {
      "id": 3,
      "name": "FR00003",
      "partner": "Dulcinea",
      "state": LogisticsRequestStateEnum.Closed,
      "logisticsRequestLine": [{
        "id": 3,
        "productId": "",
        "quantity": 1,
        "ticketBarCode": "11111111",
        "displayItem": {
          "items": [
            {
              "id": 7770005,
              "name": "Germany",
              "sequence": 0,
              "product_reference": false,
              "pos_x": 1,
              "pos_y": 1,
              "size_horizontal": 57,
              "size_vertical": 20,
              "html_template": '<div class="grid-cell-container-middle" style="background: #a1a1a1;"><div class="grid-cell-container-inner">Item3</div></div>',
              "type": "category",
            },
            {
              "id": 7770055,
              "name": "Item3",
              "sequence": 0,
              "product_reference": false,
              "pos_x": 1,
              "pos_y": 1,
              "size_horizontal": 57,
              "size_vertical": 20,
              "html_template": '<div class="grid-cell-container-middle" style="background: #a1a1a1;"><div class="grid-cell-container-inner">Germany</div></div>',
              "type": "static",
            }
          ],
          "items_tree": [
            {
              "item_id": 7770005,
              "items": [
                {
                  "item_id": 7770055,
                  "items": []
                }
              ]
            }
          ],
        },
      }],
    }
    ]
  }

  createDisplayConfiguration(): any {
    return {
      "properties": {
        "name": "Workflows tests",
        "items": [
          {
            "id": 1,
            "name": "Item-11",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 1,
            "pos_y": 1,
            "size_horizontal": 58,
            "size_vertical": 20,
            "html_template": '<div class="grid-cell-container-middle" style="background: white;"><div class="grid-cell-container-inner">Main Static</div></div>',
            "type": "static",
          },
          {
            "id": 2,
            "name": "Item-12",
            "sequence": 0,
            "product_reference": product3.id,
            "pos_x": 2,
            "pos_y": 16,
            "size_horizontal": 10,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: yellow;"><div class="grid-cell-container-inner">@product_name</br><span style="color:green">@price</span></br>Line 1 Line 1 Line 1 Line 1 Line 1 Line 1 Line 1 Line 1 </br>Line 2</br>Line 3</div></div>',
            "type": "product",
          },
          {
            "id": 3,
            "name": "Item-22",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 46,
            "pos_y": 16,
            "size_horizontal": 10,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: YellowGreen;"><div class="grid-cell-container-inner">Next Page (Display)</br><span style="color:green">@price</span></div></div>',
            "type": "category",
            "background": -1,
            "z_index": 10,
          },
          {
            "id": 4,
            "name": "Item-200",
            "sequence": 0,
            "pos_x": 1,
            "pos_y": 1,
            "size_horizontal": 9,
            "size_vertical": 5,
            "html_template": '<div class="grid-cell-container-middle" style="background: orange;"><div class="grid-cell-container-inner">Static Page 2</br></div></div>',
            "type": "static",
          },
          {
            "id": 5,
            "name": "Item-201",
            "sequence": 0,
            "product_reference": product6.id,
            "pos_x": 0,
            "pos_y": 0,
            "size_horizontal": 0,
            "size_vertical": 0,
            "html_template": '<div class="grid-cell-container-middle" style="background: yellow;"><div class="grid-cell-container-inner">@product_name</br><span style="color:green">@price</span></div></div>',
            "type": "product",
          },
          {
            "id": 6,
            "name": "Simulation ticket validation",
            "pos_x": 2,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: Azure;"><div class="grid-cell-container-inner">Simulation ticket validation</div></div>',
            "type": "category",
          },
          {
            "id": 7,
            "name": "CASH INPUT",
            "sequence": 0,
            "product_reference": product3.id,
            "pos_x": 32,
            "pos_y": 2,
            "size_horizontal": 11,
            "size_vertical": 16,
            "html_template": '<div class="grid-cell-container-middle" style="background: orange;"><div class="grid-cell-container-inner"><visual-item-info>PIN INPUT</visual-item-info></br>@pin_pad</div></div>',
            "type": "cash_refund",
            "pin_length": "4",
            "api_url": "http://localhost:8061/api/pin/validate/",
            "min_amount": 1,
            "max_amount": 100,
          },
          {
            "id": 8,
            "name": "Bad Print",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 10,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: SkyBlue;"><div class="grid-cell-container-inner">Bad Print</div></div>',
            "type": "print",
          },
          {
            "id": 9,
            "name": "Print",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 2,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: Khaki;"><div class="grid-cell-container-inner">Print</div></div>',
            "type": "print",
            "print_template_type_unique_name": "display_item",
          },
          {
            "id": 10,
            "pos_x": 2,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: Coral;"><div class="grid-cell-container-inner">Debug</div></div>',
            "type": "debug",
          },
          {
            "id": 11,
            "name": "Page 2",
            "pos_x": 15,
            "pos_y": 16,
            "size_horizontal": 10,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: PaleGreen;"><div class="grid-cell-container-inner">Page 2</div></div>',
            "type": "category",
            "background": -1,
          },
          {
            "id": 12,
            "sequence": 0,
            "pos_x": 1,
            "pos_y": 1,
            "size_horizontal": 57,
            "size_vertical": 20,
            "html_template": '<div class="grid-cell-container-middle" style="background: AntiqueWhite;"><div class="grid-cell-container-inner">Page 2</br></div></div>',
            "type": "static",
          },
          {
            "id": 14,
            "name": "Page 3",
            "pos_x": 2,
            "pos_y": 16,
            "size_horizontal": 10,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: Gold;"><div class="grid-cell-container-inner">Page 3</div></div>',
            "type": "category",
            "background": -1,
          },
          {
            "id": 15,
            "sequence": 0,
            "pos_x": 1,
            "pos_y": 1,
            "size_horizontal": 57,
            "size_vertical": 20,
            "html_template": '<div class="grid-cell-container-middle" style="background: Honeydew;"><div class="grid-cell-container-inner">Page 3</br></div></div>',
            "type": "static",
          },
          {
            "id": 16,
            "name": "Open Fmcu",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 2,
            "pos_y": 10,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: SkyBlue;"><div class="grid-cell-container-inner">Open Fmcu</div></div>',
            "type": "fmcu_action",
            "api_url": "http://localhost:3000/",
            "timeout": 50000,
          },
          {
            "id": 17,
            "name": "Bad Open FMCU",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 10,
            "pos_y": 10,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: SkyBlue;"><div class="grid-cell-container-inner">Bad Open FMCU</div></div>',
            "type": "fmcu_action",
          },
          {
            "id": 18,
            "name": "Static page",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 1,
            "pos_y": 1,
            "size_horizontal": 58,
            "size_vertical": 20,
            "html_template": '<div class="grid-cell-container-middle" style="background: white;"><div class="grid-cell-container-inner"></div></div>',
            "type": "static",
          },
          {
            "id": 19,
            "name": "Barcode scan",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 24,
            "pos_y": 10,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Scan barcode</br></div></div>',
            "type": "ticket",
            "api_url": "/logistics/request/",
          },
          {
            "id": 20,
            "name": "Odoo ticket validation",
            "pos_x": 10,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: Moccasin;"><div class="grid-cell-container-inner">Odoo ticket validation</div></div>',
            "type": "category",
          },
          {
            "id": 21,
            "name": "Barcode scan",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 24,
            "pos_y": 10,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Scan barcode</br></div></div>',
            "type": "ticket",
            "api_url": "http://localhost:8069/api/logistics/request/",
          },
          {
            "id": 22,
            "name": "Steps List Workflow Category",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 2,
            "pos_y": 5,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Progress Steps List</div></div>',
            "type": "category",
          },
          {
            "id": 23,
            "name": "Steps List Workflow",
            "sequence": 0,
            "pos_x": 8,
            "pos_y": 1,
            "size_horizontal": 41,
            "size_vertical": 19,
            "type": "steps_list_workflow",
          },
          {
            "id": 24,
            "name": "Card balance",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 2,
            "pos_y": 16,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: DarkSalmon;"><div class="grid-cell-container-inner">Card balance</div></div>',
            "type": "category",
          },
          {
            "id": 25,
            "name": "Sale order",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 2,
            "pos_y": 2,
            "size_horizontal": 5,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: DarkSalmon;"><div class="grid-cell-container-inner">Sale order</div></div>',
            "type": "category",
          },
          {
            "id": 26,
            "name": "Sale order",
            "sequence": 0,
            "navigation_item_id": 27,
            "product_reference": false,
            "pos_x": 2,
            "pos_y": 2,
            "size_horizontal": 5,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: PaleGreen;"><div class="grid-cell-container-inner">Sale order</div></div>',
            "type": "payment_confirmation",
          },
          {
            "id": 27,
            "name": "Finish",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 2,
            "pos_y": 10,
            "size_horizontal": 5,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: DarkSalmon;"><div class="grid-cell-container-inner">Finish</div></div>',
            "type": "category",
          },
          {
            "id": 40,
            "name": "Cash Payment Category",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 10,
            "pos_y": 5,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Cash Payment</br></div></div>',
            "type": "category",
          },
          {
            "id": 41,
            "name": "Cash Payment",
            "sequence": 0,
            "product_reference": product1.id,
            "pos_x": 8,
            "pos_y": 1,
            "size_horizontal": 41,
            "size_vertical": 19,
            "type": "cash_payment",
          },
          {
            "id": 42,
            "name": "Receipt Switcher",
            "sequence": 0,
            "pos_x": 10,
            "pos_y": 14,
            "size_horizontal": 8,
            "size_vertical": 3,
            "type": "receipt_switcher",
          },
          {
            'id': 43,
            'name': 'Assets viewer',
            'pos_x': 18,
            'pos_y': 8,
            'size_horizontal': 8,
            'size_vertical': 3,
            'html_template': '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Image Viewer</div></div>',
            'type': 'category',
          },
          {
            'id': 44,
            'name': 'Pdf viewer component',
            'pos_x': 4,
            'pos_y': 4,
            'size_horizontal': 25,
            'size_vertical': 14,
            'api_url': 'http://localhost:4200/assets/pdf/test.pdf',
            'type': 'pdf',
          },
          {
            'id': 45,
            'name': 'Image viewer component',
            'pos_x': 30,
            'pos_y': 4,
            'size_horizontal': 25,
            'size_vertical': 14,
            'api_url': 'http://localhost:4200/static/images/info/category_demo1.jpg',
            'type': 'image',
          },
          {
            'id': 46,
            'name': 'CIOBoard component',
            'pos_x': 30,
            'pos_y': 4,
            'size_horizontal': 25,
            'size_vertical': 14,
            'previous_display_item': true,
            'html_template': '<div class="grid-cell-container-middle" style="background: Plum;"><div class="grid-cell-container-inner">CIOboard component </div></div>',
            'type': 'cioboard_event',
            'cioboard_pin_events': [{ 'name': 'in0', 'state': true }]
          },
          {
            "id": 47,
            "name": "basket",
            "pos_x": 20,
            "pos_y": 7,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Basket</div></div>',
            "type": "basket",
          },
          {
            "id": 48,
            "name": "Static",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 10,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Static</div></div>',
            "type": "category",
          },
          {
            "id": 49,
            "name": "Static",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 18,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Category</div></div>',
            "type": "category",
          },
          {
            "id": 50,
            "name": "Product",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 26,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Product</div></div>',
            "type": "category",
          },
          {
            "id": 51,
            "name": "Print",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 34,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Print</div></div>',
            "type": "category",
          },
          {
            "id": 52,
            "name": "Ticket Validation",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 42,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Ticket Validation</div></div>',
            "type": "category",
          },
          {
            "id": 53,
            "name": "FMCU Action",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 50,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">FMCU Action</div></div>',
            "type": "category",
          },
          {
            "id": 54,
            "name": "Cash Payment Confirmation",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 18,
            "pos_y": 5,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Cash Payment Confirmation</div></div>',
            "type": "category",
          },
          {
            "id": 55,
            "name": "Cash Refund",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 26,
            "pos_y": 5,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Cash Refund</div></div>',
            "type": "category",
          },
          {
            "id": 56,
            "name": "Cash Refund Confirmation",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 34,
            "pos_y": 5,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Cash Refund Confirmation</div></div>',
            "type": "category",
          },
          {
            "id": 57,
            "name": "Cash Payment Cancellation",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 42,
            "pos_y": 5,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Cash Payment Cancellation</div></div>',
            "type": "category",
          },
          {
            "id": 58,
            "name": "RFID Card",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 50,
            "pos_y": 5,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">RFID Card</div></div>',
            "type": "category",
          },
          {
            "id": 59,
            "name": "Recipt Switcher",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 2,
            "pos_y": 8,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Recipt Switcher</div></div>',
            "type": "category",
          },
          {
            "id": 60,
            "name": "PDF Viewer",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 10,
            "pos_y": 8,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">PDF Viewer</div></div>',
            "type": "category",
          },
          {
            "id": 61,
            "name": "Server Action",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 26,
            "pos_y": 8,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Server Action</div></div>',
            "type": "category",
          },
          {
            "id": 62,
            "name": "Basket",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 34,
            "pos_y": 8,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Basket</div></div>',
            "type": "category",
          },
          {
            "id": 63,
            "name": "CIOBoard Event",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 42,
            "pos_y": 8,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">CIOBoard Event</div></div>',
            "type": "category",
          },
          {
            "id": 64,
            "name": "Enter Pin Code",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 50,
            "pos_y": 8,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Enter Pin Code</div></div>',
            "type": "category",
          },
          {
            "id": 65,
            "name": "Category Timeout",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 2,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "timeout_to_show_next_item": 5000,
            "navigation_item_id": 66,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Category Timeout (5 sec)</div></div>',
            "type": "category",
          },
          {
            "id": 66,
            "name": "Empty Category",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 0,
            "pos_y": 0,
            "size_horizontal": 0,
            "size_vertical": 0,
            "html_template": '<div class="grid-cell-container-middle" style="display:none; background: LightGray;"><div class="grid-cell-container-inner">Empty Category</div></div>',
            "type": "category",
            "hidden": true,
          },
          {
            "id": 67,
            "name": "Card Payment Category",
            "sequence": 0,
            "pos_x": 2,
            "pos_y": 11,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Card payment</br></div></div>',
            "type": "category",
          },
          {
            "id": 68,
            "name": "Card Payment",
            "sequence": 0,
            "pos_x": 1,
            "pos_y": 1,
            "size_horizontal": 57,
            "size_vertical": 20,
            "type": "card_payment",
          },
          {
            "id": 69,
            "name": "Keypad Category",
            "sequence": 0,
            "pos_x": 10,
            "pos_y": 11,
            "size_horizontal": 8,
            "size_vertical": 3,
            "type": "category",
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Keypad</br></div></div>',
          },
          {
            "id": 70,
            "name": "Keypad",
            "sequence": 0,
            "pos_x": 15,
            "pos_y": 1,
            "size_horizontal": 37,
            "size_vertical": 20,
            "type": "keypad",
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Keypad</br></div></div>',
          },
          {
            "id": 71,
            "name": "input text",
            "sequence": 0,
            "pos_x": 1,
            "pos_y": 1,
            "size_horizontal": 10,
            "size_vertical": 5,
            "type": "input_text",
            "unique_name": "car_number",
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner"></div></div>',
          },
          {
            "id": 72,
            "name": "input text",
            "sequence": 0,
            "pos_x": 1,
            "pos_y": 6,
            "size_horizontal": 10,
            "size_vertical": 5,
            "type": "input_text",
            "unique_name": "input2",
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner"></div></div>',
          },
          {
            "id": 73,
            "name": "server action",
            "unique_name": "input_complete_button",
            "sequence": 0,
            "pos_x": 1,
            "pos_y": 12,
            "size_horizontal": 10,
            "size_vertical": 5,
            "type": "server_action",
            "api_url": "http://localhost:8069/api/car_rental/request",
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;">Server Action<div class="grid-cell-container-inner"></div></div>',
          },
          {
            'id': 74,
            'name': 'Markdown viewer',
            'pos_x': 18,
            'pos_y': 11,
            'size_horizontal': 8,
            'size_vertical': 3,
            'html_template': '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Markdown Viewer</div></div>',
            'type': 'category',
          },
          {
            'id': 75,
            'name': 'Markdown viewer component',
            'pos_x': 4,
            'pos_y': 4,
            'size_horizontal': 50,
            'size_vertical': 14,
            'api_url': 'http://localhost:4200/assets/markdown/test.md',
            'type': 'markdown',
          },
          {
            "id": 76,
            "name": "PIN code",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 22,
            "pos_y": 2,
            "size_horizontal": 14,
            "size_vertical": 18,
            "html_template": '<div class="grid-cell-container-middle" style="background: orange;"><div class="grid-cell-container-inner"><visual-item-info>PIN INPUT</visual-item-info></br>@pin_pad</div></div>',
            "type": "pin",
            "pin_length": "4",
            "api_url": "http://localhost:8061/api/pin/validate/",
          },
          {
            "id": 77,
            "name": "Custom Price Product Category",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 26,
            "pos_y": 11,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Custom Price Product</div></div>',
            "type": "category",
          },
          {
            "id": 78,
            "name": "Ticket Custom Price",
            "sequence": 1,
            "product_reference": product5.id,
            "pos_x": 10,
            "pos_y": 11,
            "size_horizontal": 10,
            "size_vertical": 5,
            "html_template": `<div class="grid-cell-container-middle" style="background: yellow;"><div class="grid-cell-container-inner">Ticket</br>@product_name</br><span style="color:green">custom_price:@price</span></br>product_price:${product5.price.toString()}</div></div>`,
            "type": "product",
            "custom_price": 50.0
          },
          {
            "id": 79,
            "name": "Card Custom Price",
            "sequence": 1,
            "product_reference": product8.id,
            "pos_x": 30,
            "pos_y": 11,
            "size_horizontal": 10,
            "size_vertical": 5,
            "html_template": `<div class="grid-cell-container-middle" style="background: white;"><div class="grid-cell-container-inner">@product_name</br><span style="color:green">custom_price:@price</span></br>product_price:${product8.price.toString()}</div></div>`,
            "type": "product",
            "custom_price": 50.0
          },
          {
            "id": 80,
            "name": "Category with SubProduct",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 34,
            "pos_y": 11,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Product with SubProduct</div></div>',
            "type": "category",
          },
          {
            "id": 81,
            "name": "Product with Additional Product",
            "sequence": 1,
            "product_reference": product6.id,
            "pos_x": 30,
            "pos_y": 11,
            "size_horizontal": 10,
            "size_vertical": 5,
            "html_template": `<div class="grid-cell-container-middle" style="background: white;"><div class="grid-cell-container-inner">@product_name</br><span style="color:green">@price</span></div></div>`,
            "type": "product",
          },
          {
            "id": 82,
            "name": "Card Dispenser",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 42,
            "pos_y": 11,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Card Dispenser</div></div>',
            "type": "category",
          },
          {
            "id": 83,
            "name": "Card Dispenser Component",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 1,
            "pos_y": 1,
            "size_horizontal": 58,
            "size_vertical": 20,
            "html_template": '<div class="grid-cell-container-middle" style="background: white;"><div class="grid-cell-container-inner">Insert Card</div></div>',
            "type": "card_dispenser",
            "navigation_item_id": 85,
          },
          {
            "id": 84,
            "name": "Card Dispenser Info Widget",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 26,
            "pos_y": 8,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: white;"><div class="grid-cell-container-inner">@rfidCardCode</div></div>',
            "type": "static",
          },
          {
            "id": 85,
            "name": "Card Dispenser Info Category",
            "sequence": 0,
            "product_reference": false,
            "pos_x": -1,
            "pos_y": -1,
            "size_horizontal": 0,
            "size_vertical": 0,
            "html_template": '<div class="grid-cell-container-middle" style="background: white;"><div class="grid-cell-container-inner"></div></div>',
            "type": "category",
          },
          {
            "id": 86,
            "name": "Main Page",
            "sequence": 0,
            "product_reference": false,
            "pos_x": 10,
            "pos_y": 2,
            "size_horizontal": 8,
            "size_vertical": 3,
            "html_template": '<div class="grid-cell-container-middle" style="background: LightGray;"><div class="grid-cell-container-inner">Main Page</div></div>',
            "type": "category",
          },
        ],
        "columns_count": 58,
        "row_count": 20,
        "page_caption_type": "Automatic",
        "items_tree": [
          {
            "comment": "Main Static",
            "item_id": 1,
            "items": []
          },
          {
            "comment": "Debug workflow",
            "item_id": 10,
            "items": []
          },
          {
            "comment": "Progress Steps List workflow",
            "item_id": 22,
            "items": [
              {
                "comment": "Progress Steps List",
                "item_id": 23,
                "items": []
              },
            ]
          },
          {
            "comment": "Card balance??",
            "item_id": 24,
            "items": [
              {
                "item_id": 1,
                "items": []
              },
              {
                "item_id": 25,
                "items": [
                  {
                    "item_id": 1,
                    "items": []
                  },
                  {
                    "item_id": 23,
                    "items": []
                  },
                  {
                    "item_id": 26,
                    "items": [
                      {
                        "item_id": 1,
                        "items": [
                          {
                            "item_id": 27,
                            "items": [
                              {
                                "item_id": 1,
                                "items": []
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
            ]
          },
          {
            "comment": "Static workflow",
            "item_id": 48,
            "items": [
              {
                "comment": "Static background",
                "item_id": 1,
                "items": []
              },
            ]
          },
          {
            "comment": "Category workflow",
            "item_id": 49,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Next Page (Display)",
                "item_id": 3,
                "items": [
                  {
                    "item_id": 4,
                    "items": []
                  },
                  {
                    "item_id": 5,
                    "items": []
                  },

                ]
              },
              {
                "comment": "Back to Main Page",
                "item_id": 86,
                "items": []
              },
              {
                "comment": "Page 2",
                "item_id": 11,
                "items": [
                  {
                    "item_id": 12,
                    "items": []
                  },
                  {
                    "item_id": 10,
                    "items": []
                  },
                  {
                    "item_id": 14,
                    "items": [
                      {
                        "item_id": 15,
                        "items": []
                      },
                      {
                        "item_id": 10,
                        "items": []
                      },
                    ]
                  },
                ]
              },
              {
                "comment": "Category Timeout",
                "item_id": 65,
                "items": [
                  {
                    "comment": "White background",
                    "item_id": 18,
                    "items": []
                  },
                  {
                    "comment": "Empty Category",
                    "item_id": 66,
                    "items": []
                  },
                ]
              },
            ]
          },
          {
            "comment": "Product workflow",
            "item_id": 50,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Demo product",
                "item_id": 2,
                "items": []
              },

            ]
          },
          {
            "comment": "Print workflow",
            "item_id": 51,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Bad Print",
                "item_id": 8,
                "items": []
              },
              {
                "comment": "Print",
                "item_id": 9,
                "items": []
              },
            ]
          },
          {
            "comment": "Ticket validation workflow",
            "item_id": 52,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Scan barcode button",
                "item_id": 6,
                "items": [
                  {
                    "comment": "White background",
                    "item_id": 18,
                    "items": []
                  },
                  {
                    "comment": "Scan barcode",
                    "item_id": 19,
                    "items": []
                  },
                ]
              },
              {
                "comment": "Odoo Scan barcode button",
                "item_id": 20,
                "items": [
                  {
                    "comment": "White background",
                    "item_id": 18,
                    "items": []
                  },
                  {
                    "comment": "Odoo scan barcode",
                    "item_id": 21,
                    "items": []
                  },
                ]
              },
            ]
          },
          {
            "comment": "FMCU Action workflow",
            "item_id": 53,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Open FMCU",
                "item_id": 16,
                "items": []
              },
              {
                "comment": "Bad Open FMCU",
                "item_id": 17,
                "items": []
              },
            ]
          },
          {
            "comment": "Cash Payment workflow",
            "item_id": 40,
            "items": [
              {
                "comment": "Sale order button",
                "item_id": 25,
                "items": []
              },
              {
                "comment": "Cash Payment",
                "item_id": 41,
                "items": []
              },
            ]
          },
          {
            "comment": "Cash Payment Confirmation workflow",
            "item_id": 54,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
            ]
          },
          {
            "comment": "Cash Refund workflow",
            "item_id": 55,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Cash Refund",
                "item_id": 7,
                "items": []
              },
            ]
          },
          {
            "comment": "Cash Refund Confirmation workflow",
            "item_id": 56,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
            ]
          },
          {
            "comment": "Cash Payment Cancellation workflow",
            "item_id": 57,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
            ]
          },
          {
            "comment": "RFID Card workflow",
            "item_id": 58,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
            ]
          },
          {
            "comment": "Recipt Switcher workflow",
            "item_id": 59,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Recipt Switcher",
                "item_id": 42,
                "items": []
              },
            ]
          },
          {
            "comment": "PDF Viewer workflow",
            "item_id": 60,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "PDF Viewer",
                "item_id": 44,
                "items": []
              },
            ]
          },
          {
            "comment": "Image Viewer workflow",
            'item_id': 43,
            'items': [
              {
                "comment": "White background",
                'item_id': 18,
                'items': []
              },
              {
                "comment": "Image Viewer",
                'item_id': 45,
                'items': []
              },
            ]
          },
          {
            "comment": "Server Action workflow",
            "item_id": 61,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
            ]
          },
          {
            "comment": "Basket workflow",
            "item_id": 62,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Basket",
                "item_id": 47,
                "items": []
              },
            ]
          },
          {
            "comment": "CIOBoard Event workflow",
            "item_id": 63,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "CIOBoard Event",
                "item_id": 46,
                "items": []
              },
            ]
          },
          {
            "comment": "Enter Pin Code workflow",
            "item_id": 64,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Pin pad",
                "item_id": 76,
                "items": []
              },
            ]
          },
          {
            "comment": "Card Payment Category",
            "item_id": 67,
            "items": [
              {
                "comment": "Card Payment",
                "item_id": 68,
                "items": []
              },
            ]
          },
          {
            "comment": "Keypad Category",
            "item_id": 69,
            "items": [
              {
                "comment": "Keypad",
                "item_id": 70,
                "items": []
              },
              {
                "comment": "Input Text ",
                "item_id": 71,
                "items": []
              },
              {
                "comment": "Input Text2",
                "item_id": 72,
                "items": []
              },
              {
                "comment": "Server Action",
                "item_id": 73,
                "items": []
              },
            ]
          },
          {
            "comment": "Markdown Viewer workflow",
            "item_id": 74,
            "items": [
              {
                "comment": "White background",
                "item_id": 18,
                "items": []
              },
              {
                "comment": "Markdown Viewer",
                "item_id": 75,
                "items": []
              },
            ]
          },
          {
            "comment": "Custom Price Category",
            "item_id": 77,
            "items": [
              {
                "comment": "Ticket Custom Price",
                "item_id": 78,
                "items": []
              },
              {
                "comment": "Card Custom Price",
                "item_id": 79,
                "items": []
              },
            ]
          },
          {
            "comment": "Additional Product Category",
            "item_id": 80,
            "items": [
              {
                "comment": "Ticket with additional Product",
                "item_id": 81,
                "items": []
              },
            ]
          },
          {
            "comment": "Card Dispenser workflow",
            "item_id": 82,
            "items": [
              {
                "comment": "Card Dispenser component",
                "item_id": 83,
                "items": []
              },
            ]
          },
          {
            "comment": "Card Dispenser Info Category",
            "item_id": 85,
            "items": [
              {
                "comment": "Card Dispenser Info Widget",
                "item_id": 84,
              },
              {
                "comment": "Back to Main Page",
                "item_id": 86,
                "items": []
              },
            ],
          }
        ]
      },
      'displayConfigurationId': 1
    };
  }

  createDisplayConfiguration2() {
    return {
      "properties": {
        "name": "Main display",
        "items": [
          {
            "id": 1,
            "pos_x": 1,
            "pos_y": 1,
            "success_item_id": false,
            "description": "Singapore",
            "sequence": 0,
            "type": "category",
            "product_reference": false,
            "html_template": "\u003cdiv class=\"grid-cell-container-middle\"\u003e\n\u003cdiv class=\"grid-cell-container-inner\"\u003e@product_name\u003c/br\u003e\n\u003cspan style=\"color:black\"\u003e@price\u003c/span\u003e\n\u003c/div\u003e\n\u003c/div\u003e",
            "size_vertical": 1,
            "background": 1,
            "failure_item_id": false,
            "z_index": 0,
            "size_horizontal": 1,
            "category_reference": false,
            "name": "Singapore"
          },
          {
            "id": 2,
            "pos_x": 1,
            "pos_y": 0,
            "success_item_id": false,
            "description": "Ticket",
            "sequence": 0,
            "type": "product",
            "product_reference": 2,
            "html_template": "\u003cdiv class=\"grid-cell-container-middle\"\u003e\n\u003cdiv class=\"grid-cell-container-inner\"\u003e@product_name\u003c/br\u003e\n\u003cspan style=\"color:black\"\u003e@price\u003c/span\u003e\n\u003c/div\u003e\n\u003c/div\u003e",
            "size_vertical": 1,
            "background": false,
            "failure_item_id": false,
            "z_index": 0,
            "size_horizontal": 1,
            "category_reference": false,
            "name": "Ticket"
          },
          {
            "id": 3,
            "pos_x": 1,
            "pos_y": 2,
            "success_item_id": false,
            "description": "Bali",
            "sequence": 0,
            "type": "category",
            "product_reference": false,
            "html_template": "\u003cdiv class=\"grid-cell-container-middle\"\u003e\n\u003cdiv class=\"grid-cell-container-inner\"\u003e@product_name\u003c/br\u003e\n\u003cspan style=\"color:black\"\u003e@price\u003c/span\u003e\n\u003c/div\u003e\n\u003c/div\u003e",
            "size_vertical": 1,
            "background": 2,
            "failure_item_id": false,
            "z_index": 0,
            "size_horizontal": 1,
            "category_reference": false,
            "name": "Bali"
          },
          {
            "id": 4,
            "pos_x": 0,
            "pos_y": 0,
            "success_item_id": false,
            "description": "Yava ticket",
            "sequence": 0,
            "type": "product",
            "product_reference": 3,
            "html_template": "\u003cdiv class=\"grid-cell-container-middle\"\u003e\n\u003cdiv class=\"grid-cell-container-inner\"\u003e@product_name\u003c/br\u003e\n\u003cspan style=\"color:black\"\u003e@price\u003c/span\u003e\n\u003c/div\u003e\n\u003c/div\u003e",
            "size_vertical": 0,
            "background": false,
            "failure_item_id": false,
            "z_index": 0,
            "size_horizontal": 0,
            "category_reference": false,
            "name": "Yava ticket"
          },
          {
            "id": 5,
            "pos_x": 2,
            "pos_y": 2,
            "success_item_id": 6,
            "description": false,
            "sequence": 0,
            "type": "ticket",
            "product_reference": false,
            "html_template": false,
            "size_vertical": 1,
            "background": false,
            "failure_item_id": false,
            "z_index": 0,
            "size_horizontal": 1,
            "category_reference": false,
            "name": "Рекурсия 1"
          },
          {
            "id": 6,
            "pos_x": 1,
            "pos_y": 1,
            "success_item_id": 5,
            "description": false,
            "sequence": 1,
            "type": "ticket",
            "product_reference": false,
            "html_template": false,
            "size_vertical": 1,
            "background": false,
            "failure_item_id": false,
            "z_index": 0,
            "size_horizontal": 1,
            "category_reference": false,
            "name": "Рекурсия 2"
          }
        ],
        "columns_count": 3,
        "row_count": 3,
        "page_caption_type": "Automatic",
        "items_tree": [
          {
            "item_id": 1,
            "items": [
              {
                "item_id": 2,
                "items": []
              }
            ]
          },
          {
            "item_id": 3,
            "items": [
              {
                "item_id": 4,
                "items": []
              }
            ]
          },
          {
            "item_id": 5,
            "items": []
          }
        ]
      },
      "displayConfigurationId": 2
    };
  }


  get moneyBanknotesExchangeRules(): MoneyExchangeRule[] {
    return [
      new MoneyExchangeRule(
        new Money(10.00, 'EUR'),
        [
          new AcceptedCash(
            [
            ],
            [
              new Money(5.00, 'EUR'),
              new Money(5.00, 'EUR'),
            ]
          ),
          new AcceptedCash(
            [
              new Money(2.00, 'EUR'),
              new Money(2.00, 'EUR'),
              new Money(1.00, 'EUR'),
            ],
            [
              new Money(5.00, 'EUR'),
            ]
          ),
          new AcceptedCash(
            [
              new Money(2.00, 'EUR'),
              new Money(2.00, 'EUR'),
              new Money(2.00, 'EUR'),
              new Money(2.00, 'EUR'),
              new Money(2.00, 'EUR'),
            ],
            [
            ]
          )
        ]
      ),
      new MoneyExchangeRule(
        new Money(5.00, 'EUR'),
        [
          new AcceptedCash(
            [
              new Money(2.00, 'EUR'),
              new Money(2.00, 'EUR'),
              new Money(1.00, 'EUR'),
            ],
            [
            ]
          ),
          new AcceptedCash(
            [
              new Money(2.00, 'EUR'),
              new Money(1.00, 'EUR'),
              new Money(1.00, 'EUR'),
              new Money(1.00, 'EUR'),
            ],
            [
            ]
          ),
        ]
      )
    ];
  }

  get moneyCoinsExchangeRules(): MoneyExchangeRule[] {
    return [
      new MoneyExchangeRule(
        new Money(2.00, 'EUR'),
        [
          new AcceptedCash(
            [
              new Money(1.00, 'EUR'),
              new Money(1.00, 'EUR'),
            ],
            [
            ]
          ),
          new AcceptedCash(
            [
              new Money(1.00, 'EUR'),
              new Money(0.50, 'EUR'),
              new Money(0.50, 'EUR'),
            ],
            [
            ]
          )
        ]
      ),
      new MoneyExchangeRule(
        new Money(1.00, 'EUR'),
        [
          new AcceptedCash(
            [
              new Money(0.50, 'EUR'),
              new Money(0.50, 'EUR'),
            ],
            [
            ]
          ),
          new AcceptedCash(
            [
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
            ],
            [
            ]
          )
        ]
      ),
      new MoneyExchangeRule(
        new Money(0.50, 'EUR'),
        [
          new AcceptedCash(
            [
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
              new Money(0.10, 'EUR'),
            ],
            [
            ]
          )
        ]
      ),
      new MoneyExchangeRule(
        new Money(0.20, 'EUR'),
        [
          new AcceptedCash(null, null)
        ],
      ),
      new MoneyExchangeRule(
        new Money(0.10, 'EUR'),
        [
          new AcceptedCash([], [])
        ],
      ),
      new MoneyExchangeRule(
        new Money(0.02, 'EUR'),
        [],
      ),
      new MoneyExchangeRule(
        new Money(0.01, 'EUR'),
        null
      )
    ];
  }

}
