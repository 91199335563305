import { TouchTileType } from "./touch-tile-type";
import { TouchTileColor } from "./touch-tile-color";

export class TouchTileColorSet {
  constructor(
    public tiles: TouchTileType[] = new Array<TouchTileType>(),
    public color: string = "#ffffff",
  ) {
  }

  get array(): TouchTileColor[] {
    return this.tiles.map(x => new TouchTileColor(x, this.color));
  }
}
