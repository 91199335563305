import { Injectable, Injector } from "@angular/core";
import { VuCommunicationService } from "../vu/vu-communication.service";
import { IVuHttp } from "../vu/http/vu-http.interface";
import { TouchTileColor } from "../../lib/touch-tile/touch-tile-color";
import { TouchTileType } from "../../lib/touch-tile/touch-tile-type";
import { TouchTileSwitch } from "../../lib/touch-tile/touch-tile-switch";
import { TouchTileColorSet } from "../../lib/touch-tile/touch-tile-color-set";
import { Observable, of } from "rxjs";
import { DtoVuConfiguration } from "../../lib/lib";
import { AdditionalPropertiesConfigurationService } from "../configuration/additional-properties-configuration.service";
import { DispatcherService } from "../dispatcher.service";
import { RunningLightScenario } from "../../lib/touch-tile/running-light-scenario";

@Injectable()
export class TouchTileService {
  vuHttp: IVuHttp;
  additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService;

  private isManualMode: boolean;

  constructor(
    injector: Injector,
  ) {
    this.additionalPropertiesConfigurationService = injector.get(AdditionalPropertiesConfigurationService);
    const vuCommunicationService = injector.get(VuCommunicationService);
    this.vuHttp = vuCommunicationService.vuHttp;
  }

  enableTouchTiles(
    tilesToEnable: TouchTileType[],
    colors: TouchTileColorSet[],
    runningLightScenario: RunningLightScenario
  ) {

    const offTouchTileTypes = Object.keys(TouchTileType).map(key => TouchTileType[key]).filter(x => !tilesToEnable || tilesToEnable.indexOf(x) == -1);

    this.updateTouchTileState(
      tilesToEnable,
      offTouchTileTypes,
      colors,
      runningLightScenario,
    );
  }

  updateTouchTileState(
    on: TouchTileType[],
    off: TouchTileType[],
    colors: TouchTileColorSet[],
    runningLightScenario: RunningLightScenario,
  ) {
    this._swithTiles(on, off).subscribe(
      () => {
        this._changeColorTiles(colors).subscribe(
          () => {
            this._setRunningLight(runningLightScenario).subscribe();
          }
        );
      }
    );
  }

  private _swithTiles(
    on: TouchTileType[],
    off: TouchTileType[],
  ): Observable<boolean> {
    let switchSet = new Array<TouchTileSwitch>();
    if (on && on.length > 0)
      switchSet = switchSet.concat(on.map(x => new TouchTileSwitch(x, true)));
    if (off && off.length > 0)
      switchSet = switchSet.concat(off.map(x => new TouchTileSwitch(x, false)));

    if (switchSet.length == 0)
      return of(false);

    return this.vuHttp.changeTouchTileSwitch(switchSet);
  }

  private _changeColorTiles(colors: TouchTileColorSet[]): Observable<boolean> {
    if (!colors || colors.length == 0)
      return of(false);

    let colorsSet = new Array<TouchTileColor>();
    colors.forEach(element => {
      colorsSet = colorsSet.concat(element.array);
    });

    if (colorsSet.length == 0)
      return of(false);

    return this.vuHttp.changeTouchTileColor(colorsSet);
  }

  private _setRunningLight(runningLightScenario: RunningLightScenario): Observable<boolean> {
    if (!runningLightScenario)
      return of(false);

    return this.vuHttp.setRunningLight(runningLightScenario);
  }

  switchLntBoard(enabled: boolean) {
    this.vuHttp.switchLntBoard(enabled);
  }
}
