import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalSettings } from '../../../lib/global-settings';
import { AcceptedCash, CashType, Money, Order, PaymentSession } from '../../../lib/lib';
import { DispatcherService } from '../../../services/dispatcher.service';
import { DemoPaymentService } from '../../../services/payment/demo-payment.service';
import { UnrestrictedCashPaymentService } from '../../../services/payment/unrestricted-cash-payment.service';
import { SaleService } from '../../../services/sale.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';

@Component({
  selector: 'app-card-recharge',
  templateUrl: './card-recharge.component.html',
  styleUrls: ['./card-recharge.component.css']
})
export class CardRechargeComponent implements OnInit, OnDestroy {
  private _paymentSession: PaymentSession;
  acceptedCoins: Money[] = [];
  acceptedBanknotes: Money[] = [];
  acceptedCashSubscription: Subscription;
  private isBackButtonEnabled: boolean;
  private moneyChangedSubscription: Subscription;
  private bnaValidationStartedSubscription: Subscription;
  private bnaValidationFinishedSubscription: Subscription;
  private _isConfirmButtonEnabled: boolean = false;
  private validationInProgress: boolean;

  constructor(
    private dispatcherService: DispatcherService,
    private vuCommunicationService: VuCommunicationService,
    private demoPaymentService: DemoPaymentService,
    private saleService: SaleService,
    private unrestrictedCashPaymentService: UnrestrictedCashPaymentService,
  ) {
  }

  ngOnInit(): void {
    this.isBackButtonEnabled = this.dispatcherService.isBackButtonEnabled;
    this.dispatcherService.pageHeaderSet('Recharge Card');
    this.dispatcherService.backButtonTextSet('Cancel');
    this.acceptedCashSubscription = this.vuCommunicationService.vuHttp.getAcceptedCashWithoutRestrictions()
      .subscribe(
        (x: AcceptedCash) => {
          if (x) {
            this.acceptedCoins = x.coins;
            this.acceptedBanknotes = x.banknotes;
          }
        });
    this.moneyChangedSubscription = this.vuCommunicationService.vuConnection.eventMoneyChanged
      .subscribe(
        _ => this.onMoneyChanged()
      );
    this.bnaValidationStartedSubscription = this.vuCommunicationService.vuConnection.eventBnaValidationStarted
      .subscribe(
        _ => this.onBnaValidationStarted()
      );
    this.bnaValidationFinishedSubscription = this.vuCommunicationService.vuConnection.eventBnaValidationFinished
      .subscribe(
        _ => this.onBnaValidationFinished()
      );
    this._paymentSession = this.saleService.paymentSession;
  }

  ngOnDestroy(): void {
    this.dispatcherService.isBackButtonEnabled = this.isBackButtonEnabled;
    this.acceptedCashSubscription.unsubscribe();
    this.moneyChangedSubscription.unsubscribe();
    this.bnaValidationStartedSubscription.unsubscribe();
    this.bnaValidationFinishedSubscription.unsubscribe();
  }

  onMoneyChanged(): void {
    this.dispatcherService.isBackButtonEnabled = false;
    this._isConfirmButtonEnabled = !this.validationInProgress;
    this.dispatcherService.onUserActivity();
  }

  onBnaValidationStarted(): void {
    this.dispatcherService.isBackButtonEnabled = false;
    this.validationInProgress = true;
    this._isConfirmButtonEnabled = false;
    this.dispatcherService.onUserActivity();
  }

  onBnaValidationFinished(): void {
    this.validationInProgress = false;
    this._isConfirmButtonEnabled = true;
  }

  get amountPaidMoney(): Money {
    return this._paymentSession ? this._paymentSession.amountPaidIn : new Money(0, 'EUR');
  }

  get amountPaid(): string {
    return this.amountPaidMoney.toStringCurrencySign();
  }

  get currencyCode(): string {
    return GlobalSettings.getCurrencyCode();
  }

  get cashTypeCoin(): CashType {
    return CashType.Coin;
  }

  get cashTypeBanknote(): CashType {
    return CashType.Banknote;
  }

  onDenominationClick(): void {
    this.demoPaymentService.onDenominationClick();
  }

  get isConfirmButtonEnabled(): boolean {
    return this._isConfirmButtonEnabled && this.amountPaidMoney.isPositive;
  }

  onConfirmClick(): void {
    if (this.isConfirmButtonEnabled) {
      this.dispatcherService.confirmPayment();
    }
  }

  get newBalance(): string {
    let cardBalance = 0;
    let currencyCode = 'EUR';
    const model = this.unrestrictedCashPaymentService.cardRechargeModel;
    if (model) {
      cardBalance = model.cardBalance;
      if (model.product && model.product.price) {
        currencyCode = model.product.price.currencyCode;
      }
    }

    const newBalance = new Money(cardBalance + this.amountPaidMoney.value, currencyCode);

    return newBalance.toStringCurrencySign();
  }

  get personalNumber(): string {
    const model = this.unrestrictedCashPaymentService.cardRechargeModel;
    return model ? model.personalNumber : '';
  }
}
