import { WorkflowStepType } from '../../lib/lib';
import { MachineBaseService } from './machine-base.service';
import {
  Money,
  PaymentSession,
  OrderSaveResult,
  WorkflowStepState,
  TeaserType,
  PrintTaskType,
  PrintTask,
  PrintTaskResult,
  MachineInactivitySettings,
  PaymentMethod,
} from '../../lib/lib';
import { SaleService } from '../sale.service';
import { Message, MessageType } from '../message.service';
import { Injectable } from '@angular/core';
import { CardDispenserService } from '../card-dispenser/card-dispenser.service';
import { PrintService } from '../print/print.service';
import { CardDispenserCompleteActionResult } from 'src/app/lib/rfid-card/card-dispenser-complete-action-result';
import { RfidCardData } from 'src/app/lib/card-dispenser/rfid-card-data';

@Injectable()
export class MachinePayoutService extends MachineBaseService {
  private saleService: SaleService;
  private paymentSession: PaymentSession;
  private cardDispenserService: CardDispenserService;
  private printService: PrintService;
  disableNavigation = false;
  externalPayment = false;
  paymentFailed = false;
  private rfidCardData: RfidCardData;

  init(): void {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.vuConnection.eventOrderSaveResultReceived.subscribe((x: OrderSaveResult) => this.onOrderSaveResult(x));
    this.vuConnection.eventReturnAmountFinished.subscribe(() => this.onEventReturnAmountFinished());
    this.vuConnection.eventPrintTaskResultReceived.subscribe((x: PrintTaskResult) => this.onPrintTaskResultReceived(x));
    this.cardDispenserService = this.injector.get(CardDispenserService);
    this.printService = this.injector.get(PrintService);
    this.cardDispenserService.writeCardDataCompleted.subscribe((x: CardDispenserCompleteActionResult) => this.onWriteCardDataCompleted(x));
  }

  get machineName(): string {
    return 'Payout Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toFlowBegin', from: ['off'], to: 'flowBegin' },
      { name: 'toSaveOrder', from: ['flowBegin'], to: 'saveOrder' },
      { name: 'toPrintReceipt', from: ['saveOrder'], to: 'printReceipt' },
      { name: 'toWriteRfidCard', from: ['saveOrder', 'printReceipt'], to: 'writeRfidCard' },
      { name: 'toPayout', from: ['printReceipt', 'saveOrder', 'writeRfidCard'], to: 'payout' },
      { name: 'toFlowEnd', from: ['*'], to: 'flowEnd' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff(event: any, isHardReset: false): void {
        scope.paymentSession = null;
        scope.saleService.closePaymentSession();
        scope.dispatcherService.isBackButtonEnabled = true;
        scope.disableNavigation = false;
        scope.dispatcherService.workflowReset('', WorkflowStepType.None);
        scope.rfidCardData = null;
      },
      onToFlowBegin(): void {
        if (!scope.disableNavigation) {
          scope.dispatcherService.isBackButtonEnabled = false;
        }
        scope.paymentFailed = false;
        scope.paymentSession = scope.saleService.paymentSession;
        scope.dispatcherService.teaserReset();
        scope.log.info(`MachinePayoutService. onToFlowBegin. ${scope.paymentSession}`);

        scope.doAsync(() => {
          if (!scope.disableNavigation) {
            scope.router.navigateByUrl('/workflow');
          }
          scope.machine.toSaveOrder();
        }, 'onToFlowBegin');
      },
      onToSaveOrder(): void {
        scope.dispatcherService.workflowReset('Payout', WorkflowStepType.SaveOrder);
        const order = scope.saleService.order;
        scope.vuHttp.saveOrder(order).catch(reason => {
          scope.onOrderSaveResult(new OrderSaveResult(0,
            scope.saleService.order.uid, false, reason));
        });
      },
      onToPrintReceipt(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.ReceiptPrinting);
        scope.printService.printOrderReceipt(scope.saleService.order.id);
      },
      onToWriteRfidCard(): void {
        if (scope.rfidCardData) {
          scope.captureCardIfNeed();
          scope.cardDispenserService.writeRfidCardData(scope.rfidCardData);
        } else {
          scope.doAsync(() => {
            scope.machine.toPayout();
          }, 'onToWriteRfidCard');
        }
      },
      onToPayout(): void {
        scope.doAsync(() => {
          if (scope.saleService.order.amountTotal.isZero) {
            scope.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteSuccess);
            scope.machine.toFlowEnd();
            return;
          }
          scope.saleService.openPaymentSession(PaymentMethod.Cash);
          const amountToPayout = scope.saleService.order.amountTotal.absolute();
          scope.paymentSession.amountTempToPayOut = amountToPayout;
          scope.dispatcherService.workflowAddStep(WorkflowStepType.PaymentReturn,
            ...scope.getPayoutArgs());

          const amountPaidOut = scope.paymentSession.amountPaidOut;
          scope.dispatcherService.cashDevicesStatePayOut(true);
          scope.vuHttp.returnAmount(amountToPayout).catch(x => {
            scope.onStepFailed();
          });
        }, 'onToPayout');
      },
      onToFlowEnd(): void {
        if (!scope.disableNavigation && !scope.externalPayment) {
          scope.dispatcherService.isBackButtonEnabled = true;
        } else {
          scope.doAsync(() => scope.machine.toOff(), 'onToFlowEnd. toOff');
        }
      },
    });
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
        if (state === 'flowEnd') {
          this.machine.toOff();
        }
        break;
      default:
        break;
    }
    return false;
  }

  machineStart(): void {
    if (this.isOff) {
      this.machine.toFlowBegin();
    }
  }

  private onMoneyChanged(money: Money): void {
    switch (this.state) {
      case 'payout':
        this.dispatcherService.workflowLastStepUpdate(... this.getPayoutArgs());
        const m = `payoutTotal: ${this.paymentSession.amountTempToPayOut}. amountChange: ${this.paymentSession.amountTempPaidOut}`;
        this.log.info(`MachinePayoutService. ${m}`);
        this.checkAmountPaidOut();
        break;
      default:
        break;
    }
  }

  private checkAmountPaidOut(): void {
    if (this.paymentSession.amountPaidOut.isPositive) {
      this.dispatcherService.teaserAddItem(TeaserType.TakeMoney);
    }
  }

  private onOrderSaveResult(result: OrderSaveResult): void {
    if (this.state !== 'saveOrder') {
      return;
    }
    result = OrderSaveResult.fromOther(result);
    this.saleService.order.id = result.orderId;
    const m = `MachinePayoutService. onOrderSaveResult: ${result}`;
    if (result.isSuccess) {
      this.log.info(m);
    } else {
      this.log.error(m);
    }
    const order = this.saleService.order;
    if (result.isSuccess
      && result.orderUid === order.uid
      && result.orderId != null) {
      this.rfidCardData = result.rfidCardData;

      if (this.rfidCardData == null) {
        this.captureCardIfNeed();
      }

      this.dispatcherService.workflowLastStepStateSet();
      if (order.isReceipt) {
        this.machine.toPrintReceipt();
      } else {
        this.machine.toWriteRfidCard();
      }
    } else {
      this.onStepFailed();
    }
  }

  protected get timeoutTrackingStates(): string[] {
    return this.getsAllStatesExceptFor('off');
  }

  private getPayoutArgs(): string[] {
    try {
      const result = [
        this.paymentSession.amountTempToPayOut.toStringCurrencySign(),
        this.paymentSession.amountTempPaidOut.toStringCurrencySign()
      ];
      return result;
    } catch (e) {
      this.log.error(`Payout Machine. getPayoutArgs. ${this.paymentSession}`);
    }
    return ['?', '?'];
  }

  private onStepFailed(): void {
    this.paymentFailed = true;
    this.machine.toFlowEnd();
    this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
  }

  private onEventReturnAmountFinished(): void {
    const scope = this;
    setTimeout(() => {
      scope.onEventReturnAmountFinishedWithDelay();
    }, 1000); // to avoid the racing with the last eventMoneyChanged event
  }

  private onEventReturnAmountFinishedWithDelay(): void {
    this.log.info(`Payout Machine. onEventReturnAmountFinished`);

    if (!this.isInState('payout')) {
      this.log.warning(`Payout Machine. onEventReturnAmountFinished but current state ${this.state}`);
      return;
    }

    this.dispatcherService.cashDevicesStatePayOut(false);
    const isSuccess = this.paymentSession.amountPaidOut >= this.paymentSession.amountTempToPayOut;
    this.dispatcherService.workflowLastStepStateSet(isSuccess ? WorkflowStepState.CompleteSuccess : WorkflowStepState.CompleteFailure);
    this.machine.toFlowEnd();
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'flowEnd':
        return new MachineInactivitySettings(10000, true);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }

  private onPrintTaskResultReceived(result: PrintTaskResult): void {
    if (this.state !== 'printReceipt') {
      return;
    }
    const printTaskType = result.printTask.printTaskType;
    if (printTaskType === PrintTaskType.Receipt) {
      this.log.info(`MachinePayoutService. onPrintTaskResultReceived. result: ${result}`);
      this.dispatcherService.teaserAddItem(TeaserType.TakeReceipt);
      const step = result ? WorkflowStepState.CompleteSuccess : WorkflowStepState.CompleteFailure;
      this.dispatcherService.workflowLastStepStateSet(step);
      this.machine.toWriteRfidCard();
    } else {
      this.log.error(`onPrintTaskResultReceived. Unsupported PrintTaskType: ${printTaskType}`);
    }
  }

  private onWriteCardDataCompleted(result: CardDispenserCompleteActionResult): void {
    if (this.state !== 'writeRfidCard') {
      return;
    }
    const writeCardDataCompleted = result && !result.isFailed;

    this.log.info(`MachinePayoutService. onWriteCardDataCompleted. result: ${result}`);

    if (!writeCardDataCompleted) {
      this.vuHttp.resetOrderRfidCardAccessData(this.saleService.order.id, result.barcode);
      return;
    }
    const step = result ? WorkflowStepState.CompleteSuccess : WorkflowStepState.CompleteFailure;
    this.dispatcherService.workflowLastStepStateSet(step);
    this.machine.toPayout();
  }

  private captureCardIfNeed(): void {
    if (this.cardDispenserService.canCaptureCard) {
      this.cardDispenserService.captureCard();
      this.cardDispenserService.stopTransaction();
    }
  }
}
