<div class="teaser-spinner" *ngIf="teaser.items.length == 0">
  <div>
      <mat-progress-spinner [mode]="'indeterminate'">
        </mat-progress-spinner>
  </div>
</div>
<div class="teaser normal-text" *ngIf="teaser.items.length > 0">
  <div class="teaser-normal" [ngClass]="cssClass">
    <span *ngFor="let x of teaser.items">{{ x | translate }}</span>
  </div>
</div>
