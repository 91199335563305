import { Component, OnInit } from '@angular/core';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { IVuConnectionSimulator } from '../../../services/vu/connection/vu-connection.interfaces';
import { RfidCardReaderSimulatorService } from '../../../services/rfid-card/rfid-card-reader-simulator.service';
import { Guid } from '../../../lib/lib';

@Component({
  selector: 'app-rfid-simulator',
  templateUrl: './rfid-simulator.component.html',
  styleUrls: ['./rfid-simulator.component.css']
})
export class RfidSimulatorComponent implements OnInit {

  vuSimulator: IVuConnectionSimulator;
  rfidCardCode: string;

  constructor(
    private vuCommunicationService: VuCommunicationService,
    private rfidCardReaderSimulatorService: RfidCardReaderSimulatorService,
  ) {
    this.vuSimulator = this.vuCommunicationService.vuConnectionSimulator;
  }

  ngOnInit() {
  }

  onInvalidRfidClick() {
    this.vuSimulator.onInvalidRfidCardInserted();
  }

  onScanRandomRfidCardIdClick() {
    this.rfidCardReaderSimulatorService.rfidCardRead(Guid.newGuid());
  }

  get rfidCardCodes(): Array<string> {
    return [
      "%AAF562DA12_",
      "%AAF562DA13_",
      "%AAF562DA14_",
    ];
  }

  onScanRfidCardClick() {
    if(!this.rfidCardCode) {
      return;
    }

    this.rfidCardReaderSimulatorService.rfidCardRead(this.rfidCardCode);
  }
}
