import { Injectable } from '@angular/core';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { CardDispenserService } from '../../../../card-dispenser/card-dispenser.service';

@Injectable()
export class CardDispenserBaseGridWorkflowService extends BaseGridWorkflowService {

  protected cardDispenserService: CardDispenserService;

  init(
  ) {
    super.init();
    this.cardDispenserService = this.injector.get(CardDispenserService);
  }

  showCardDispenserNotAvailableAndBackToParentPage() {
    this.showCardDispenserNotAvailable();
    this.navigateBack();
  }

  showCardDispenserNotAvailable() {
    this.showErrorMessage('Card Dispenser failure occurred', null);
  }

  showCardDispenserNotReadSectorData(): void {
    this.showErrorMessage('Can`t read the sector data on the rfid card', null);
  }

}
