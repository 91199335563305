export class CovidCheckResult {
  valid: boolean;
  testRequired: boolean;

  static createFromAny(data: any): CovidCheckResult {
    if (data == null) {
      return null;
    }
    const result = new CovidCheckResult();
    result.valid = data.valid;
    result.testRequired = data.test_required;
    return result;
  }
}
