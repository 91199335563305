<div class="outer-container">
  <div class="inner-container">
    <div class="centered-content">
      <span *ngFor="let header of (( headerLines | async).text);  let index = index">
        <span *ngIf="index!==0"> > </span>
        <span [innerHTML]="header | translate"></span>
      </span>
    </div>
  </div>
</div>
