import { WorkflowStepType } from './workflow-step-type';
import { WorkflowStepState } from './workflow-step-state';
import { GlobalSettings } from '../lib';

export class WorkflowStep {
  type: WorkflowStepType;
  state = WorkflowStepState.Wait;
  args: string[];

  constructor(type: WorkflowStepType, ...args: string[]) {
    this.type = type;
    this.args = args;
  }

  get text(): string {
    return this.toString();
  }

  toString() {
    let translatedString = GlobalSettings.translate(this.type.toString());
    if (!this.args) {
      return translatedString;
    }

    for (let i = 0; i < this.args.length; i++) {
      const translatedArgs = GlobalSettings.translate(this.args[i]);
      translatedString = translatedString.replace(`{${i}}`, translatedArgs);
    }
    return translatedString;
  }
}
