import { PaymentMethod } from './payment-method';

export class CashlessPaymentData {
  paymentMethod: PaymentMethod;
  data: any;

  constructor(
    paymentMethod: PaymentMethod,
    data: any = null,
  ) {
    this.paymentMethod = paymentMethod;
    this.data = data;
  }
}
