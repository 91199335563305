import { DtoOrderLine } from './dto-order-line';
import { Money, Order, Utilities } from '../lib';
import { OrderType } from './order-type';

export class DtoOrder {
  id: number;
  uid: string;
  orderLines: DtoOrderLine[];
  isReceipt = false;
  amountTotal: Money;
  amountReceived: Money;
  amountReturned: Money;
  paymentMethod: number;
  barcode: string;
  type: string;
  originalOrderId: number;
  giftCardEan13: string;
  giftCardName: string;
  giftCardPartialPaymentAmount: number;
  properties: Map<string, object>;


  static fromOrder(order: Order): DtoOrder {
    const r = new DtoOrder();
    r.id = order.id;
    r.uid = order.uid;
    r.isReceipt = order.isReceipt;
    r.amountTotal = order.amountTotal;
    r.amountReceived = order.type === OrderType.SaleRefund ||
      order.amountTotal && order.amountTotal.isNegative ? order.amountTotal : order.amountReceived;
    r.amountReturned = order.type === OrderType.SaleRefund ? Money.empty : order.amountReturned;
    r.orderLines = order.orderLines.filter(x => x.quantity !== 0).map(x => DtoOrderLine.fromOrderLine(x));
    r.paymentMethod = order.paymentMethod;
    r.barcode = order.barcode;
    r.type = order.type;
    r.originalOrderId = order.originalOrderId;
    r.properties = Utilities.strMapToObj(order.properties);
    r.giftCardEan13 = order.giftCardEan13;
    r.giftCardName = order.giftCardName;
    r.giftCardPartialPaymentAmount = order.giftCardPartialPaymentAmount;
    return r;
  }
}
