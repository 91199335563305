import { NgModule } from '@angular/core';
import { LoadingSpinnerService } from '../../services/loading-spinner/loading-spinner.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
  ],
  providers: [
    LoadingSpinnerService,
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
  ],
  exports: [
    NgxSpinnerModule,
  ]

})
export class LoadingSpinnerModule { }
