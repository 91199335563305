import { Workflow, WorkflowStepType } from '../lib/lib';
import { PayloadAction } from '../lib/payload-action';

const initialState = new Workflow('Not set', WorkflowStepType.None);

export class WorkflowActionType {
  static readonly WORKFLOW_RESET = 'WorkflowReset';
  static readonly WORKFLOW_ADD_STEP = 'WorkflowAddStep';
  static readonly WORKFLOW_DELETE_LAST_STEP = 'WorkflowDeleteLastStep';
  static readonly WORKFLOW_LAST_STEP_STATE_SET = 'WorkflowLastStepStateSet';
  static readonly WORKFLOW_LAST_STEP_UPDATE = 'WorkflowLastStepUpdate';
  static readonly WORKFLOW_ADD_ADDITIONAL_MESSAGES = 'WorkflowAddAdditionalMessages';
  static readonly WORKFLOW_DELETE_ADDITIONAL_MESSAGES = 'WorkflowDeleteAdditionalMessages';
}

export function workflowReducer(state = initialState, action: PayloadAction): Workflow {
  let isChanged = true;
  switch (action.type) {
    case WorkflowActionType.WORKFLOW_RESET:
      state = action.payload;
      break;
    case WorkflowActionType.WORKFLOW_ADD_STEP:
      state.addStep(action.payload);
      break;
    case WorkflowActionType.WORKFLOW_DELETE_LAST_STEP:
      state.deleteLastStep();
      break;
    case WorkflowActionType.WORKFLOW_LAST_STEP_STATE_SET:
      state.lastStepState = action.payload;
      break;
    case WorkflowActionType.WORKFLOW_LAST_STEP_UPDATE:
      const array: string[] = action.payload;
      state.lastStepUpdateArgs(...array);
      break;
    case WorkflowActionType.WORKFLOW_ADD_ADDITIONAL_MESSAGES:
      state.addAdditionalMessages(action.payload);
      break;
    case WorkflowActionType.WORKFLOW_DELETE_ADDITIONAL_MESSAGES:
      state.deleteAdditionalMessages();
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? Workflow.fromOther(state) : state;
}
