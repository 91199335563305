import { Component } from '@angular/core';
import { IVuConnectionSimulator } from '../../../services/vu/connection/vu-connection.interfaces';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { LogisticsRequestSimulatorService } from '../../../services/logistics-request/logistics-request-simulator.service';

@Component({
  moduleId: module.id,
  selector: 'app-gate-simulator',
  templateUrl: 'gate-simulator.component.html',
  styleUrls: ['gate-simulator.component.scss']
})
export class GateSimulatorComponent {
  vuSimulator: IVuConnectionSimulator;

  constructor(
    private vuCommunicationService: VuCommunicationService,
    private logisticsRequestSimulatorService: LogisticsRequestSimulatorService,
  ) {
    this.vuSimulator = this.vuCommunicationService.vuConnectionSimulator;
  }


  readBarodeClick(x: boolean) {
    this.vuCommunicationService.vuConnectionSimulator.onGateTransactionBegin(x);
  }

  enteredClick(x: boolean, barcode: string = "87654321") {
    this.logisticsRequestSimulatorService.updateLogisticsRequestTicket(barcode);
    this.vuCommunicationService.vuConnectionSimulator.onGateTransactionEnd(x);
  }
}
