import { ILog } from '../services/logging/log.interface';

export const doAsync = (f: any, log: ILog) => {
  setTimeout(() => {
    doSync(f, `async. ${f}`, log);
  }, 1);
};

export const doSync = (f: any, contextMessage: string, log: ILog) => {
  try {
    f();
  } catch (e) {
    log.error(`do. ${contextMessage}. ${e.message}`);
  }
};
