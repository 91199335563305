import { BaseButton } from './base-button';

export class AbortButton extends BaseButton {

  static fromOther(other: AbortButton): AbortButton {
    return Object.assign(new AbortButton(), other);
  }

  resetText(): void {
    this.text = 'Abort';
  }
}
