export * from './vu.reducer';
export * from './page-footer.reducer';
export * from './page-header.reducer';
export * from './simulator.reducer';
export * from './visual-item/visual-item-info.reducer'
export * from './workflow.reducer';
export * from './back-button.reducer';
export * from './shop.reducer';
export * from './teaser.reducer';
export * from './ticket.reducer';
export * from './ticket-use.reducer';
export * from './configuration.reducer';
export * from './cash-devices-state.reducer';
export * from './message-view.reducer';
export * from './abort-button.reducer';

import {
  vuReducer,
  pageHeaderReducer,
  simulatorReducer,
  visualItemReducer,
  workflowReducer,
  backButtonStateReducer,
  shopReducer,
  teaserReducer,
  ticketReducer,
  configurationReducer,
  cashDevicesStateReducer,
  messageViewReducer,
  ticketUseInfoReducer,
  pageFooterReducer,
  abortButtonStateReducer,
} from './reducers';

const reducers = {
  vuState: vuReducer,
  pageHeader: pageHeaderReducer,
  simulatorState: simulatorReducer,
  visualItem: visualItemReducer,
  workflow: workflowReducer,
  backButtonState: backButtonStateReducer,
  shopState: shopReducer,
  teaser: teaserReducer,
  ticket: ticketReducer,
  ticketUseInfo: ticketUseInfoReducer,
  configuration: configurationReducer,
  cashDevicesState: cashDevicesStateReducer,
  messageView: messageViewReducer,
  pageFooter: pageFooterReducer,
  abortButtonState: abortButtonStateReducer,
};

export default reducers;
