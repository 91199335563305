import { Component } from '@angular/core';
import { DavCard } from '../../../../lib/kletterzentrum/dav-card';
import { BaseScanCardModalComponent } from '../../../modal/base-scan-card-modal/base-scan-card-modal.component';
import { Observable, of } from 'rxjs';


@Component({
  selector: 'app-category-scan-card-modal',
  templateUrl: '../../../modal/base-scan-card-modal/base-scan-card-modal.component.html',
  styleUrls: ['../../../modal/base-scan-card-modal/base-scan-card-modal.component.css']
})

export class CategoryScanCardModalComponent extends BaseScanCardModalComponent {

  get isIllerissenMember(): boolean {
    return this.internalData.isIllerissenMember;
  }

  set isIllerissenMember(value: boolean) {
    this.internalData.isIllerissenMember = value;
  }

  barcodeValidation(barcode: string): Observable<boolean> {

    var davCard = new DavCard(barcode);
    if (!davCard.isDavCard) {
      this.showMessage('It is not a DAV card.', false);
      return of(false);
    }

    if (davCard.memberAge < 6) {
      this.showMessage('You don`t need a ticket. Pass is free.', false);
      return of(false);
    }

    if (this.isIllerissenMember && !davCard.isSectionIllertissenMember) {
      this.showMessage('The DAV card can`t be accepted. Please use another card.', false);
      return of(false);
    }

    return of(true);
  }

  get cardSuccesfulyScandMeessage(): string {
    return 'The DAV card was successfully scanned.';
  }
}
