<div class="modal-header">
  <div class="modal-title normal-text center">{{ 'Print' | translate }}</div>
</div>
<div class="modal-body center">
  <div *ngIf="!printError" class="normal-text">{{ 'Please, wait when printing end' | translate }}</div>
  <div *ngIf="printError" class="normal-text">{{ 'Print error' | translate }}</div>
  <button *ngIf="printError" class="btn button-base btn-item" (click)=close() type="button">
    {{ 'Close' | translate }}
  </button>
</div>
