import * as moment from 'moment';
import { Ticket } from '../ticket/ticket';

export class DavCard {

  static readonly pattern = /^(\d{3})(\d{2})(\d{6})(\d{8})$/;

  private _cardNumber: string;
  private _isDavCard: boolean
  private _sectionNo: string;
  private _localGroupNo: string;
  private _memberNo: string;
  private _hasValidTicket: boolean;
  private _relatedTicketProductId: number;

  constructor(cardNumber: string) {
    this.cardNumber = cardNumber;
  }

  private _parse() {
    this._isDavCard = DavCard.isDavCard(this._cardNumber);
    if (!this._isDavCard) {
      return;
    }

    var match = DavCard.pattern.exec(this._cardNumber);
    if (match.length < 4) {
      return;
    }

    this._sectionNo = match[1];
    this._localGroupNo = match[2];
    this._memberNo = match[3];
  }

  get isDavCard(): boolean {
    return this._isDavCard;
  }

  get sectionNo(): string {
    return this._sectionNo;
  }

  get localGroupNo(): string {
    return this._localGroupNo;
  }

  get memberNo(): string {
    return this._memberNo;
  }

  get isSectionIllertissenMember(): boolean {
    return this._sectionNo == '124';
  }

  static isDavCard(cardNumber: string): boolean {
    return this.pattern.test(cardNumber);
  }

  set hasValidTicket(hasValidTicket: boolean) {
    this._hasValidTicket = hasValidTicket;
  }

  get hasValidTicket(): boolean {
    return this._hasValidTicket;
  }

  set cardNumber(cardNumber: string) {
    this._cardNumber = cardNumber;
    this._parse();
  }

  get cardNumber(): string {
    return this._cardNumber;
  }

  get memberAge(): number {
    var match = DavCard.pattern.exec(this._cardNumber);
    let memberBirthday = moment(match[4], "DDMMYYYY");
    let memberAge = moment().diff(memberBirthday, 'years')

    return isNaN(memberAge) ? null : memberAge;
  }

  get relatedTicketProductId(): number {
    return this._relatedTicketProductId;
  }

  set relatedTicketProductId(id: number) {
    this._relatedTicketProductId = id;
  }
}
