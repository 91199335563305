import { MachineInactivitySettings, Money, WorkflowStepType, TeaserType, AcceptedCash, PrintTask, PrintTaskType, PrintTaskResult, WorkflowStepState, } from '../../lib/lib';
import { MachineBaseService } from './machine-base.service';
import { Message, MessageType } from '../message.service';
import { MoneyExchangeStateService } from '../money-exchange/money-exchange-state.service';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MachineMoneyExchangeService extends MachineBaseService {

  private moneyExchangeStateService: MoneyExchangeStateService;
  private _transactionIsStarted = false;
  private _cancelByTimer = false;

  init(): void {
    super.init();
    this.moneyExchangeStateService = this.injector.get(MoneyExchangeStateService);
    this.moneyExchangeStateService.eventMoneyPayin.subscribe(money => this.onMoneyPayin(money));
    this.moneyExchangeStateService.eventMoneyPayout.subscribe(money => this.onMoneyPayout(money));
    this.moneyExchangeStateService.eventPayoutMoneyStart.subscribe(variant => this.onPayoutMoneyStart(variant));
    this.moneyExchangeStateService.eventReadyToMoneyExchange.subscribe(_ => this.onReadyToMoneyExchange());
    this.moneyExchangeStateService.eventMoneyExchangePayoutFinish.subscribe(_ => this.onMoneyExchangePayoutFinish());
    this.vuConnection.eventPrintTaskResultReceived.subscribe((x: PrintTaskResult) => this.onPrintTaskResultReceived(x));
  }

  get machineName(): string {
    return 'Money Exchange Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toAcceptingCashInfo', from: ['off', 'payout'], to: 'acceptingCashInfo' },
      { name: 'toWaitingReadyToMoneyExchange', from: ['acceptingCashInfo'], to: 'waitingReadyToMoneyExchange' },
      { name: 'toRules', from: ['waitingReadyToMoneyExchange'], to: 'rules' },
      { name: 'toPayout', from: ['waitingReadyToMoneyExchange', 'rules'], to: 'payout' },
      { name: 'toPrintIssueReceipt', from: ['payout'], to: 'printIssueReceipt' },
      { name: 'toFlowEnd', from: ['payout', 'printIssueReceipt'], to: 'flowEnd' },
    );
  }

  protected getMethods(): any {
    return super.getMethods({
      onToAcceptingCashInfo: () => {
        this._cancelByTimer = false;
        this.dispatcherService.workflowReset('', WorkflowStepType.None);
        this.dispatcherService.isBackButtonEnabled = true;
        this.moneyExchangeStateService.initialize()
          .pipe(
            switchMap(_ => this.moneyExchangeStateService.isTransactionExist()),
            switchMap(
              result => {
                if (result) {
                  return this.moneyExchangeStateService.commitTransaction();
                } else {
                  return of(true);
                }
              }),
            switchMap(_ => this._beginTransaction()),
          )
          .subscribe();
        this.router.navigateByUrl('/accepting-cash-info');
      },
      onToWaitingReadyToMoneyExchange: () => {
        this.dispatcherService.isBackButtonEnabled = false;
        this.dispatcherService.workflowReset('Money Exchange', WorkflowStepType.None);
        this.dispatcherService.teaserReset();
        this.dispatcherService.workflowLastStepStateSet();
        this.dispatcherService.workflowAddStep(WorkflowStepType.Wait);
        this.router.navigateByUrl('/exchange-payout');
      },
      onToRules: () => {
        this.dispatcherService.isBackButtonEnabled = true;
        this.router.navigateByUrl('/exchange-rules');
      },
      onToPayout: () => {
        this.dispatcherService.isBackButtonEnabled = false;
        this.dispatcherService.workflowLastStepStateSet();
        this.dispatcherService.workflowAddStep(
          WorkflowStepType.PaymentReturn,
          this.moneyExchangeStateService.insertedMoney.toStringCurrencySign(),
          this.moneyExchangeStateService.returnedAmount.toStringCurrencySign());
        this.router.navigateByUrl('/exchange-payout');
      },
      onToPrintIssueReceipt: (event: any, data: any) => {
        if (data && data.issueId) {
          this.dispatcherService.workflowAddStep(WorkflowStepType.ReceiptPrinting);
          this.vuHttp.print(new PrintTask(0, PrintTaskType.IssueReceipt, JSON.stringify({issueId: data.issueId})));
          return;
        }
        this.doAsync(() => {
          this.machine.toFlowEnd();
        }, 'onToFlowEnd');
      },
      onToFlowEnd: () => {
        this._cancelByTimer = false;
        this.dispatcherService.workflowLastStepStateSet();
        this.dispatcherService.teaserAddItem(TeaserType.TakeMoney);
        this.closeRemoteTransaction();
        this.dispatcherService.isBackButtonEnabled = true;
      },
      onToOff: (event: any, isHardReset: false) => {
        this.dispatcherService.workflowReset('', WorkflowStepType.None);
      },
    });
  }

  machineStart(withoutTransition = false): void {
    this.machine.toAcceptingCashInfo();
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
        if (this.isInState('rules')) {
          this.cancelSessionAndReturnMoney();
          break;
        }
        if (this.isInState('payout')) {
          if (this.moneyExchangeStateService.isReturnMoney) {
            this.machine.toAcceptingCashInfo();
            break;
          }
        }

        this.closeRemoteTransaction();
        this.machine.toOff();
        break;
      default:
        break;
    }
    return false;
  }

  private onMoneyPayin(money: Money): void {
    if (this.isInState('acceptingCashInfo')) {
      this.dispatcherService.onUserActivity();
      this.machine.toWaitingReadyToMoneyExchange();
    }
  }

  private onMoneyPayout(money: Money): void {
    if (!this.isInState('payout')) {
      return;
    }

    this.dispatcherService.onUserActivity();

    if (this.moneyExchangeStateService.isReturnMoney) {
      this.dispatcherService.workflowLastStepUpdate(
        this.moneyExchangeStateService.insertedMoney.toStringCurrencySign(),
        money.toStringCurrencySign()
      );

      this.dispatcherService.workflowLastStepStateSet();
      this.dispatcherService.teaserAddItem(TeaserType.TakeMoney);

      this.dispatcherService.backButtonTextReset();
      this.dispatcherService.isBackButtonVisible = true;
      this.dispatcherService.isBackButtonEnabled = true;

      if (this._cancelByTimer) {
        this.machine.toFlowEnd();
      }
      return;
    }

    this.dispatcherService.workflowLastStepUpdate(
      this.moneyExchangeStateService.insertedMoney.toStringCurrencySign(),
      this.moneyExchangeStateService.returnedAmount.toStringCurrencySign()
    );
  }

  private onReadyToMoneyExchange(): void {
    if (!this.isInState('waitingReadyToMoneyExchange')) {
      return;
    }

    const variants = this.moneyExchangeStateService.variants;
    if (variants) {
      if (variants.length === 1) {
        this.moneyExchangeStateService.payoutMoney(variants[0]).subscribe();
      }
      if (variants.length > 1) {
        this.machine.toRules();
      }
    }
  }

  private onMoneyExchangePayoutFinish() {
    console.log("onMoneyExchangePayoutFinish");
    if (!this.isInState('payout')) {
      return;
    }

    this.dispatcherService.onUserActivity();

    if (this.moneyExchangeStateService.returnedAmount.isZero || this.moneyExchangeStateService.returnedAmount.isGreater(this.moneyExchangeStateService.insertedMoney)) {
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
      this.dispatcherService.workflowAddStep(WorkflowStepType.UnexpectedOperation);
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
      this.moneyExchangeStateService.createIssueIncorrectReturnAmount().subscribe((issueId: number) => {
        this.machine.toPrintIssueReceipt(issueId);
      });
    } else {
      this.machine.toFlowEnd();
    }
  }

  private onPayoutMoneyStart(acceptedCash: AcceptedCash): void {
    this._toPayout();
  }

  private _toPayout(): void {
    this.dispatcherService.workflowReset('Payout', WorkflowStepType.Wait);
    this.dispatcherService.teaserReset();
    this.machine.toPayout();
  }

  protected get timeoutTrackingStates(): string[] {
    return ['acceptingCashInfo', 'waitingReadyToMoneyExchange', 'payout', 'rules', 'flowEnd'];
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    if (machineName === this.machineName
      && this.isInState('rules')) {
      this._cancelByTimer = true;
      this.cancelSessionAndReturnMoney();
      return;
    }
    this.closeRemoteTransaction();
    super.onMachineTimeoutModalCancel(machineName);
  }

  cancelSessionAndReturnMoney(): void {
    this.moneyExchangeStateService.rollbackTransaction().subscribe(() => { this._transactionIsStarted = false; });
    this.dispatcherService.backButtonTextReset();
    this.dispatcherService.isBackButtonEnabled = false;
    this._toPayout();
  }

  closeRemoteTransaction(): void {
    if (!this._transactionIsStarted) {
      return;
    }

    this.moneyExchangeStateService.commitTransaction().subscribe(() => { this._transactionIsStarted = false; });
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'flowEnd':
        return new MachineInactivitySettings(10 * 1000, true);
      default:
        return new MachineInactivitySettings(30 * 1000, false);
    }
  }

  private _beginTransaction(): Observable<any> {
    return this.moneyExchangeStateService.beginTransaction().pipe(
      tap(result => {
        this._transactionIsStarted = result;
      }),
    );
  }

  private onPrintTaskResultReceived(result: PrintTaskResult) {
    if (this.state !== 'printIssueReceipt') {
      return;
    }
    const printTaskType = result.printTask.printTaskType;
    if (printTaskType === PrintTaskType.IssueReceipt) {
      this.log.info(`MachinePayoutService. onPrintTaskResultReceived. result: ${result}`);
      this.dispatcherService.teaserAddItem(TeaserType.TakeReceipt);
      const step = result ? WorkflowStepState.CompleteSuccess : WorkflowStepState.CompleteFailure;
      this.dispatcherService.workflowLastStepStateSet(step);
      this.machine.toFlowEnd();
    } else {
      this.log.error(`onPrintTaskResultReceived. Unsupported PrintTaskType: ${printTaskType}`);
    }
  }
}
