import { Injectable, Injector } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LoggingService } from './logging/logging.service';
import { ConfigurationService } from './configuration/configuration.service';
import { AssetsService } from './assets.service';
import { DispatcherService } from './dispatcher.service';
import { Configuration } from '../lib/lib';
import { LanguageService } from './language.service';
import { MessageType, MessageService } from './message.service';

@Injectable()
export class ThemeService {
  private internalLog: LoggingService;

  readonly AllCategoryId = '1';
  private previousCustomCss = '';
  private configurationService: ConfigurationService;
  private internalShowDemoBackground: boolean;
  private assetsService: AssetsService;
  private internalCacheLangToCustomCssStyle = new Map<string, string>();
  private internalCacheLangToCustomCssStyleFixed = new Map<string, string>();
  private dispatcherService: DispatcherService;
  private languageService: LanguageService;
  private messageService: MessageService;

  constructor(
    private injector: Injector,
    private router: Router,
  ) {
    this.configurationService = this.injector.get(ConfigurationService);
    this.assetsService = this.injector.get(AssetsService);
    this.languageService = this.injector.get(LanguageService);
    this.updateCustomCss();
    this.internalShowDemoBackground = this.configurationService.configuration.showDemoBackground;
    this.dispatcherService = this.injector.get(DispatcherService);
    this.dispatcherService.onConfigurationChangedSubscribe((x) => this._configurationChanged(x));
    this.messageService = this.injector.get(MessageService);
    this.messageService.getSubscription().subscribe(message => {
      if (message && message.messageType === MessageType.LanguageChanged) {
        this.updateCustomCss();
      }
    });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        switch (val.urlAfterRedirects) {
          case '/visual-items/store':
          case '/screen-saver':
            break;
          case '/lite-mode':
            this.setBackgroundImage(null);
            this.backgroundColor = 'black';
            break;
          case '/root':
          default:
            if (this.configurationService.configuration.backgroundId) {
              this.setBackgroundImageById(this.configurationService.configuration.backgroundId + '');
            } else {
              this.setBackgroundCategoryImage(this.all_category_id);
            }
            break;
        }
      }
    });
  }

  get showDemoBackground(): boolean {
    return this.internalShowDemoBackground;
  }

  set showDemoBackground(value: boolean) {
    this.internalShowDemoBackground = value;
    this.setBackgroundCategoryImage(this.all_category_id);
  }

  get all_category_id(): string {
    if (this.showDemoBackground) {
      return 'demo1';
    }
    return this.AllCategoryId;
  }

  setBackgroundCategoryImage(categoryId): void {
    this.log.info(`setBackgroundCategoryImage: ${categoryId}`);
    this.setBackgroundImage(this.assetsService.getCategoryImageUrl(categoryId));
  }

  setBackgroundImageById(imageId): void {
    this.log.info(`setBackgroundImageById: ${imageId}`);
    this.setBackgroundImage(this.assetsService.getImageUrlById(imageId));
  }

  setBackgroundImage(backgroundUrl: string): void {
    if (backgroundUrl == null || backgroundUrl === 'none') {
      document.body.style.backgroundImage = 'none';
    } else {
      document.body.style.backgroundImage = 'url(\'' + backgroundUrl + '\')';
    }
  }

  set backgroundColor(color: string) {
    document.body.style.backgroundColor = color;
  }

  get backgroundColor(): string {
    return document.body.style.backgroundColor;
  }

  get log(): LoggingService {
    if (this.internalLog == null) {
      this.internalLog = this.injector.get(LoggingService);
    }
    return this.internalLog;
  }

  private _configurationChanged(configuration: Configuration): void {
    this.updateCustomCss();
  }

  updateCustomCss(): void {
    if (!this.configurationService.configuration) {
      return;
    }

    const customCss = this.customCss;
    if (this.previousCustomCss === customCss) {
      return;
    }

    this.previousCustomCss = customCss;

    try {
      const style = document.getElementById('customCssStyle');
      if (style) {
        style.innerHTML = customCss;
      } else {
        const head = document.getElementsByTagName('head')[0];
        head.innerHTML += `<style id='customCssStyle' type="text/css">${customCss}</style>`;
      }
    } catch (e) {
      this.log.error(`updateCustomCss. ${e.message}`);
    }
  }

  get customCss(): string {
    if (!this.configurationService.configuration) {
      return '';
    }

    const language = this.language;
    const customCss = this.configurationService.configuration.customCss || '';
    const cacheCustomCss = this.internalCacheLangToCustomCssStyle.get(language) || '';
    if (cacheCustomCss === customCss) {
      return this.internalCacheLangToCustomCssStyleFixed.get(language) || '';
    }

    this.internalCacheLangToCustomCssStyle.set(language, customCss);
    const customCssFixedUrls = this.assetsService.replaceAssetsUrls(customCss, language);
    this.internalCacheLangToCustomCssStyleFixed.set(language, customCssFixedUrls);
    return customCssFixedUrls;
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }
}
