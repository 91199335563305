import { Directive, forwardRef, Attribute, ElementRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[appValidateNumber]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => NumberValidator), multi: true }
    ]
})
export class NumberValidator implements Validator {

    constructor() { }

    @Input()
    min: number;

    @Input()
    max: number;

    validate(control: AbstractControl): { [key: string]: any } {
        const controlValue = control.value;

        const value = Number.parseFloat(controlValue);

        if (this.min !== undefined && value < this.min) {
            return { minLimitationViolated: true };
        }

        if (this.max !== undefined && value > this.max) {
            return { maxLimitationViolated: true };
        }

        return null;
    }
}
