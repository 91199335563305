import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Teaser } from 'src/app/lib/teaser';
import { Workflow } from 'src/app/lib/workflow/workflow';
import { DispatcherService } from 'src/app/services/dispatcher.service';

@Component({
  selector: 'app-workflow-simple',
  templateUrl: './workflow-simple.component.html',
  styleUrls: ['./workflow-simple.component.css']
})
export class WorkflowSimpleComponent implements OnInit, OnDestroy {

  private workflow: Workflow;

  private workflowSubscription: Subscription;

  constructor(
    private dispatcherService: DispatcherService,
  ) {
  }

  ngOnInit(): void {
    this.workflowSubscription = this.dispatcherService.onWorkflowStateChangedSubscribe(x => this.onWorkflowStateChanged(x));
    this.dispatcherService.pageHeaderSet('');
    this.dispatcherService.backButtonTextSet('Home');
  }

  ngOnDestroy(): void {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.backButtonTextReset();
    this.workflowSubscription.unsubscribe();
  }

  onWorkflowStateChanged(workflow: Workflow): void {
    this.workflow = workflow;
  }

  get title(): string {
    return this.workflow?.name || '';
  }

  get step(): string {
    if (!this.workflow || !this.workflow.steps || this.workflow.steps.length === 0) {
      return '';
    }

    const lastStepIndex = this.workflow.steps.length - 1;
    const lastStep = this.workflow.steps[lastStepIndex];
    if (!lastStep) {
      return '';
    }

    return lastStep.text;
  }

  get messages(): string[] {
    return this.workflow?.additionalMessages || [];
  }
}
