import { Injectable } from '@angular/core';
import { NotificationMessageTypeEnum } from '../../lib/notification-message/notification-message-type.enum';
import { NotificationMessage } from '../../lib/notification-message/notification-message';

@Injectable()
export class NotificationService {

    static defaultTimeout = 5000;
    private readonly internalNotificationMessage = new NotificationMessage();
    private internalTimer: any;

    show(message: string, type: NotificationMessageTypeEnum = NotificationMessageTypeEnum.Info, timeout: number = NotificationService.defaultTimeout) {
        this.internalNotificationMessage.message = message;
        this.internalNotificationMessage.type = type;
        this.startTimer(timeout);
    }

    hide() {
        this.stopTimer();
        this.internalNotificationMessage.message = null;
    }

    get notificationMessage(): NotificationMessage {
        return this.internalNotificationMessage;
    }

    private startTimer(timeout: number) {
        this.stopTimer();

        this.internalTimer = setTimeout(
            () => {
                this.hide();
            }, timeout);
    }

    private stopTimer() {
        if(this.internalTimer != null) {
            clearTimeout(this.internalTimer);
            this.internalTimer = null;
        }
    }
}
