import { Component, Injector } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';
import { StoreHistoryService } from '../../../services/store-history.service';
import { StoreTreeState } from '../../../lib/store-tree-state';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { VisualItem, Ticket, TicketStatus } from '../../../lib/lib';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { DavCard } from '../../../lib/kletterzentrum/dav-card';
import { DavCardType } from '../../../lib/kletterzentrum/dav-card-type';
import { TicketService } from '../../../services/ticket/ticket.service';
import { DavCardService } from '../../../services/kletterzentrum/dav-card.service';
import { CategoryScanCardModalComponent } from '../modal/category-scan-card-modal/category-scan-card-modal.component';
import { LanguageService } from '../../../services/language.service';
import { MessageService, MessageType } from '../../../services/message.service';
import { ModalService } from '../../../services/gui/modal/modal-service';

@Component({
  moduleId: module.id,
  selector: 'app-categories-page',
  templateUrl: './categories-page.component.html',
  styleUrls: ['./categories-page.component.css']
})
export class CategoriesPageComponent {

  backButtonVisible: boolean;
  categories: VisualItem[];

  private _modalRef: BsModalRef;
  private _eventCloseSubscription: Subscription;
  private ticketService: TicketService;
  private davCardService: DavCardService;
  private backButtonSubscription: Subscription;
  protected messageService: MessageService;
  private timeoutModalCancelSubscription: Subscription;

  constructor(
    private dispatcherService: DispatcherService,
    private storeHistoryService: StoreHistoryService,
    private vuCommunicationService: VuCommunicationService,
    private modalService: ModalService,
    private injector: Injector,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit() {
    this.backButtonSubscription = this.dispatcherService.onBackButtonClickSubscribe(() => this.onBackButtonClick());
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = true;
    this.dispatcherService.pageHeaderSet('Buy Card');

    this.vuCommunicationService.vuHttp.getVisualItems("store").then(visualItems => {
      this.categories = visualItems;
    });

    this.ticketService = this.injector.get(TicketService);
    this.davCardService = this.injector.get(DavCardService);
    this.messageService = this.injector.get(MessageService);
    this.timeoutModalCancelSubscription = this.messageService.getSubscription().subscribe(message => {
      if (message && message.messageType === MessageType.MachineTimeoutModalCancel) {
        this.modalService.close(this._modalRef);
      }
    });
  }

  ngOnDestroy() {
    this.timeoutModalCancelSubscription.unsubscribe();
    this.backButtonSubscription.unsubscribe();
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
  }

  onCategoryButtonClick(category: VisualItem) {
    if (!category)
      return;

    if (category.data && category.data.checkCardType != DavCardType.None) {
      this._showDialog(category);
      return;
    }

    this._showSalePage(category);
  }

  onTicketScanned(ticket: Ticket, barcode: string) {
    let davCardInfo = new DavCard(null);
    davCardInfo.hasValidTicket = false;
    if (ticket != null) {
      davCardInfo.hasValidTicket = ticket.hasValidDavCard;
      davCardInfo.relatedTicketProductId = ticket.productId;
    }
    davCardInfo.cardNumber = barcode;

    this.davCardService.davCardInfo = davCardInfo;
  }

  private _showSalePage(category: VisualItem) {
    if (category == null)
      return;

    let memberAge = null;
    if (this.davCardService.davCardInfo != null && this.davCardService.davCardInfo.memberAge != null)
      memberAge = this.davCardService.davCardInfo.memberAge;

    const storeTreeState = new StoreTreeState();
    storeTreeState.initialize(category.children, memberAge);
    storeTreeState.parentItem = category;
    this.storeHistoryService.updateCurrentStoreTreeState(storeTreeState);
    this.dispatcherService.onButtonSaleShopClick();
  }

  private _showDialog(category: VisualItem) {
    let cardType: DavCardType;
    if (category && category.data && category.data.checkCardType)
      cardType = category.data.checkCardType;

    this._modalRef = this.modalService.show(
      CategoryScanCardModalComponent,
      {
        title: 'Scanning Card',
        message: (cardType && cardType == DavCardType.MemberDav) ?
          'Please scan your Member DAV card' : 'Please scan your Member DAV card Section Illertissen',
        isIllerissenMember: cardType && cardType == DavCardType.MemberDavSectionIllerissen
      },
      (data) => {
        this._modalRef = null;
        if (data && data.isContinue && data.cards && data.cards.length == 1) {
          let barcode = data.cards[0];

          this.ticketService.getTicketInfo(barcode).subscribe(
            (ticket: Ticket) => {
              this.onTicketScanned(ticket, barcode);
              this._showSalePage(category);
            },
          );
        }
      });
  }

  private onBackButtonClick() {
    this.dispatcherService.back();
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }
}
