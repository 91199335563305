export class PaymentMessageStore {
  message: string;
  higlightMessage: boolean;
  terminalText: string;

  constructor() {
    this.reset();
  }

  reset(): void {
    this.message = null;
    this.terminalText = null;
    this.higlightMessage = false;
  }
}
