import { GiftCardEventType } from './gift-card-event-type';

export class GiftCardEvent {
  eventType: GiftCardEventType;
  cardBalance: number;

  constructor(eventType: GiftCardEventType, cardBalance: number) {
    this.eventType = eventType;
    this.cardBalance = cardBalance;
  }

  static fromOther(x: any): GiftCardEvent {
    return new GiftCardEvent(x.eventType, x.cardBalance);
  }

  toString(): string {
    return `${GiftCardEventType[this.eventType]} '${this.cardBalance == null ? '' : this.cardBalance}'`
  }
}
