import { HammerGestureConfig } from '@angular/platform-browser';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class TouchService {

  private _hammerInstance: any;

  constructor(
  ) {
  }

  private get hammerInstance(): any {
    if (!this._hammerInstance) {
      const hammerConfig = new HammerGestureConfig();
      this._hammerInstance = hammerConfig.buildHammer(document.documentElement);
    }
    return this._hammerInstance;
  }

  get eventScreenTap(): Observable<any> {
    return fromEvent(this.hammerInstance, 'tap');
  }

  get eventScreenSwipe(): Observable<any> {
    return fromEvent(this.hammerInstance, 'swipe');
  }

  get eventScreenPan(): Observable<any> {
    return fromEvent(this.hammerInstance, 'pan').pipe(debounceTime(500));
  }
}

