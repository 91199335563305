import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '../../general/base/base-modal-component';

@Component({
  selector: 'app-confirm-action-modal',
  templateUrl: './confirm-action-modal.component.html',
  styleUrls: ['./confirm-action-modal.component.css']
})
export class ConfirmActionModalComponent extends BaseModalComponent {
  get title(): string {
    return this.internalData.title || 'Confirm Action';
  }

  set title(value: string) {
    this.internalData.title = value;
  }

  get message(): string {
    return this.internalData.message || 'Message';
  }

  set message(value: string) {
    this.internalData.message = value;
  }
}
