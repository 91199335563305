import { Component, ViewEncapsulation } from '@angular/core';
import { UiService } from '../../../services/ui.service';
import { DispatcherService } from '../../../services/dispatcher.service';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BackButtonComponent {
  constructor(
    public uiService: UiService,
    private dispatcherService: DispatcherService,
  ) {
  }

  onClick(): void {
    this.dispatcherService.onBackButtonClick();
  }
}
