import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {
  @Input()
  multiple: boolean = false;

  @Input()
  items: Array<string>;

  @Input()
  disabled: boolean = false;

  @Input()
  placeholder: string;

  @Output()
  onSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  private value: any = [''];

  public refreshValue(value: any): void {
    this.value = value;
    this.onSelect.emit(value);
  }

  public itemsToString(value: Array<any> = []): string {
    return value
      .map((item: any) => {
        return item.text;
      }).join(',');
  }

}
