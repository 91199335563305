<div *ngIf="titles?.length" class="modal-header">
  <div class="modal-title normal-text pull-left"><span *ngFor="let title of titles">{{ title | translate }}</span></div>
</div>
<div class="modal-body">
  <span *ngFor="let message of messages">{{ message | translate }}</span>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning pull-left button-base button-medium" (click)="close(false)">
    {{ 'Cancel' | translate }}
  </button>
  <button type="button" class="btn btn-success pull-right button-base button-medium" (click)="close(true)">
    {{ 'OK' | translate }}
  </button>
</div>

