import { MoneyExchangeRule } from './money-exchange-rule';

export class MoneyExchangeRules {
  constructor(
    public coins: MoneyExchangeRule[],
    public banknotes: MoneyExchangeRule[],
  ) {
  }

  static fromJson(data: any): MoneyExchangeRules {
    if (data == null) {
      return null;
    }
    const coins = MoneyExchangeRule.fromJsonArray(data.coins);
    const banknotes = MoneyExchangeRule.fromJsonArray(data.banknotes);
    return new MoneyExchangeRules(coins, banknotes);
  }

  rulesToString(rules: MoneyExchangeRule[]): string {
    return rules ? rules.join('; ') : '';
  }

  toString() {
    return `coins: '${this.rulesToString(this.coins)}'. banknotes: '${this.rulesToString(this.banknotes)}'`;
  }
}
