import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { ComponentsModule } from '../components/components.module';
import { PaymentCashComponent } from '../../components/shop/payment-cash/payment-cash.component';
import { MinMaxMoneyComponent } from '../../components/shop/min-max-money/min-max-money.component';
import { AcceptedCashComponent } from '../../components/shop/accepted-cash/accepted-cash.component';
import { ReceiptModule } from '../receipt/receipt.module';
import { DenominationComponent } from '../../components/shop/denomination/denomination.component';
import { PaymentAmountInputBaseComponent } from '../../components/grid/payment/payment-amount-input-base/payment-amount-input-base.component';
import { AcceptingCashInfoComponent } from '../../components/money-exchange/accepting-cash-info/accepting-cash-info.component';
import { ExchangeRulesComponent } from '../../components/money-exchange/exchange-rules/exchange-rules.component';
import { ExchangePayoutComponent } from '../../components/money-exchange/exchange-payout/exchange-payout.component';
import { Routes, RouterModule } from '@angular/router';
import { MoneyExchangeService } from '../../services/money-exchange/money-exchange.service';
import { MoneyExchangeStateService } from '../../services/money-exchange/money-exchange-state.service';
import { MachineMoneyExchangeService } from '../../services/machines/machine-money-exchange.service';
import { MoneyExchangeSimulatorService } from '../../services/money-exchange/money-exchange-simulator.service';
import { WorkflowModule } from '../workflow/workflow.module';
import { CardRechargeComponent } from '../../components/payment/card-recharge/card-recharge.component';

const routes: Routes = [
  { path: 'accepting-cash-info', component: AcceptingCashInfoComponent },
  { path: 'exchange-payout', component: ExchangePayoutComponent },
  { path: 'exchange-rules', component: ExchangeRulesComponent },
  { path: 'card-recharge', component: CardRechargeComponent },
  ];

@NgModule({
  declarations: [
    PaymentCashComponent,
    MinMaxMoneyComponent,
    AcceptedCashComponent,
    DenominationComponent,
    PaymentAmountInputBaseComponent,
    AcceptingCashInfoComponent,
    ExchangeRulesComponent,
    ExchangePayoutComponent,
    CardRechargeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    VUTranslateModule,
    ComponentsModule,
    ReceiptModule,
    RouterModule.forChild(routes),
    WorkflowModule,
  ],
  exports: [
    PaymentCashComponent,
    MinMaxMoneyComponent,
    DenominationComponent,
    AcceptedCashComponent,
  ],
  providers: [
    MoneyExchangeService,
    MoneyExchangeStateService,
    MachineMoneyExchangeService,
    MoneyExchangeSimulatorService,
  ],
})
export class PaymentCashModule { }
