import { Component, Injector } from '@angular/core';
import { DavCard } from '../../../../lib/kletterzentrum/dav-card';
import { BaseScanCardModalComponent } from '../../../modal/base-scan-card-modal/base-scan-card-modal.component';
import { TicketService } from '../../../../services/ticket/ticket.service';
import { Ticket } from '../../../../lib/lib';
import { Observable, of } from 'rxjs';
import { DavCardService } from '../../../../services/kletterzentrum/dav-card.service';

@Component({
  selector: 'app-dav-scan-card-modal',
  templateUrl: '../../../modal/base-scan-card-modal/base-scan-card-modal.component.html',
  styleUrls: ['../../../modal/base-scan-card-modal/base-scan-card-modal.component.css']
})

export class ScanDavCardModalComponent extends BaseScanCardModalComponent {
  private ticketService: TicketService;
  private davCardService: DavCardService;

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
    this.ticketService = this.injector.get(TicketService);
    this.davCardService = this.injector.get(DavCardService);
  }

  barcodeValidation(barcode: string): Observable<boolean> {
    var davCard = new DavCard(barcode);
    this.scanResult = false;
    if (!davCard.isDavCard) {
      this.showMessage('It is not a DAV card.', false);
      this.log.info(this.scanResultMessage);
      return of(false);
    }

    if (this.ignoreCards.length != 0 && this.ignoreCards.indexOf(barcode) != -1) {
      this.showMessage(this.cardAlreadyScandMeessage, false);
      this.log.info(this.scanResultMessage);
      return of(false);
    }

    const isIllerissenMember = this.davCardService.isSectionIllertissenMember;
    if (isIllerissenMember && !davCard.isSectionIllertissenMember) {
      this.showMessage('The DAV card can`t be accepted. Please use another card.', false);
      return of(false);
    }

    if (davCard.memberAge < 6) {
      this.showMessage('You don`t need a ticket. Pass is free.', false);
      this.log.info(this.scanResultMessage);
      return of(false);
    }

    if (!this.groupSize && this.product && !this.product.isValidForAge(davCard.memberAge)) {
      this.showMessage('The age on the card is wrong. Please scan another card.', false);
      this.log.info(this.scanResultMessage);
      return of(false);
    }

    return Observable.create((observer) => {
      super.barcodeValidation(barcode).subscribe(
        (result) => {

          if (!result) {
            observer.next(false);
            return;
          }

          if(this.groupSize) {
            const agesForCheck = Object.assign([], this.ages);
            agesForCheck.push(davCard.memberAge);
            if (this.product && !this.product.isValidForAgesGroup(agesForCheck, this.groupSize)) {
              this.showMessage('The age on the card is wrong. Please scan another card.', false);
              this.log.info(this.scanResultMessage);
              observer.next(false);
              return;
            }
          }

          this.ticketService.getTicketInfo(barcode).subscribe(
            (ticket: Ticket) => {
              if (ticket.hasValidDavCard) {
                this.showMessage('Your card is already used', false);
                this.log.info(this.scanResultMessage);
                observer.next(false);
              } else {
                this.ages.push(davCard.memberAge);
                observer.next(true);
              }
            });
        });

    });
  }

  get ages(): number[] {
    if (!this.internalData.ages)
      this.internalData.ages = new Array<number>();
    return this.internalData.ages;
  }

  set ages(ages: number[]) {
    this.internalData.ages = ages;
  }

  get cardAlreadyScandMeessage(): string {
    return 'The DAV card is already scanned. Please scan another card.';
  }

  get cardSuccesfulyScandMeessage(): string {
    return 'The DAV card was successfully scanned.';
  }

  close(isContinue: boolean) {
    this.ages = new Array<number>();
    super.close(isContinue);
  }
}
