import { Injectable } from '@angular/core';
import { ILog } from './log.interface';
import { VuLoggingService } from '../vu/vu-logging.service';
import { GlobalSettings } from '../../lib/lib';

const CSS = {
  'INFO': '',
  'WARNING': 'color: red',
  'DEBUG': 'color: #ba4a00',
  'ERROR': 'color: white; background-color: black',
};

function pad(num: number, size: number): string {
  let s = '000000000' + num.toString();
  return s.substr(s.length - size);
}

@Injectable()
export class LoggingService implements ILog {
  constructor(private vuLoggingService: VuLoggingService) {
    GlobalSettings.setLog(this);
  }

  info(message: any) {
    this.log(message, 'INFO');
  }

  warning(message: any) {
    this.log(message, 'WARNING');
  }

  debug(message: any) {
    this.log(message, 'DEBUG');
  }

  error(message: any) {
    this.log(message, 'ERROR');
  }

  private log(message: any, level: string) {
    let date = new Date();
    let day = pad(date.getDate(), 2);
    let monthIndex = pad(date.getMonth(), 2);
    let year = date.getFullYear();
    let hours = pad(date.getHours(), 2);
    let minutes = pad(date.getMinutes(), 2);
    let seconds = pad(date.getSeconds(), 2);
    let milliseconds = pad(date.getMilliseconds(), 3);
    console.log(`%c[${hours}:${minutes}:${seconds}.${milliseconds}] - ${message}`, CSS[level]);
    this.vuLoggingService.writeLogMessage(`[${year}-${monthIndex}-${day} ${hours}:${minutes}:${seconds}] [${level}] ${message}`);
  }
}
