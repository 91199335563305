import { Component, Input, Output, EventEmitter } from '@angular/core';
import { KeyboardType } from '../on-screen-keyboard/on-screen-keyboard.component';

const LAYOUT = [
  {
    "row": [
      { "value": "1", "action": "char" },
      { "value": "2", "action": "char" },
      { "value": "3", "action": "char" },
      { "value": "4", "action": "char" },
      { "value": "5", "action": "char" },
      { "value": "6", "action": "char" },
      { "value": "7", "action": "char" },
      { "value": "8", "action": "char" },
      { "value": "9", "action": "char" },
      { "value": "0", "action": "char" },
      { "value": "←", "action": "del" },
    ]
  },
  {
    "row": [
      { "value": "Q", "action": "char" },
      { "value": "W", "action": "char" },
      { "value": "E", "action": "char" },
      { "value": "R", "action": "char" },
      { "value": "T", "action": "char" },
      { "value": "Y", "action": "char" },
      { "value": "U", "action": "char" },
      { "value": "I", "action": "char" },
      { "value": "O", "action": "char" },
      { "value": "P", "action": "char" },
    ]
  },
  {
    "row": [
      { "value": "A", "action": "char" },
      { "value": "S", "action": "char" },
      { "value": "D", "action": "char" },
      { "value": "F", "action": "char" },
      { "value": "G", "action": "char" },
      { "value": "H", "action": "char" },
      { "value": "J", "action": "char" },
      { "value": "K", "action": "char" },
      { "value": "L", "action": "char" },
    ]
  },
  {
    "row": [
      { "value": "Z", "action": "char" },
      { "value": "X", "action": "char" },
      { "value": "C", "action": "char" },
      { "value": "V", "action": "char" },
      { "value": "B", "action": "char" },
      { "value": "N", "action": "char" },
      { "value": "M", "action": "char" },
    ]
  },
  {
    "enter": [
      { "value": "Enter", "action": "enter" }
    ]
  },
];

@Component({
  moduleId: module.id,
  selector: 'virtual-keyboard',
  templateUrl: 'virtual-keyboard.component.html',
  styleUrls: ['virtual-keyboard.component.scss']
})
export class VirtualKeyboardComponent {
  layout: any[] = LAYOUT;
  textValue: string = '';
  @Input() keyboardType = KeyboardType.All;
  @Input() isDisabled = false;
  @Output() onEnteredText: EventEmitter<string> = new EventEmitter<string>();
  @Output() onTextChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  textLength: number;

  @Input()
  separatorPosition: number;

  get _textLength(): number {
    if (this.textLength) {
      return this.textLength;
    }

    const separatorCount = 3;
    return 16 + separatorCount;
  }

  checkTextLength(length: number): boolean {
    return length == this._textLength;
  }

  keyPress(key) {
    const value = key.value;
    const action = key.action;
    switch (action) {
      case 'char':
      case 'number':
      case 'zero':
      case 'space':
        const newCharLength = 1;
        if (this.checkTextLength(this.textValue.length + newCharLength)) {
          this.textValue = `${this.textValue}${value.toUpperCase()}`
          this.onEnteredText.emit(this.textValue);
          break;
        }

        this.onTextChanged.emit();

        if (this.separatorPosition && this.separatorPosition != 0) {
          if ((this.textValue.length + 2) % this.separatorPosition == 0)
            this.textValue = `${this.textValue}${value.toUpperCase()}-`;
          else {
            if ((this.textValue.length + 1) % this.separatorPosition == 0)
              this.textValue = `${this.textValue}-${value.toUpperCase()}`
            else
              this.textValue = `${this.textValue}${value.toUpperCase()}`
          }
        } else {
          this.textValue = `${this.textValue}${value.toUpperCase()}`
        }

        break;
      case 'del':
        let l = this.textValue.length;
        if (l > 0) {
          this.textValue = this.textValue.substr(0, l - 1)
        }

        if (this.checkTextLength(this.textValue.length)) {
          this.onEnteredText.emit(this.textValue);
        }

        break;
      default:
        break;
    }
  }
}
