import { Action } from '@ngrx/store';
import { Header } from '../lib/header';

const initialState = new Header();

export const PAGE_HEADER_SET = 'PageHeaderSet';

export const PAGE_HEADER_VISIBLE = 'PageHeaderVisible';

export class PageHeaderAction implements Action {
  readonly type: string = PAGE_HEADER_SET;
  constructor(
    public payload: any,
  ) {
    initialState.resetText();
  }
}

export function pageHeaderReducer(state = initialState, action: PageHeaderAction) {
  let isChanged = true;
  switch (action.type) {
    case PAGE_HEADER_SET:
      state.text = action.payload;
      break;
    case PAGE_HEADER_VISIBLE:
      state.visible = action.payload;
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? Header.fromOther(state) : state;
}
