import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-xml-preview',
  templateUrl: './print-xml-preview.component.html',
  styleUrls: ['./print-xml-preview.component.css']
})
export class PrintXmlPreviewComponent {

  @Input()
  xmlTemplate: string;

  get receiptPreview(): string {
    return this.formattingTemplate(this.xmlTemplate || '');
  }

  private formattingTemplate(xml: string): string {
    return xml.replace(/<pre>/g, '<span class="pre">')
      .replace(/<line>/g, '<span class="line">')
      .replace(/<\/line>/g, '</span>')
      .replace(/<left>/g, '<span class="left">')
      .replace(/<\/left>/g, '</span>')
      .replace(/<right>/g, '<span class="right">')
      .replace(/<\/right>/g, '</span>')
      .replace(/<\/pre>/g, '</span>');
  }
}
