import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { CIOBoardService } from '../../../../cioboard/cioboard.service';
import { Subscription } from 'rxjs';
import { CIOBoardPinEvent } from '../../../../../lib/cioboard/cioboard-pin-event';
import { CIOBoardEvent } from '../../../../../lib/cioboard/coiboard-event';

@Injectable()
export class CIOBoardEventGridWorkflowService extends BaseGridWorkflowService {

  private cioBoardService: CIOBoardService;
  subsrcription: Subscription;

  init(
  ) {
    super.init();
    this.cioBoardService = this.injector.get(CIOBoardService);
  }
  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CIOBoardEvent;
  }

  show(displayItem: DisplayItem, context: any): void {
    super.show(displayItem, context);
    this.enableCIOBoardEvent();
  }

  hide(displayItem: DisplayItem, context: any): void {
    super.hide(displayItem, context);
    this.disabledCIOBoardEvent();
  }

  hideAll(): void {
    super.hideAll();
    this.disabledCIOBoardEvent();
  }

  enableCIOBoardEvent(): void {
    if (this.isEnableCIOBoardEvent) {
      return;
    }

    this.subsrcription = this.cioBoardService.stateChange.subscribe((changedState: CIOBoardEvent) => {
      if (changedState && changedState.isOnePinsChanged(this.displayItem.cioboardPins)) {
        this.navigateToUrlOrNavigationItem(this.displayItem);
      }
    });
  }

  disabledCIOBoardEvent(): void {
    if (!this.isEnableCIOBoardEvent) {
      return;
    }
    if (this.subsrcription) {
      this.subsrcription.unsubscribe();
      this.subsrcription = null;
    }
  }

  get isEnableCIOBoardEvent(): boolean {
    return this.subsrcription != null;
  }

  get cioboardPins(): CIOBoardPinEvent[] {
    return this.displayItem.cioboardPins;
  }
}
