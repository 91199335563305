export const DEUTSCH_LANGUAGE_ID = 'de-DE';

export const ENGLISH_LANGUAGE_ID = 'en-US';

export const CZECH_LANGUAGE_ID = 'cs-CZ';

export const UKRAINIAN_LANGUAGE_ID = 'uk-UA';

export const POLISH_LANGUAGE_ID = 'pl-PL';

export const FRENCH_LANGUAGE_ID = 'fr-FR';

export const ROMANIAN_LANGUAGE_ID = 'ro-RO';

export const TURKISH_LANGUAGE_ID = 'tr-TR';

export const DEFAULT_LANGUAGE_ID = DEUTSCH_LANGUAGE_ID;

export class Locale {
  id: string;
  displayName: string;

  static convertToOdooLanguageId(languageAngularId: string): string {
    if (languageAngularId) {
      return languageAngularId.replace('-', '_');
    }
    return '';
  }
}

export const LOCALES: Locale[] = [
  { id: ENGLISH_LANGUAGE_ID, displayName: 'English' },
  { id: DEUTSCH_LANGUAGE_ID, displayName: 'Deutsch' },
  { id: UKRAINIAN_LANGUAGE_ID, displayName: 'Українська' },
  { id: CZECH_LANGUAGE_ID, displayName: 'Čeština' },
  { id: POLISH_LANGUAGE_ID, displayName: 'Polski' },
  { id: FRENCH_LANGUAGE_ID, displayName: 'Français' },
  { id: ROMANIAN_LANGUAGE_ID, displayName: 'Română' },
  { id: TURKISH_LANGUAGE_ID, displayName: 'Türkçe' },
];
