import { Injectable, Injector } from '@angular/core';

import { IDisplayGridWorkflowService } from './../display-grid-workflow.interface';
import { DisplayItemTypeEnum } from '../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { DisplayGridWorkflowService } from './../display-grid-workflow.service';
import { DisplayItemTree } from '../../../../lib/display/display-item/display-item-tree';
import { DictionaryHelper } from '../../../../lib/dictionary-helper';
import { VuCommunicationService } from '../../../vu/vu-communication.service';
import { DispatcherService } from '../../../dispatcher.service';
import { LoadingSpinnerService } from '../../../loading-spinner/loading-spinner.service';
import { NotificationService } from '../../../notification/notification.service';
import { NotificationMessageTypeEnum } from '../../../../lib/notification-message/notification-message-type.enum';
import { GlobalSettings } from '../../../../lib/lib';
import { LoggingService } from '../../../logging/logging.service';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class BaseGridWorkflowService implements IDisplayGridWorkflowService {

  protected displayGridWorkflowService: DisplayGridWorkflowService;
  protected vuCommunicationService: VuCommunicationService;
  protected dispatcherService: DispatcherService;
  protected loadingSpinnerService: LoadingSpinnerService;

  private hardResetDelayAction: any;

  protected context: any;
  protected displayItem: DisplayItem;

  protected notificationService: NotificationService;

  protected loggingService: LoggingService;

  constructor(
    protected injector: Injector,
  ) {
    this.init();
  }

  init() {
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.dispatcherService = this.injector.get(DispatcherService);
    this.loadingSpinnerService = this.injector.get(LoadingSpinnerService);
    this.notificationService = this.injector.get(NotificationService);
    this.loggingService = this.injector.get(LoggingService);
  }

  get displayItemType(): DisplayItemTypeEnum {
    return null;
  }

  initialize(displayGridWorkflowService: DisplayGridWorkflowService) {
    this.displayGridWorkflowService = displayGridWorkflowService;
  }

  processAction(displayItem: DisplayItem, context: any): boolean {
    if (displayItem == null || displayItem.type !== this.displayItemType) {
      return false;
    }

    return true;
  }

  showDisplayItems(displayItems: DisplayItem[], show: boolean, context: any): void {
    if (displayItems == null) {
      this.hideAll();
      return;
    }

    for (const displayItem of displayItems) {
      if (displayItem.type === this.displayItemType) {
        if (show) {
          this.show(displayItem, context);
        } else {
          this.hide(displayItem, context);
        }
        break;
      }
    }
  }

  show(displayItem: DisplayItem, context: any): void {
    this.context = context;
    this.displayItem = displayItem;
  }

  hide(displayItem: DisplayItem, context: any): void {
    this.context = null;
    this.displayItem = null;
  }

  hideAll(): void {
    this.context = null;
    this.displayItem = null;
  }

  showDataInDisplayGrid(data: any, context: any) {
    const dictionaryHelper = new DictionaryHelper(data);
    const displayItemTree = DisplayItemTree.build(
      dictionaryHelper.getProperty('items'),
      dictionaryHelper.getProperty('items_tree'),
      dictionaryHelper.getProperty('additional_items_tree'),
      this.displayGridWorkflowService.gridVisualItemsComponent.displayConfiguration.allItems,
    );

    context.displayItemInfo = data;
    displayItemTree.fillProducts(this.vuCommunicationService).then(() => {
      if (this.displayGridWorkflowService && this.displayGridWorkflowService.gridVisualItemsComponent &&
        displayItemTree.nodes && displayItemTree.nodes.length === 1) {
        this.displayGridWorkflowService.gridVisualItemsComponent.showAdditionDisplayItem(displayItemTree.nodes[0]);
      }
    });
  }

  nextStep(displayItem: DisplayItem): void {
    if (!displayItem) {
      return;
    }

    this.showDisplayItem(displayItem.navigationItem);
  }

  showDisplayItem(displayItem: DisplayItem): void {
    if (!displayItem || displayItem.type !== DisplayItemTypeEnum.Category) {
      return;
    }

    this.displayGridWorkflowService.gridVisualItemsComponent.showAdditionDisplayItem(displayItem);
  }

  navigateToRootPage(): void {
    this.displayGridWorkflowService.gridVisualItemsComponent.navigateToRootPage();
  }

  reset(): void {
    this.context = {};
  }

  fillExternalApiRequestData(requestData: Map<string, any>, context: any) {
  }


  updateContext(context: any) {
  }

  get externalApiRequestData(): Map<string, any> {
    return this.displayGridWorkflowService.buildExternalApiRequestData();
  }

  fillDisplayItemRenderData(displayItem: DisplayItem, data: Map<string, any>, context: any) {
    if (displayItem == null || context == null ||
      context.odooContext == null || context.odooContext.externalContext == null) {
      return;
    }

    const externalContextData = context.odooContext.externalContext;
    for (const key in externalContextData) {
      if (!externalContextData.hasOwnProperty(key)) {
        continue;
      }
      const placeholderName = '@' + key;
      if (displayItem.htmlTemplate.includes(placeholderName)) {
        let placeholderValue = externalContextData[key];
        if (key.indexOf('Money') === 0) {
          placeholderValue = placeholderValue.toLocaleString(GlobalSettings.getCurrentLocale(), { minimumFractionDigits: 2 });
        }
        data.set(placeholderName, placeholderValue || '');
      }
    }
  }

  userActivity() {
    if (this.dispatcherService) {
      this.dispatcherService.onUserActivity();
    }

    this.displayGridWorkflowService.displayGridInactivityService.updateInactivityTimerIfEnabled();
  }

  get canDoHardReset(): boolean {
    return true;
  }

  protected delayedHardReset() {
    if (this.hardResetDelayAction) {
      if (typeof (this.hardResetDelayAction) === 'function') {
        this.hardResetDelayAction();
      }
      this.hardResetDelayAction = null;
    }
  }

  protected get hardResetProccessDisplayItem(): DisplayItem {
    return null;
  }

  hardReset(action: () => void): void {
    const displayItem = this.hardResetProccessDisplayItem;
    if (displayItem) {
      this.hardResetDelayAction = action;
      this.showDisplayItem(displayItem);
      return;
    }

    if (typeof (action) === 'function') {
      action();
    }
  }

  findDisplayItemByUniqueName(uniqueName: string): DisplayItem {
    const parentDisplayItem = this.displayGridWorkflowService.parentDisplayItem;
    if (parentDisplayItem && parentDisplayItem.children) {
      return parentDisplayItem.children.find(x => x.uniqueName === uniqueName);
    }
    return null;
  }

  navigateToUrlOrNavigationItem(displayItem: DisplayItem): void {
    if (!displayItem) {
      return;
    }
    if (displayItem.apiUrl != null && displayItem.apiUrl.trim().length !== 0) {
      this.loadingSpinnerService.show();
      try {
        this.vuCommunicationService.ExternalApiService.sendPostRequestOdooJson(displayItem.apiUrl, this.externalApiRequestData)
          .then((result) => {
            if (result) {
              this.context.odooContext = result.context;
              this.displayGridWorkflowService.updateContext(this.context);
            }
            if (this.externalContextError) {
              this.loadingSpinnerService.hide();
              this.showErrorMessage(this.externalContextError, '');
            } else {
              this.showDataInDisplayGrid(result, this.context);
              this.loadingSpinnerService.hide();
            }
          })
          .catch((error: any) => {
            this.loadingSpinnerService.hide();
            this.showErrorMessage('Communication error', error);
          });
      } catch (error) {
        this.loadingSpinnerService.hide();
        this.showErrorMessage('Communication error', error);
      }
    } else {
      this.navigateToNavigationItem();
    }
  }

  getRequestData(url: string, requestData: Map<string, any> | null | undefined): Observable<any> {
    this.loadingSpinnerService.show();
    return from(this.vuCommunicationService.ExternalApiService.sendPostRequestOdooJson(url, requestData))
      .pipe(
        map(
          (result) => {
            this.loadingSpinnerService.hide();
            return result;
          }
        ),
        catchError(
          (error: any): Observable<any> => {
            this.loadingSpinnerService.hide();
            return throwError(error);
          },
        ),
      );
  }

  get externalContextError(): string {
    return this.context &&
      this.context.odooContext &&
      this.context.odooContext.externalContext &&
      this.context.odooContext.externalContext.Error || '';
  }

  navigateBack(): void {
    this.displayGridWorkflowService.gridVisualItemsComponent.backPage();
  }

  navigateToNavigationItem(): boolean {
    if (!this.displayItem) {
      return false;
    }

    if (this.displayItem.previousDisplayItem) {
      this.navigateBack();
      return true;
    }

    if (this.displayItem.navigationItem) {
      this.nextStep(this.displayItem);
      return true;
    }

    return false;
  }

  showErrorMessage(title: string, error: any): void {
    this.loggingService.warning(`${title}. ${error || ''}`);
    this.notificationService.show(title, NotificationMessageTypeEnum.Error);
  }

  getRfidCardCodeFormattedData(context: any): string {
    let result = '';
    if (!context) {
      return result;
    }

    result += `${context.rfidCardCode}`;

    if (context.odooContext && context.odooContext.externalContext && context.odooContext.externalContext.PersonId) {
      result += `#${context.odooContext.externalContext.PersonId}`;
    }

    return result;
  }
}
