import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map, tap, switchMap, catchError } from 'rxjs/internal/operators';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ExternalApiService } from 'src/app/services/vu/external-api/external-api.service';
import { VuCommunicationService } from 'src/app/services/vu/vu-communication.service';
import { CovidCheckOperation } from '../models/covid-check-operation';
import { CovidCheckResult } from '../models/covid-check-result';

@Injectable()
export class CovidService {

  private certificateValidated;

  currentCovidCheckOperation: CovidCheckOperation;

  validationCompleted: EventEmitter<boolean> = new EventEmitter(true);

  constructor(
    private vuCommunicationService: VuCommunicationService,
    private configurationService: ConfigurationService,
    private externalApiService: ExternalApiService,
  ) {
  }

  public checkCertificate(certificate: string, testCertificate: string = ''): Observable<CovidCheckResult> {
    return of(true).pipe(
      switchMap(() => {
        const requestValues = new Map<string, any>();
        requestValues.set('qr', certificate);
        requestValues.set('qr_test', testCertificate);

        const url = this.configurationService.configuration.odooUrl + '/api/covcheck';
        return this.externalApiService.sendPostRequestOdooPlainJson(url, requestValues);
      }),
      map((result) => {
        return CovidCheckResult.createFromAny(result);
      }),
      catchError(error => {
        return of(new CovidCheckResult());
      }),
    );
  }

  public setCertificateValidated(result: boolean): void {
    this.certificateValidated = result;
    this.validationCompleted.emit(result);
}

  get isCovidCheckRequiredSettings(): boolean {
    return this.configurationService.configuration?.originalConfigurationHelper?.getBoolean('covid_check_required');
  }

  get isCovidCheckRequired(): boolean {
    return this.isCovidCheckRequiredSettings && !this.certificateValidated;
  }

  reset(): void {
    this.certificateValidated = false;
    this.currentCovidCheckOperation = CovidCheckOperation.WaitCertificateScan;
  }
}
