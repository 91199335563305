import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'simple-button-image',
  templateUrl: './simple-button-image.component.html',
  styleUrls: ['./simple-button-image.component.css']
})
export class SimpleButtonImageComponent implements OnInit {

  @Input()
  cssClass = 'btn btn-success button-base back-button';

  @Output()
  buttonClick: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  imageURL = '';

  @Input()
  providerName = '';

  @Input()
  iconClass = '';

  constructor() { }

  onButtonClick(): void {
    this.buttonClick.emit();
  }

  ngOnInit(): void {
  }

}

