export class RfidCardData {

  public data: string;

  static createFromAny(obj: any): RfidCardData {
    if (!obj) {
      return null;
    }

    const rfidCardData = new RfidCardData();
    rfidCardData.data = obj;
    return rfidCardData;
  }
}
