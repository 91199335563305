import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingSpinnerService } from '../../../../services/loading-spinner/loading-spinner.service';
import { ExternalPaymentService } from '../../services/external-payment.service';
import { DispatcherService } from '../../../../services/dispatcher.service';
import { ThemeService } from '../../../../services/theme.service';

@Component({
  selector: 'app-external-payment',
  templateUrl: './external-payment.component.html',
  styleUrls: ['./external-payment.component.css']
})
export class ExternalPaymentComponent implements OnInit, OnDestroy {

  private _loading: boolean;
  private _backButtonVisible: boolean;
  private _abortButtonVisible: boolean;
  private _activatedRouteParamsSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingSpinnerService: LoadingSpinnerService,
    private externalPaymentService: ExternalPaymentService,
    private dispatcherService: DispatcherService,
    private themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.dispatcherService.pageHeaderSet();
    this._backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = false;
    this._abortButtonVisible = this.dispatcherService.isAbortButtonVisible;
    this.dispatcherService.isAbortButtonVisible = false;

    this.loadingSpinnerService.show();
    this._loading = true;
    this.themeService.updateCustomCss();

    this._activatedRouteParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
      this.externalPaymentService.initialize(params);
      this.loadingSpinnerService.hide();
    });
  }

  ngOnDestroy(): void {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.isBackButtonVisible = this._backButtonVisible;
    this.dispatcherService.isAbortButtonVisible = this._abortButtonVisible;

    if (this._activatedRouteParamsSubscription) {
      this._activatedRouteParamsSubscription.unsubscribe();
      this._activatedRouteParamsSubscription = null;
    }
  }

  get orderId(): string {
    return this.externalPaymentService.orderId;
  }

  get loading(): boolean {
    return this._loading;
  }
}
