export class CardDispenserActionResult {
  isFailed: boolean;
  barcode: string;
  isAvailable: boolean;
  cardJsonData = '';
  eventDateTime: Date;

  constructor(
    isFailed: boolean,
    barcode: string,
    isAvailable: boolean,
    cardJsonData?: string,
    eventDateTime?: Date
  ) {
    this.isFailed = isFailed;
    this.barcode = barcode;
    this.isAvailable = isAvailable;
    this.cardJsonData = cardJsonData;
    this.eventDateTime = eventDateTime;
  }

  static fromJson(data: any): CardDispenserActionResult {
    if (data == null || data.id === -1) {
      return null;
    }
    const obj = data;
    return new CardDispenserActionResult(
      obj.isFailed,
      obj.barcode,
      obj.isAvailable,
      obj.cardJsonData || '',
      obj.eventDateTime != null ? new Date(obj.eventDateTime) : null,
    );
  }

  public toString() {
    let s = `isFailed: '${this.isFailed}'. `;
    s += `Barcode: '${this.barcode}'. `;
    s += `isAvailable: '${this.isAvailable}'. `;
    s += `cardJsonData: '${this.cardJsonData}'`;
    s += `eventDateTime: '${this.eventDateTime}'`;
    return s;
  }
}
