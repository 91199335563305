<div class="message-outer">
  <div class="message-middle">
    <div class="message-inner">
      <app-message></app-message>
      <i *ngIf="iconCssClass" class="fa fa-2x" [ngClass]="iconCssClass" aria-hidden="true"></i>
    </div>
  </div>
</div>

<!-- <button (click)="setCertificateValidated(true)">TEST - True</button> -->
