import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LogisticsRequestSimulatorService } from '../../../services/logistics-request/logistics-request-simulator.service';
import { LogisticsRequest } from '../../../lib/logistics/logistics-request';
import { LogisticsRequestStateEnum } from '../../../lib/logistics/logistics-request-state-enum';

@Component({
  selector: 'app-logistics-request-simulator',
  templateUrl: './logistics-request-simulator.component.html',
  styleUrls: ['./logistics-request-simulator.component.scss']
})
export class LogisticsRequestSimulatorComponent implements OnInit, OnDestroy {
  logisticsRequests: LogisticsRequest[];
  logisticsRequestObj: LogisticsRequest;
  currentState: string;
  states: string[];

  constructor(
    private logisticsRequestService: LogisticsRequestSimulatorService, ) { }

  ngOnInit() {
    this.logisticsRequests = this.logisticsRequestService.memoryLogisticsRequests;
    this.states = Object.values(LogisticsRequestStateEnum);
    this.currentState = this.logisticsRequestObj ? this.logisticsRequestObj.state : null;
  }

  ngOnDestroy() {
  }


  onLogisticsRequestChange(event: any) {
    this.currentState = this.logisticsRequestObj.state;
  }


  onStateChange(event: any) {
    this.logisticsRequestService.setState(this.logisticsRequestObj.id, this.currentState);
  }

}
