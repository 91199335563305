import { Component, Injector } from '@angular/core';
import { ProductWithVariants } from 'src/app/lib/product/product-with-variants';
import { LanguageService } from 'src/app/services/language.service';
import { IVuHttp } from 'src/app/services/vu/http/vu-http.interface';
import { VuCommunicationService } from 'src/app/services/vu/vu-communication.service';
import { BaseModalComponent } from '../base/base-modal-component';

@Component({
  selector: 'app-select-product-variant-popup',
  templateUrl: './select-product-variant-popup.component.html',
  styleUrls: ['./select-product-variant-popup.component.scss']
})

export class SelectProductVariantPopupComponent extends BaseModalComponent {

  _currentProductWithVariants: ProductWithVariants = null;
  currentProductWithVariantsIndex = 0;
  selectedProductIds = [];
  selectedProductId = -1;

  private vuHttp: IVuHttp;
  private vuCommunicationService: VuCommunicationService;

  constructor(
    private injector: Injector,
    private languageService: LanguageService,
  ) {
    super();
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.vuHttp = this.vuCommunicationService.vuHttp;
  }

  get productsWithVariants(): ProductWithVariants[] {
    return this.internalData.productsWithVariants;
  }

  get currentProductWithVariants(): ProductWithVariants {
    if (this._currentProductWithVariants) {
      return this._currentProductWithVariants;
    }
    const productsWithVariants = this.productsWithVariants;
    if (productsWithVariants && productsWithVariants.length >= 1) {
      this._currentProductWithVariants = productsWithVariants[0];
    }

    return this._currentProductWithVariants;
  }

  selectProduct(productId: number): void {
    if (!productId) {
      return;
    }
    this.selectedProductId = productId;

    const productsWithVariants = this.productsWithVariants;
    if (productsWithVariants && productsWithVariants.length > this.currentProductWithVariantsIndex + 1) {
      this.currentProductWithVariantsIndex += 1;
      if (this.selectedProductId !== -1) {
        this.selectedProductIds.push(this.selectedProductId);
      }
      this.selectedProductId = -1;
    } else {
      if (this.selectedProductId !== -1) {
        this.selectedProductIds.push(this.selectedProductId);
      }
      this.finishSelectProductVariants();
    }
  }

  finishSelectProductVariants(): void {
    const result = {
      accepted: true,
      selectedProducts: [],
    };

    if (this.selectedProductIds && this.selectedProductIds.length > 0) {
      this.vuHttp.getProductsByIds(this.selectedProductIds)
        .then(products => {
          result.selectedProducts = products;
          this.close(result);
        });
      return;
    }

    this.close(result);
  }

  back(): void {
    const productsWithVariants = this.productsWithVariants;
    if (!productsWithVariants || productsWithVariants.length === 0 || this.currentProductWithVariantsIndex - 1 < 0) {
      const result = {
        accepted: false,
        selectedProducts: [],
      };
      this.close(result);
      return;
    }

    this.currentProductWithVariantsIndex -= 1;
    this.selectedProductId = this.selectedProductIds[this.currentProductWithVariantsIndex];
  }

  get isNextButtonAvailable(): boolean {
    return this.currentProductWithVariants && (!this.currentProductWithVariants.variants
      || this.currentProductWithVariants.variants.length === 0);
  }

  get productName(): string {
    return this.currentProductWithVariants.translateName(this.language) || this.currentProductWithVariants.name;
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }
}
