import { GlobalSettings } from 'src/app/lib/global-settings';
import { Money } from 'src/app/lib/money/money';
import { FreeProductInformation } from './free-product-information';
import { PurchasedProductInformation } from './purchased-product-information';
import { FreePurchasedProductInformation } from './free-purchased-product-information';

export class TotalPurchasedInformation {
  purchasedProducts: PurchasedProductInformation[];
  freeProducts: FreeProductInformation[];
  freePurchasedProducts: FreePurchasedProductInformation[];
  cashback: Money;

  static createFromAny(data: any): TotalPurchasedInformation {
    const totalPurchasedInformation = new TotalPurchasedInformation();
    if (data) {
      totalPurchasedInformation.purchasedProducts = PurchasedProductInformation.createFromAnyArray(data.purchased_products);
      totalPurchasedInformation.freeProducts = FreeProductInformation.createFromAnyArray(data.free_products);
      totalPurchasedInformation.freePurchasedProducts = FreePurchasedProductInformation.createFromAnyArray(data.free_purchased_products);
      totalPurchasedInformation.cashback = data.cashback_amount != null ?
        new Money(data.cashback_amount, GlobalSettings.getCurrencyCode()) : Money.empty;
    }

    return totalPurchasedInformation;
  }

}
