import { Component, Input, Injector, ViewChild } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';
import { TicketParameters } from '../../../lib/ticket/ticket-parameters';
import { Product } from '../../../lib/lib';
import { LanguageService } from '../../../services/language.service';
import { KeyboardHelper } from '../../../lib/helpers/keyboard-helper';
import { OnScreenKeyboardComponent, KeyboardType } from '../on-screen-keyboard/on-screen-keyboard.component';
import { GlobalSettings } from '../../../lib/lib';
import { BaseModalComponent } from '../base/base-modal-component';

enum FieldType {
  FirstName,
  LastName,
}

@Component({
  selector: 'app-ticket-parameters',
  templateUrl: './ticket-parameters.component.html',
  styleUrls: ['./ticket-parameters.component.css']
})
export class TicketParametersComponent extends BaseModalComponent {
  private dispatcherService: DispatcherService;
  private languageService: LanguageService;

  get product(): Product {
    return this.internalData.product;
  }

  @Input()
  groupSizeCaption: string;

  get ticketParameters(): TicketParameters {
    return this.internalData.ticketParameters;
  }

  bsRangeValue: Date[];

  _startDate: Date;

  _endDate: Date;

  selectedKeywords: any;

  FieldType: typeof FieldType = FieldType;

  focusedField = FieldType.FirstName;

  private _keyboardHelper: KeyboardHelper;

  @ViewChild('screenKeyboard', { static: false }) screenKeyboard: OnScreenKeyboardComponent;

  constructor(
    private injector: Injector
  ) {
    super();
    this.groupSizeCaption = 'Persons Number';
    this.dispatcherService = this.injector.get(DispatcherService);
    this.languageService = this.injector.get(LanguageService);
  }

  initializeKeyboardHelper(): boolean {
    if (this._keyboardHelper) {
      return true;
    }

    if (!this.screenKeyboard) {
      return false;
    }

    this._keyboardHelper = new KeyboardHelper(this.screenKeyboard);
    return true;
  }

  onFocus(field: FieldType): void {
    this.focusedField = field;

    this._updateKeyboardType();
  }

  get birthdayField(): boolean {
    if (this.showBirthdayField) {
      return false;
    }
  }

  get isOkDisabled(): boolean {
    return !(this.isPartnerValid && this.isBirthdayValid && this.isAppointmentValid);
  }

  get isPartnerValid(): boolean {
    if (this.isPartnerRequired) {
      return Boolean(
        this.ticketParameters.lastName
        && this.ticketParameters.firstName
        && this.ticketParameters.lastName.length
        && this.ticketParameters.firstName.length);
    }

    return true;
  }

  get isAppointmentValid(): boolean {
    if (this.appointmentEnabled) {
      return Boolean(
        this.ticketParameters.dateTo
        && this.ticketParameters.dateFrom
        && this.ticketParameters.ticketKeywords
        && this.ticketParameters.ticketKeywords.length);
    }
    return true;
  }

  get isBirthdayValid(): boolean {
    if (this.displayBirthdayField) {
      return Boolean(this.ticketParameters.customerBirthday);
    }
    return true;
  }

  _updateKeyboardType(): void {
    if (!this.initializeKeyboardHelper()) {
      return;
    }
    switch (this.focusedField) {
      case FieldType.FirstName:
        this._keyboardHelper.setKeyboardType(
          this.ticketParameters.firstName,
          KeyboardType.CharsOnly,
          KeyboardType.CharsOnlyWithSpace,
          null
        );
        break;
      case FieldType.LastName:
        this._keyboardHelper.setKeyboardType(
          this.ticketParameters.lastName,
          KeyboardType.CharsOnly,
          KeyboardType.CharsOnlyWithSpace,
          null
        );
        break;
    }
  }

  keyPress(key: any): void {
    this.dispatcherService.onUserActivity();

    if (!this.initializeKeyboardHelper()) {
      return;
    }

    switch (this.focusedField) {
      case FieldType.FirstName:
        this.ticketParameters.firstName = this._keyboardHelper.keyPress(this.ticketParameters.firstName, key);
        break;
      case FieldType.LastName:
        this.ticketParameters.lastName = this._keyboardHelper.keyPress(this.ticketParameters.lastName, key);
        break;
    }

    this._updateKeyboardType();
  }

  updateCaretPos(oField): void {
    if (!this.initializeKeyboardHelper()) {
      return;
    }

    this._keyboardHelper.updateCaretPos(oField);
    this._updateKeyboardType();
  }

  get showGroupSize(): boolean {
    return this.groupSizeMin > 0 && this.groupSizeMax > 0;
  }

  onDateRangeSelect(value: Date[]): void {
    this.bsRangeValue = value;
    if (value.length === 2) {
      this._startDate = value[0];
      this._endDate = value[1];
      this.ticketParameters.dateFrom = this._startDate;
      this.ticketParameters.dateTo = this._endDate;
    }
  }

  get startDate(): Date {
    return this._startDate;
  }

  get endDate(): Date {
    return this._endDate;
  }

  onSelectKeywords(value): void {
    this.selectedKeywords = [];
    this.ticketParameters.ticketKeywords = '';
    value.map((item: any) => {
      this.selectedKeywords.push(item.text);
      this.ticketParameters.ticketKeywords =
        this.ticketParameters.ticketKeywords ? this.ticketParameters.ticketKeywords + '; ' + item.text : item.text;
    })
  }

  get showBirthdayField(): boolean {
    return this.displayBirthdayField;
  }

  onDatepickerSelect(value: Date): void {
    this.ticketParameters.customerBirthday = GlobalSettings.getIsoDateStrByDate(value);
  }

  get today(): Date {
    return new Date();
  }

  get headerCaption(): string {
    if (!this.product) {
      return '';
    }

    return this.product.translateName(this.language) || this.product.name;
  }

  get isPartnerRequired(): boolean {
    if (!this.product) {
      return false;
    }

    return this.product.isPartnerRequired;
  }

  get groupSizeMin(): number {
    if (!this.product) {
      return 0;
    }

    return this.product.groupSizeMin;
  }

  get groupSizeMax(): number {
    if (!this.product) {
      return 0;
    }

    return this.product.groupSizeMax;
  }

  get appointmentEnabled(): boolean {
    if (!this.product) {
      return false;
    }

    return this.product.appointmentEnabled;
  }

  get appointmentDateFrom(): Date {
    if (!this.product) {
      return null;
    }

    return new Date(this.product.appointmentDateFrom).getUTCFullYear() > 2010 ? new Date(this.product.appointmentDateFrom) : new Date();
  }

  get appointmentDateTo(): Date {
    if (!this.product) {
      return null;
    }

    return new Date(this.product.appointmentDateTo).getUTCFullYear() > 2010 ? new Date(this.product.appointmentDateTo) : null;
  }

  get appointmentKeywords(): string[] {
    if (!this.product) {
      return null;
    }

    return this.product.appointmentKeywords ? this.product.appointmentKeywords.split(';') : [];
  }

  get displayBirthdayField(): boolean {
    if (!this.product) {
      return false;
    }

    return this.product.displayBirthdayField;
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }
}
