import { TicketStatus } from "./lib";


export class StatusInfo {
  text: string;
  cssClass: string;
  status: TicketStatus;

  constructor(text: string,
    cssClass: string,
    status: TicketStatus
  ) {
    this.text = text;
    this.cssClass = cssClass;
    this.status = status;
  }

  static getStyleByTicketAccessStatus(ticketStatus: TicketStatus): StatusInfo {
    let result;

    switch (ticketStatus) {
      case TicketStatus.Valid:
        result = new StatusInfo('Valid', 'fa-thumbs-up green', TicketStatus.Valid);
        break;
      case TicketStatus.OnlyExitAllowed:
        result = new StatusInfo('Only Exit Allowed', 'fa-thumbs-up green', TicketStatus.Valid);
        break;
      case TicketStatus.Invalid:
        result = new StatusInfo('Invalid', 'fa-thumbs-down red', TicketStatus.Invalid);
        break;
      case TicketStatus.InvalidOvertime:
        result = new StatusInfo('Overtime', 'fa-clock-o orange', TicketStatus.InvalidOvertime);
        break;
      case TicketStatus.WrongSide:
        result = new StatusInfo('Wrong side', 'fa-lock red', TicketStatus.WrongSide);
        break;
      case TicketStatus.TodayUsed:
        result = new StatusInfo('Today used', 'fa-hourglass-end green', TicketStatus.TodayUsed);
        break;
      case TicketStatus.Used:
        result = new StatusInfo('Used', 'fa-trash-o orange', TicketStatus.Used);
        break;
      case TicketStatus.Staff:
        result = new StatusInfo('Staff', 'fa-address-card-o green', TicketStatus.Staff);
        break;
      case TicketStatus.Expired:
        result = new StatusInfo('Expired', 'fa-clock-o red', TicketStatus.Expired);
        break;
      case TicketStatus.OutOfSchedule:
        result = new StatusInfo('Out of schedule', 'fa-clock-o orange', TicketStatus.OutOfSchedule);
        break;
      case TicketStatus.VirtualTicket:
        result = new StatusInfo('Virtual ticket', 'fa-clock-o green', TicketStatus.VirtualTicket);
        break;
      case TicketStatus.InvalidVirtualTicket:
        result = new StatusInfo('Invalid virtual ticket', 'fa-thumbs-down red', TicketStatus.InvalidVirtualTicket);
        break;
      case TicketStatus.Unknown:
      default:
        result = new StatusInfo('Unknown', 'fa-question-circle red', TicketStatus.Unknown);
        break;
    }

    return result;
  }

  static getStyleByTicketStatus(ticketStatus: TicketStatus): StatusInfo {
    let result;

    switch (ticketStatus) {
      case TicketStatus.Valid:
      case TicketStatus.OnlyExitAllowed:
        result = new StatusInfo('Valid', 'fa-thumbs-up green', TicketStatus.Valid);
        break;
      case TicketStatus.Invalid:
        result = new StatusInfo('Invalid', 'fa-thumbs-down red', TicketStatus.Invalid);
        break;
      case TicketStatus.Disabled:
        result = new StatusInfo('Disabled', 'fa-trash-o gray', TicketStatus.Disabled);
        break;
      case TicketStatus.Unknown:
      default:
        result = new StatusInfo('Unknown', 'fa-question-circle red', TicketStatus.Unknown);
        break;
    }

    return result;
  }
}

