import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseModalComponent } from 'src/app/components/general/base/base-modal-component';
import { NumberScanModalResult } from 'src/app/lib/scan/number-scan-modal-result';
import { RechargeCardService } from 'src/app/modules/recharge-card/services/recharge-card.service';
import { RfidCardDialogueService } from 'src/app/modules/rfid-card/services/rfid-card-dialogue.service';
import { CardDispenserService } from 'src/app/services/card-dispenser/card-dispenser.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';

@Component({
  selector: 'app-card-dispenser-read-card-modal',
  templateUrl: './card-dispenser-read-card-modal.component.html',
  styleUrls: ['./card-dispenser-read-card-modal.component.css']
})
export class CardDispenserReadCardModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  private loadCardInformationSubscription: Subscription;

  constructor(
    private rechargeCardService: RechargeCardService,
    private rfidCardDialogueService: RfidCardDialogueService,
    private dispatcherService: DispatcherService,
    private cardDispenserService: CardDispenserService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.usubscribeLoadCardInformationSubscription();
  }

  onRfidCardRead(barcode: string): void {

    this.dispatcherService.onUserActivity();

    this.usubscribeLoadCardInformationSubscription();

    let productId = 0;
    if (this.data && this.data.product) {
      productId = this.data.product.id;
    }

    this.loadCardInformationSubscription = this.rechargeCardService.loadCardInformation(barcode, true, productId)
      .subscribe(cardInformation => {
        this.rfidCardDialogueService.closeMessageDialogue();
        if (cardInformation && cardInformation.name) {
          this.close(new NumberScanModalResult(barcode, cardInformation));
        } else {
          this.rfidCardDialogueService.showFailedScanRfidCardDialogue();
          this.cardDispenserService.releaseCardCustomer(false);
        }
      });
  }

  onCancel(): void {
    this.cardDispenserService.stopTakeCardFromCustomer();
    this.close(false);
  }

  usubscribeLoadCardInformationSubscription(): void {
    if (this.loadCardInformationSubscription) {
      this.loadCardInformationSubscription.unsubscribe();
      this.loadCardInformationSubscription = null;
    }
  }
}
