export class TicketParameters {
  firstName: string;
  lastName: string;
  groupSize: number;
  dateFrom: Date;
  dateTo: Date;
  ticketKeywords: string;
  customerBirthday: string;

  constructor(
    firstName: string = '',
    lastName: string = '',
    groupSize: number = 0,
    dateFrom: Date = null,
    dateTo: Date = null,
    ticketKeywords: string = '',
    customerBirthday: string = '',
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.groupSize = groupSize;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.ticketKeywords = ticketKeywords;
    this.customerBirthday = customerBirthday;
  }

  get isQuantityEditable(): boolean {
    return Boolean(this.firstName || this.lastName || this.groupSize >= 1);
  }
}
