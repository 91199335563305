import { GlobalSettings } from '../lib';

export class Money {
  value: number;
  currencyCode: string;

  static toMoneyArray(objects: any[]): Money[] {
    if (!objects) {
      return [];
    }
    return objects.map(x => new Money(x.value, x.currencyCode));
  }

  static fromJSON(json: any): Money {
    if (json == null) {
      return Money.empty;
    }
    return new Money(json.value, json.currencyCode);
  }

  static getCurrencyCode(price: Money): string {
    return price && price.currencyCode ? price.currencyCode : GlobalSettings.getCurrencyCode();
  }

  static cointainsMoneyInArray(moneyArray: Money[], money: Money): boolean {
    if (!moneyArray) {
      return false;
    }

    for (const element of moneyArray) {
      if (element && element.isEquals(money)) {
        return true;
      }
    }

    return false;
  }

  static isArrayEmptyOrZero(moneyArray: Money[]): boolean {
    if (!moneyArray || moneyArray.length === 0) {
      return true;
    }

    let notZeroMoneyExist = false;
    for (const element of moneyArray) {
      if (element && !element.isZero) {
        notZeroMoneyExist = true;
        break;
      }
    };

    return !notZeroMoneyExist;
  }

  constructor(value: number, currencyCode: string) {
    this.value = value;
    this.currencyCode = currencyCode;
  }

  static get empty(): Money {
    return new Money(0, '');
  }

  get currencySign(): string {
    switch (this.currencyCode) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'UAH':
        return '₴';
      case 'NOK':
        return 'kr';
      case 'ESP':
        return '₧';
      case 'ATS':
        return 'S';
      case 'JPY':
        return '¥';
      case 'GBP':
        return '£';
      case 'CHF':
        return 'Fr';
      case 'SEK':
        return 'kr';
      case 'PLN':
        return 'zł';
    }
    return this.currencyCode;
  }

  get currencyCoinSign(): string {
    switch (this.currencyCode) {
      case 'EUR':
        return 'ct';
      case 'USD':
        return '¢';
      case 'UAH':
        return 'kop';
      case 'NOK':
        return 'kr';
      case 'ESP':
        return '₧';
      case 'ATS':
        return 'gro';
      case 'JPY':
        return '¥';
      case 'GBP':
        return 'p';
      case 'CHF':
        return 'Rp';
      case 'SEK':
        return 'kr';
      case 'PLN':
        return 'gr';
    }
    return this.currencyCode;
  }

  add(other: Money): Money {
    const currencyCode = this.currencyCode ? this.currencyCode : other.currencyCode;
    return new Money(this.fixFloatPoint(this.value + other.value), currencyCode);
  }

  fixFloatPoint(value: number): number {
    if (!value) {
      return value;
    }
    return parseFloat((value).toFixed(2));
  }

  distract(other: Money): Money {
    return this.add(new Money(-other.value, other.currencyCode));
  }

  get isPositive(): boolean {
    return this.value > 0;
  }

  get isNegative(): boolean {
    return this.value < 0;
  }

  get isZero(): boolean {
    return this.value === 0;
  }

  absolute(): Money {
    return new Money(Math.abs(this.value), this.currencyCode);
  }

  clone(): Money {
    return new Money(this.value, this.currencyCode);
  }

  negate(): Money {
    return new Money(-this.value, this.currencyCode);
  }

  zero(): Money {
    return new Money(0, this.currencyCode);
  }

  toString(): string {
    const value = this.value.toLocaleString(GlobalSettings.getCurrentLocale(), { minimumFractionDigits: 2 });
    return `${value} ${this.currencyCode}`;
  }

  toStringCurrencySign(): string {
    const value = this.value.toLocaleString(GlobalSettings.getCurrentLocale(), { minimumFractionDigits: 2 });
    return `${value} ${this.currencySign}`;
  }

  toStringCompact(): string {
    const value = this.value.toLocaleString(GlobalSettings.getCurrentLocale(), { maximumFractionDigits: 1 });
    return `${value}${this.currencySign}`;
  }

  toStringCompactWithCoin(): string {
    if (this.value < 1) {
      const value = (this.value * 100).toLocaleString(GlobalSettings.getCurrentLocale(), { maximumFractionDigits: 1 });
      return `${value}${this.currencyCoinSign}`;
    } else {
      const value = this.value.toLocaleString(GlobalSettings.getCurrentLocale(), { maximumFractionDigits: 1 });
      return `${value}${this.currencySign}`;
    }
  }

  isEquals(money: Money): boolean {
    return money && this.value === money.value && this.currencyCode === money.currencyCode;
  }

  isGreater(money: Money): boolean {
    return money && this.value < money.value && this.currencyCode === money.currencyCode;
  }

  isEqualsOrGreater(money: Money): boolean {
    return money && this.value <= money.value && this.currencyCode === money.currencyCode;
  }

  isLess(money: Money): boolean {
    return money && this.value > money.value && this.currencyCode === money.currencyCode;
  }

  isEqualsOrLess(money: Money): boolean {
    return money && this.value >= money.value && this.currencyCode === money.currencyCode;
  }

}
