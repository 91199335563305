import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentMethod } from 'src/app/lib/payment/payment-method';
import { PaymentsMethodService } from 'src/app/services/payment/payment-method.service';

@Component({
  selector: 'app-payment-buttons',
  templateUrl: './payment-buttons.component.html',
  styleUrls: ['./payment-buttons.component.css']
})

export class PaymentButtonsComponent {

  @Output()
  paymentClick: EventEmitter<PaymentMethod> = new EventEmitter<PaymentMethod>();

  @Input()
  showIcons: boolean;

  @Input()
  withImage = false;

  availablePayments = PaymentMethod;

  constructor(
    private paymentMethodService: PaymentsMethodService,
  ) {
  }

  getImageUrl(paymentMethod: PaymentMethod): string {
    return this.paymentMethodService.getImageUrl(paymentMethod);
  }

  getProviderName(paymentMethod: PaymentMethod): string {
    return this.paymentMethodService.getProviderName(paymentMethod);
  }

  getButtonText(paymentMethod: PaymentMethod): string {
    return this.paymentMethodService.getButtonText(paymentMethod);
  }
  getIconClass(paymentMethod: PaymentMethod): string {
    return this.paymentMethodService.getIconClass(paymentMethod);
  }

  getImageClass(paymentMethod: PaymentMethod): string {
    if (this.getImageUrl(paymentMethod)) {
      return '';
    }
    return this.paymentMethodService.getIconClass(paymentMethod);
  }

  getSimpleButtonCSSClass(paymentMethod: PaymentMethod): string {

    let SimpleButtonCSSClass = 'btn btn-primary button-base back-button w100';

    if (paymentMethod === PaymentMethod.PaymentCard) {
      SimpleButtonCSSClass = SimpleButtonCSSClass + ' payment-by-card';
    }

    if (paymentMethod === PaymentMethod.Cash) {
      SimpleButtonCSSClass = SimpleButtonCSSClass + ' payment-by-cash';
    }

    if (paymentMethod === PaymentMethod.Bluecode) {
      SimpleButtonCSSClass = SimpleButtonCSSClass + ' payment-by-bluecode';
    }

    if (paymentMethod === PaymentMethod.GiftCard) {
      SimpleButtonCSSClass = SimpleButtonCSSClass + ' payment-by-giftcard';
    }

    return SimpleButtonCSSClass;
  }

  get paymentMethods(): PaymentMethod[] {
    return this.paymentMethodService.availablePaymentMethods;
  }

  onPaymentMethodClick(paymentMethod: PaymentMethod): void {
    this.paymentClick.emit(paymentMethod);
  }

}
