import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { BarcodeScanModalComponent } from './components/barcode-scan-modal/barcode-scan-modal.component';
import { BarcodeScanComponent } from './components/barcode-scan/barcode-scan.component';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [
    BarcodeScanModalComponent,
    BarcodeScanComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    TranslateModule,
  ],
  exports: [
    BarcodeScanModalComponent,
    BarcodeScanComponent,
  ],
  entryComponents: [
    BarcodeScanModalComponent,
  ],
})
export class BarcodeModule { }
