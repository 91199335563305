<div class="modal-header">
  <div class="modal-title normal-text center">{{ title | translate }}</div>
</div>
<div class="modal-body center">
  <div class="normal-text">{{ message | translate }}</div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success pull-right button-base button-medium" (click)="close(true)">
    {{ 'Confirm' | translate }}
  </button>
  <button type="button" class="btn btn-danger pull-left button-base button-medium" (click)="close(false)">
    {{ 'Close' | translate }}
  </button>
</div>
