import { Component } from '@angular/core';

import { Locale } from '../../../lib/lib';
import { LanguageService } from '../../../services/language.service';

@Component({
  moduleId: module.id,
  selector: 'languages',
  templateUrl: 'languages.component.html',
  styleUrls: ['languages.component.scss']
})
export class LanguagesComponent {

  constructor(
    public languageService: LanguageService,
  ) {
  }

  onChange(value: Locale) {
    this.languageService.selectedLocale = value;
  }
}
