import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class LoadingSpinnerService {

  private _counter = 0;

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
  ) {
  }

  show(): void {
    this._counter++;
    this._updateVisible();
  }

  hide(): void {
    this._counter--;
    if (this._counter < 0) {
      this._counter = 0;
    }
    this._updateVisible();
  }

  _updateVisible(): void {
    if (this._counter > 0) {
      this.ngxSpinnerService.show();
    } else {
      this.ngxSpinnerService.hide();
    }
  }
}
