import { Action } from '@ngrx/store';
import { PayloadAction } from '../lib/payload-action';

export class SimulatorState {
  isTestRunning: boolean;

  static fromOther(other: any) {
    return Object.assign(new SimulatorState(), other);
  }
}

const initialState: SimulatorState = {
  isTestRunning: false,
};

export const SIMULATOR_IS_TEST_RUNNING = 'SimulatorIsTestRunning';

export class SimulatorIsTestunningAction implements Action {
  readonly type = SIMULATOR_IS_TEST_RUNNING;
  constructor(public payload: boolean) { }
}

export function simulatorReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;
  switch (action.type) {
    case SIMULATOR_IS_TEST_RUNNING:
      state.isTestRunning = action.payload;
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? SimulatorState.fromOther(state) : state;
}
