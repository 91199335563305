import { Injectable } from '@angular/core';
import { VuCommunicationService } from '../vu/vu-communication.service';

@Injectable()
export class BarcodeReaderSimulatorService {

  constructor(
    private vuCommunicationService: VuCommunicationService,
  ) {
  }

  barcodeRead(barcode: string) {
    this.vuCommunicationService.vuConnectionSimulator.barcodeRead(barcode);
  }

}
