import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { ProductGridWorkflowService } from '../product/product-grid-workflow.service';

@Injectable()
export class BasketGridWorkflowService extends ProductGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Basket;
  }
}
