import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../base-grid-workflow.service';

@Injectable()
export class CategoryGridWorkflowService extends BaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Category;
  }

  processAction(displayItem: DisplayItem, context: any): boolean {
    const result = super.processAction(displayItem, context);
    if (!result) {
      return false;
    }

    if (displayItem && displayItem.previousDisplayItem) {
      this.navigateBack();
      return true;
    }

    if (displayItem && displayItem.apiUrl && displayItem.canUseExternalNavigation) {
      window.location.href = displayItem.apiUrl;
      return true;
    }

    return false;
  }
}
