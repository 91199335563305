import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '../../general/base/base-modal-component';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css']
})
export class InfoModalComponent extends BaseModalComponent {
  get title(): string {
    return this.internalData.title || 'Information';
  }

  set title(value: string) {
    this.internalData.title = value;
  }

  get message(): string {
    return this.internalData.message || 'Message';
  }

  set message(value: string) {
    this.internalData.message = value;
  }
}
