import { Component, OnInit } from '@angular/core';
import { IVuConnectionSimulator } from '../../../services/vu/connection/vu-connection.interfaces';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { TurnstileSimulatorService } from '../../../services/turnstile/turnstile-simulator.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { DictionaryHelper } from '../../../lib/dictionary-helper';

@Component({
  selector: 'app-turnstile-simulator',
  templateUrl: './turnstile-simulator.component.html',
  styleUrls: ['./turnstile-simulator.component.css']
})
export class TurnstileSimulatorComponent implements OnInit {

  vuSimulator: IVuConnectionSimulator;
  constructor(
    private vuCommunicationService: VuCommunicationService,
    private turnstileSimulatorService: TurnstileSimulatorService,
    private configurationService: ConfigurationService,
  ) {
    this.vuSimulator = this.vuCommunicationService.vuConnectionSimulator;
  }

  ngOnInit() {
  }

  get additionalProperties(): DictionaryHelper {
    return this.configurationService.configuration.additionalPropertiesHelper;
  }

  onTurnstileEnterClick() {
    this.vuSimulator.onTurnstileEnter();
  }

  get isEnterAllowed(): Boolean {
    return this.turnstileSimulatorService.isEnterAllowed;
  }

  get turnstileIsLeftSide(): boolean {
    return this.additionalProperties.getBoolean("turnstile_on_left_side");
  }

  set turnstileIsLeftSide(value: boolean) {
    this.additionalProperties.setProperty("turnstile_on_left_side", value);
    this._vuConfigurationChanged();
  }

  private _vuConfigurationChanged() {
    this.vuCommunicationService.vuConnectionSimulator.configurationChanged(this.configurationService.configuration);
  }
}
