import { Component, Input, OnInit } from '@angular/core';
import { CardInformation } from 'src/app/modules/recharge-card/models/card-information';

@Component({
  selector: 'app-expired-card-information',
  templateUrl: './expired-card-information.component.html',
  styleUrls: ['./expired-card-information.component.css']
})
export class ExpiredCardInformationComponent implements OnInit {

  @Input()
  cardInformation: CardInformation;

  constructor() { }

  ngOnInit(): void {
  }

}
