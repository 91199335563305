export enum RefundPaymentActionEnum {
  WithRefund = 'WithRefund',
  WithoutRefund = 'WithoutRefund',
  WithoutRefundIfOrderWasSaved = 'WithoutRefundIfOrderWasSaved',
  NoRefundOnReceiptPrint = 'NoRefundOnReceiptPrint',
  NoRefundOnTicketPrint = 'NoRefundOnTicketPrint',
  NoRefundIfReceiptTaken = 'NoRefundIfReceiptTaken',
  NoRefundIfTicketTaken = 'NoRefundIfTicketTaken',
  NoRefundIfTicketAndReceiptTaken = 'NoRefundIfTicketAndReceiptTaken',
}
