import { IVuHttp } from '../../../services/vu/http/vu-http.interface';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { VuState, ShopState, OrderSaveResult, CashDevicesState } from '../../../lib/lib';
import { SimulatorState } from '../../../reducers/reducers';
import { IVuConnectionSimulator } from '../../../services/vu/connection/vu-connection.interfaces';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { TestingService } from '../../../services/testing.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { MachinesInactivityService } from '../../../services/machines/machines-inactivity.service';
import { ThemeService } from '../../../services/theme.service';
import { CookieService } from 'ngx-cookie';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DictionaryHelper } from '../../../lib/dictionary-helper';
import { LogisticsRequestSimulatorService } from '../../../services/logistics-request/logistics-request-simulator.service';

const SIMULATOR_TAB_INDEX = 'simulator_tab_index';

@Component({
  selector: 'simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SimulatorComponent implements OnInit, OnDestroy {
  private vuSimulator: IVuConnectionSimulator;
  private vuHttp: IVuHttp;
  isProduction: boolean;
  vuState = new VuState();
  cashDevicesState = new CashDevicesState();
  shopState = new ShopState();
  private subscriptionVuState: Subscription;
  private subscriptionCashDevicesState: Subscription;
  private subscriptionShopState: Subscription;
  simulatorState = new SimulatorState();
  private subscriptionSimulatorState: Subscription;
  cannotPayCash = false;
  cannotPayCard = false;
  isOrderSaving = false;
  tabSelectedIndex = 0;
  shortTab = true;

  constructor(
    protected configurationService: ConfigurationService,
    private testingService: TestingService,
    private vuCommunicationService: VuCommunicationService,
    private dispatcherService: DispatcherService,
    private userInactivityService: MachinesInactivityService,
    private themeService: ThemeService,
    private cookieService: CookieService,
    private logisticsRequestService: LogisticsRequestSimulatorService,
  ) {
    this.vuSimulator = vuCommunicationService.vuConnectionSimulator;
    this.vuHttp = vuCommunicationService.vuHttp;
    this.logisticsRequestService.generateLogisticsRequestInMemory();
  }

  ngOnInit(): void {
    this.isProduction = this.configurationService.configuration.isProduction;
    this.subscriptionVuState = this.dispatcherService.onVuStateChangedSubscribe(x => this.vuState = x);
    this.subscriptionCashDevicesState = this.dispatcherService.onCashDevicesStateSubscribe(
      x => {
        this.cashDevicesState = x;
      }
    );
    this.subscriptionShopState = this.dispatcherService.onShopStateChangedSubscribe(x => this.onShopStateChanged(x));
    this.subscriptionSimulatorState = this.dispatcherService.onSimulatorStateChangedSubscribe(x => this.onSimulatorState(x));
    this.restoreTabSelectedIndex();
  }

  get isCashPayIn(): boolean {
    return this.cashDevicesState.isCashPayIn;
  }

  get isCashPayOut(): boolean {
    return this.cashDevicesState.isCashPayOut;
  }

  ngOnDestroy(): void {
    this.subscriptionVuState.unsubscribe();
    this.subscriptionShopState.unsubscribe();
    this.subscriptionSimulatorState.unsubscribe();
    this.subscriptionCashDevicesState.unsubscribe();
  }

  private onShopStateChanged(shopState: ShopState): void {
    this.cannotPayCash = !shopState.canPayCash;
    this.cannotPayCard = !shopState.canPayCard;
    this.isOrderSaving = shopState.isOrderSaving;
    this.shopState = shopState;
  }

  private onSimulatorState(simulatorState: SimulatorState): void {
    this.simulatorState.isTestRunning = simulatorState.isTestRunning;
  }

  onMaintenanceModeChange(event: any): void {
    this.dispatcherService.vuStateMaintenanceMode(event.currentValue);
  }

  onBurglaryChange(event: any): void {
    this.dispatcherService.vuStateBurglaryMode(event.currentValue);
  }

  onOfflineModeChange(event: any): void {
    this.dispatcherService.vuStateOfflineMode(event.currentValue);
  }

  onServiceModeClick(): void {
    this.dispatcherService.vuStateServiceMode(true);
  }

  onCanPayCashChange(event: any): void {
    this.dispatcherService.shopStateCanPayCash(!event.currentValue);
  }

  onNoConnectionChange(event: any): void {
    this.vuSimulator.connectionChanged(!event.currentValue);
  }

  onTestStartClick(): void {
    this.testingService.testStart();
  }

  onTestStopClick(): void {
    this.testingService.testStop();
  }

  onResetMachinesClick(): void {
    this.dispatcherService.machineHardResetRoot();
  }

  onPaidoutClick(): void {
    this.vuSimulator.returnAmountFinished();
  }

  onReturnChangeFinishedClick(): void {
    this.vuSimulator.returnChangeFinished();
  }


  showTimeoutModal(): void {
    this.userInactivityService.simulateTimeoutModalOpen();
  }

  onProductionApiChange(event: any): void {
    this.vuHttp.isUseProductionApi = event.currentValue;
  }

  get showDemoBackground(): boolean {
    return this.themeService.showDemoBackground;
  }

  set showDemoBackground(value: boolean) {
    this.themeService.showDemoBackground = value;
  }

  get additionalProperties(): DictionaryHelper {
    return this.configurationService.configuration.additionalPropertiesHelper;
  }

  get liteMode(): boolean {
    return this.additionalProperties.getBoolean('lite_mode');
  }

  set liteMode(value: boolean) {
    this.additionalProperties.setProperty('lite_mode', value);
    this._vuConfigurationChanged();
  }

  get demoMode(): boolean {
    return this.additionalProperties.getBoolean('demo');
  }

  set demoMode(value: boolean) {
    this.additionalProperties.setProperty('demo', value);
    this._vuConfigurationChanged();
  }

  get liteTouchTileMode(): boolean {
    return this.additionalProperties.getBoolean("lite_touch_tile_mode");
  }

  set liteTouchTileMode(value: boolean) {
    this.additionalProperties.setProperty("lite_touch_tile_mode", value);
    this._vuConfigurationChanged();
  }

  private _vuConfigurationChanged(): void {
    this.vuCommunicationService.vuConnectionSimulator.configurationChanged(this.configurationService.configuration);
  }

  onTabChanged(event: MatTabChangeEvent): void {
    this.cookieService.put(SIMULATOR_TAB_INDEX, event.index.toString());
  }

  restoreTabSelectedIndex(): void {
    const str = this.cookieService.get(SIMULATOR_TAB_INDEX);
    if (!str) {
      return;
    }
    const tabSelectedIndex = parseInt(str, 10);
    if (isNaN(tabSelectedIndex)) {
      return;
    }

    // TODO: return if tabSelectedIndex is too large.

    this.tabSelectedIndex = tabSelectedIndex;
  }

  onBnaValidationStartedClick(): void {
    this.vuCommunicationService.vuConnectionSimulator.onBnaValidationStarted();
  }

  onBnaValidationFinishedClick(): void {
    this.vuCommunicationService.vuConnectionSimulator.onBnaValidationFinished();
  }
}
