<div class="visual-items">
  <div class="scroll scrollbar">
    <div class="container entire-width">
      <div *ngFor="let x of displayItems; let last = last; let index = index">
        <div [attr.class]="rowCssClass(index)">
          <div *ngFor="let y of x" [attr.class]="y.cssClass" align="center">
            <app-visual-item [visualItem]="y" (onClick)="onClick(y)"></app-visual-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="category-footer">
  <div class="outer-container">
    <div class="inner-container">
      <div class="centered-content category-description" [innerHTML]="categoryDescription"></div>
    </div>
  </div>
</div>
<div class="row pay">
  <div class="col-lg-12">
    <div class="pull-right">
      <simple-button [enabled]="true" *ngIf="isBasketButtonVisible"
        [text]="'Basket'" [cssClass]="'btn btn-primary button-base back-button'" (onClick)="onBasketButtonClick($event)"></simple-button>
    </div>
  </div>
</div>
