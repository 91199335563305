<div class='money-exchange'>
  <div class="normal-text">{{ 'Choose an option to exchange' | translate }} {{insertedMoney}}:</div>
  <div *ngFor="let variant of variants; let i = index" class="row accepted-cash">
    <div class="col-lg-12">
      <a class="btn btn-lg btn-success btn-choice" type="button" (click)="onChoice(variant)">
        <i class="fa fa-refresh"></i>
        {{ 'Option' | translate }} {{(i+1)}}
      </a>
      <div [attr.class]="'accepted-cash-denomination'" *ngFor="let x of variant.banknotes">
        <denomination [money]="x" [cashType]="billsType"></denomination>
      </div>
      <div [attr.class]="'accepted-cash-denomination'" *ngFor="let x of variant.coins">
        <denomination [money]="x" [cashType]="coinType"></denomination>
      </div>
    </div>
  </div>
</div>
