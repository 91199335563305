<div class="frame" (click)="onButtonClick()">
    <span *ngIf="imageURL && providerName"  class="helper"></span>
    <span *ngIf="imageURL && !providerName"  class="helper-no-name"></span>
    <img *ngIf="imageURL" [attr.src]="imageURL" class="center image">
    <div *ngIf="!imageURL" class="icon-container">
        <span *ngIf="iconClass && imageURL === ''">
            <i [attr.class]="iconClass"></i>
        </span>
    </div>
    <h3 *ngIf="providerName" class="name provider">{{ providerName | translate }}</h3>
</div>
