import { Injectable, Injector } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DictionaryHelper } from '../../lib/dictionary-helper';

@Injectable()
export class AdditionalPropertiesConfigurationService {
  private isProduction = false;
  private configurationService: ConfigurationService;

  constructor(
    private injector: Injector,
  ) {
    this.configurationService = injector.get(ConfigurationService);
    this.isProduction = this.configurationService.configuration.isProduction;
  }

  get additionalProperties(): DictionaryHelper {
    return this.configurationService.configuration.additionalPropertiesHelper;
  }

  get isLiteMode(): boolean {
    return this.additionalProperties.getBoolean('lite_mode');
  }

  get isDemoMode(): boolean {
    return this.additionalProperties.getBoolean('demo');
  }

  get turnstileIsLeftSide(): boolean {
    return this.additionalProperties.getBoolean('turnstile_on_left_side');
  }

  get timeoutPaymentSec(): number {
    return this.additionalProperties.getNumber('timeout_payment', 30);
  }

  get timeoutEntranceSec(): number {
    return this.additionalProperties.getNumber('timeout_entrance', 60);
  }

  get timeoutReturnMoneySec(): number {
    return this.additionalProperties.getNumber('timeout_return_money', 10);
  }

  get timeoutPrinterSec(): number {
    return this.additionalProperties.getNumber('timeout_printer', 60);
  }

  get timeoutPostSaleFlowEndSec(): number {
    return this.additionalProperties.getNumber('timeout_post_sale_flow_end_sec', 10);
  }

  get timeoutReturnAmountSec(): number {
    return this.additionalProperties.getNumber('timeout_return_amount_sec', 30);
  }

  get colorDefault(): string {
    return this.additionalProperties.getString('color_default', '#ffffff');
  }

  get colorActive(): string {
    return this.additionalProperties.getString('color_active', '#2c8ccd');
  }

  get colorDisabled(): string {
    return this.additionalProperties.getString('color_disabled', 'gray');
  }

  get colorSuccess(): string {
    return this.additionalProperties.getString('color_success', '#00ff00');
  }

  get colorError(): string {
    return this.additionalProperties.getString('color_error', '#ff0000');
  }

  get waitForTicketRemoved(): boolean {
    return this.additionalProperties.getBoolean('wait_for_ticket_removed');
  }

  get timeoutInvalidRfidCardInserted(): number {
    return this.additionalProperties.getNumber('timeout_invalid_rfid_card_inserted', 3);
  }

  get isCreditCardPaymentAvailable(): boolean {
    return this.additionalProperties.getBoolean('is_credit_card_payment_available', this.isProduction ? false : true);
  }

  get isBluecodePaymentAvailable(): boolean {
    return this.additionalProperties.getBoolean('is_bluecode_payment_available');
  }

  get isTurnstileFastOpenEnabled(): boolean {
    return this.additionalProperties.getBoolean('is_turnstile_fast_open_enabled');
  }

  get isCashPaymentAvailable(): boolean {
    return this.additionalProperties.getBoolean('is_cash_payment_available');
  }

  get ignoreBarcodeReaderScanOnMainPage(): boolean {
    return this.additionalProperties.getBoolean('ignore_barcode_reader_scan_on_main_page');
  }

  get ticketLength(): number {
    return this.additionalProperties.getNumber('ticket_length', 19);
  }

  get separatorPosition(): number {
    return this.additionalProperties.getNumber('separator_position', 5);
  }

  get isContactlessCardAccepted(): boolean {
    return this.additionalProperties.getBoolean('is_contactless_card_accepted');
  }

  get isReadBarcodeAtGateAfterPayment(): boolean {
    return this.additionalProperties.getBoolean('is_read_barcode_at_the_gate_after_payment');
  }

  get isGateOnTheLeftSide(): boolean {
    return this.additionalProperties.getBoolean('is_gate_on_the_left_side');
  }

  get openDelayTimeoutMs(): number {
    return this.additionalProperties.getNumber('open_delay_timeout_ms');
  }

  get gateDefaultAccessTimeMinutes(): number {
    return this.additionalProperties.getNumber('default_access_time', 10);
  }

  get gateMinAccessTimeMinutes(): number {
    return this.additionalProperties.getNumber('min_access_time', 1);
  }

  get gateMaxAccessTimeMinutes(): number {
    return this.additionalProperties.getNumber('max_access_time', 30);
  }

  get gateEntryProductId(): number {
    return this.additionalProperties.getNumber('entry_product');
  }

  get turnstileEntryProductId(): number {
    return this.additionalProperties.getNumber('turnstile_entry_product');
  }

  get isLiteTouchTileMode(): boolean {
    return this.additionalProperties.getBoolean("lite_touch_tile_mode");
  }

  get externalApiRequestTimeoutMs(): number {
    return this.additionalProperties.getNumber('external_api_request_timeout_ms', 0);
  }

  get supportLanguages(): string[] {
    const supportLanguages = this.additionalProperties.getString('support_languages');
    if (!supportLanguages) {
      return [];
    }
    return supportLanguages.split(';');
  }
}
